import React, { useState, useEffect } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Popover, Typography } from "@mui/material";
import "./style.css";
import { data } from "./data";
import Cookies from "js-cookie";
import axios from "axios";

// let data = {
//   1: {
//     id: "1",
//     name: "Yuvraj",
//     parent: "",
//     child: [],
//   },
// };

export default function Structure() {
  const [dataSet, setDataSet] = useState(null);
  async function getWorkflowListing() {
    const API_URL = "https://preprod-api.tbplabs.tech/flow/flow";

    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };

    const WorkflowData = await axios.get(API_URL, config);
    setDataSet(WorkflowData.data[0].actions);
  }

  useEffect(() => {
    getWorkflowListing();
  }, []);

  const startNodeId = "1";

  return (
    dataSet && (
      <ChildContainer
        nodes={dataSet}
        node={dataSet[startNodeId]}
        setDataSet={setDataSet}
      />
    )
  );
}

export const ChildContainer = (props) => {
  const { nodes, node, setDataSet } = props;

  if (node?.nextActionId.length === 0 || node?.nextActionId[0] === "None") {
    return (
      <div className="structure">
        {" "}
        <Child
          nodes={nodes}
          node={node}
          isLast={true}
          setDataSet={setDataSet}
        />
      </div>
    );
  }

  return (
    <div className="structure">
      <Child nodes={nodes} node={node} isLast={false} setDataSet={setDataSet} />
      <div className="node-child-container">
        {node?.nextActionId.map((n) => (
          <ChildContainer
            key={n}
            node={nodes[n]}
            nodes={nodes}
            setDataSet={setDataSet}
          />
        ))}
      </div>
    </div>
  );
};

export const Child = (props) => {
  const { nodes, node, isLast, setDataSet } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const generateNewNodeId = () => {
    const existingIds = Object.keys(nodes);
    let newId = 1;

    while (existingIds.includes(newId.toString())) {
      newId++;
    }

    return newId.toString();
  };

  function addList(node) {
    const newNodeId = generateNewNodeId();
    const newNode = {
      type: "list",
      id: newNodeId,
      name: "New Node",
      parent: node.id,
      nextActionId: [],
    };

    node.nextActionId.push(newNodeId);

    const newData = {
      ...nodes,
      [newNodeId]: newNode,
    };

    setDataSet(newData);
  }

  function addChoices(id) {
    const newNodeId = generateNewNodeId();
    const newNode = {
      type: "choice",
      id: newNodeId,
      name: "New choice",
      parent: id,
      nextActionId: [],
    };

    node.nextActionId.push(newNodeId);

    const newData = {
      ...nodes,
      [newNodeId]: newNode,
    };

    setDataSet(newData);
  }

  function addCondition(node) {
    const newNodeId1 = generateNewNodeId();
    const newNodeId2 = parseInt(newNodeId1) + 1;

    const newNode1 = {
      type: "condition",
      id: newNodeId1,
      name: "New Node 1",
      parent: node.id,
      nextActionId: [],
    };

    const newNode2 = {
      id: newNodeId2,
      name: "New Node 2",
      parent: node.id,
      nextActionId: [],
    };

    node.nextActionId.push(newNodeId1, newNodeId2);

    const newData = {
      ...nodes,
      [newNodeId1]: newNode1,
      [newNodeId2]: newNode2,
    };

    setDataSet(newData);
  }

  return (
    <div className="node">
      <div className="node-connector"></div>

      <div className="node-header">Action Node Id : {node?.actionId}</div>
      <div className="node-body"> {node?.name} </div>
      {node?.type === "choice" && (
        <button className="btn" onClick={() => addChoices(node?.parent)}>
          Add choice
        </button>
      )}
      {isLast ? (
        <>
          <div className="addButton">
            <AddBoxIcon
              stroke="#024d6a"
              fill="#024d6a"
              sx={{
                color: "#024d6a",
                background: "white",
                borderRadius: "4px",
                height: "35px",
                width: "35px",
              }}
              aria-describedby={id}
              onClick={handleClick}
            />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography className="menuItem" onClick={() => addList(node)}>
              List
            </Typography>
            <Typography className="menuItem" onClick={() => addCondition(node)}>
              Condition
            </Typography>
            {/* <Typography className="menuItem" onClick={() => addChoices(node)}>
              Choices
            </Typography> */}
          </Popover>
        </>
      ) : (
        <div className="node-connector-bottom"></div>
      )}
    </div>
  );
};
