import React, { useState, useEffect } from "react";
import AddTeam from "./AddTeam";
import axios from "axios";
import Cookies from "js-cookie";
import "./TeamSetting.css";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TeamDescription from "./TeamDescription";
import { ArrowDropDown } from "@mui/icons-material";
import { Switch } from "@mui/material";

const TeamSetting = ({ permission }) => {
  const [adduserForm, setAdduserForm] = useState(true);
  const [TeamList, setTeamList] = useState(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [edit, setEdit] = useState("notedit");
  const [search, setSearch] = useState("");
  const [dropdownSearch, setDropdownSearch] = useState("");
  const [userList, setUserList] = useState(null);
  const [chooseDropdownUser, setChooseDropdownUser] = useState("--User--");
  const [teamId, setTeamId] = useState(null);
  const [addTeamForm, setTeamForm] = useState(false);
  const [addTeamUserId, setaddTeamUserId] = useState(null);
  const [warningMessage, setWarningMessage] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const adduserValue =
    edit == "notedit"
      ? {
          name: "",
          department: "",
          keywords: "",
        }
      : {
          name: "",
          department: "",
          keywords: "",
        };

  const [userAddToForm, setUserAddToForm] = useState(adduserValue);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserAddToForm({
      ...userAddToForm,
      [name]: value,
    });
  };

  function openAddUserForm(e) {
    try {
      userAddToForm.name = e.name;
      userAddToForm.department = e.department;
      userAddToForm.keywords = e.keyword;
      setEdit("notedit");
    } catch (err) {
      setEdit("edit");
    }
    if (adduserForm == false) {
      userAddToForm.name = "";
      userAddToForm.department = "";
      userAddToForm.keywords = "";
    }
    setAdduserForm(!adduserForm);
  }

  async function getUserList() {
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    let resp = await axios.get(
      "https://preprod-api.tbplabs.tech/user/userlist",
      config
    );
    setUserList(resp.data.data);
  }

  useEffect(() => {
    getUserList();
  }, []);

  async function getTeamList() {
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    let resp = await axios.get(
      "https://preprod-api.tbplabs.tech/user/team",
      config
    );
    setTeamList(resp.data.data);
  }

  useEffect(() => {
    getTeamList();
    getUserList();
  }, []);

  async function submitAddUserForm() {
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    try {
      if (edit == "notedit") {
        await axios.post(
          "https://preprod-api.tbplabs.tech/user/team",
          userAddToForm,
          config
        );
      } else {
        await axios.post(
          "https://preprod-api.tbplabs.tech/user/team",
          userAddToForm,
          config
        );
      }
    } catch (error) {}
  }

  function updateUser(name) {
    setAdduserForm();
  }

  const typography = {
    color: "black",
    fontSize: "14px",
  };

  function adduserToTeam(teamId, id, name) {
    setChooseDropdownUser(name);
    setIsDropDownOpen(false);
    setaddTeamUserId(id);
  }

  async function addUserToTeam() {
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    try {
      await axios.patch(
        `https://preprod-api.tbplabs.tech/user/userTeam?teamId=${teamId}&userId=${addTeamUserId}&isAdmin=${isAdmin}`,
        null,
        config
      );
      try {
        await getTeamList();
      } catch (err) {}
      setWarningMessage("User Added to Team successfully !");
      setTimeout(() => {
        setTeamForm(false);
      }, 800);
    } catch (err) {
      setWarningMessage("User Already exist in team!");
    }
  }

  const dropdownClose = {
    height: "0px",
    overflow: "hidden",
    position: "absolute",
    background: "white",
    borderRadius: "5px",
    width: "150px",
    overflow: "hidden",
  };
  const dropdownOpen = {
    maxHeight: "250px",
    overflowY: "scroll",
    overflowX: "hidden",
    position: "absolute",
    background: "white",
    border: "1px solid #109cf1",
    borderRadius: "5px",
    width: "150px",
    zIndex: "1",
  };
  const addUserbtn = {
    color: "#109cf1",
    padding: "5px",
    border: "2px solid #109cf1",
    borderRadius: "3px",
    fontSize: "12px",
    marginBottom: "10px",
    width: "70px",
    position: "absolute",
    right: "2%",
    cursor: "pointer",
  };
  function adduserform(teamId) {
    setTeamId(teamId);
    setTeamForm(true);
  }

  return (
    <>
      {adduserForm ? (
        <div className="peopleSettingContainer">
          <h1 className="account-setting">Team</h1>
          {permission.ADD_TEAM && (
            <button onClick={() => openAddUserForm()} className="addUser">
              Add Team
            </button>
          )}

          <span className="search-people">
            <input
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Team"
              type="text"
            ></input>
          </span>
          <div className="people-form">
            <table className="team-people-form-table">
              <tr>
                <th>Team List</th>
              </tr>
              {TeamList != null
                ? TeamList.filter((e) => {
                    return search.toLowerCase() === ""
                      ? e
                      : e.user__first_name.toLowerCase().includes(search);
                  }).map((e) => (
                    <tr>
                      <Accordion
                        style={{ boxShadow: "#d6f3ff 1px 1px 10px 1px" }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={typography}>{e.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography style={typography}>
                            {permission.ADD_USER_TEAM && (
                              <h3
                                onClick={() => adduserform(e.id)}
                                style={addUserbtn}
                              >
                                Add User
                              </h3>
                            )}
                            <TeamDescription
                              permission={permission}
                              users={e.users}
                              teamId={e.id}
                            />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </tr>
                  ))
                : null}
              <div className="parent-dropdown-full">
                <div
                  style={
                    addTeamForm ? { display: "block" } : { display: "none" }
                  }
                  className="chooseTeamDropdown"
                >
                  <span
                    onClick={() => setTeamForm(false)}
                    className="close-btn"
                  >
                    X
                  </span>
                  <p className="add-txt">Choose User to add</p>
                  <div className="dropdown-container">
                    <div
                      onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                      className="dropdown"
                    >
                      <span>{chooseDropdownUser}</span> <ArrowDropDown />
                    </div>
                    <div>
                      <ul style={isDropDownOpen ? dropdownOpen : dropdownClose}>
                        <li>
                          <input
                            className="dropdownSearch"
                            placeholder="🔎︎ Search"
                            type="text"
                            onChange={(e) => setDropdownSearch(e.target.value)}
                          ></input>
                        </li>
                        {userList != null
                          ? userList
                              .filter((event) => {
                                return dropdownSearch.toLowerCase() === ""
                                  ? event
                                  : event.user__first_name
                                      .toLowerCase()
                                      .includes(dropdownSearch);
                              })
                              .map((event) => (
                                <li
                                  className="userAddList"
                                  onClick={() =>
                                    adduserToTeam(
                                      teamId,
                                      event.user__id,
                                      event.user__first_name
                                    )
                                  }
                                  value={event.user__first_name}
                                >
                                  {event.user__first_name}
                                </li>
                              ))
                          : null}
                      </ul>
                    </div>
                    <div className="team-admin">
                      Is team admin?{" "}
                      <Switch
                        onChange={() => setIsAdmin(!isAdmin)}
                        size="small"
                      />
                    </div>
                  </div>

                  <button
                    onClick={() => addUserToTeam()}
                    className="submit-btn"
                  >
                    Submit
                  </button>
                  <span className="warningMessage">{warningMessage}</span>
                </div>
              </div>
            </table>
          </div>
          <div>
            <div className="addUser-container"></div>
          </div>
        </div>
      ) : (
        <div className="adduser-container">
          <h1 className="account-setting">
            Add Team
            <button onClick={() => openAddUserForm()} className="addUser">
              Close X
            </button>
          </h1>

          <div className="accountSettingForm">
            <div className="basic-information">Basic Information</div>
            <div className="addUserForm">
              <div className="field">
                <p className="label">Name</p>
                <input
                  className="form-input"
                  variant="outlined"
                  name="name"
                  value={userAddToForm.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <p className="label">Department</p>
                <input
                  className="form-input"
                  variant="outlined"
                  name="department"
                  value={userAddToForm.department}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <p className="label">Keyword</p>
                <input
                  className="form-input"
                  variant="outlined"
                  name="keyword"
                  value={userAddToForm.keyword}
                  onChange={handleInputChange}
                />
              </div>
              <br></br>
              <div className="submitButton">
                <button
                  onClick={() => submitAddUserForm()}
                  className="form-submit-btn"
                >
                  Submit
                </button>
                <button
                  onClick={() => openAddUserForm()}
                  style={{
                    marginLeft: "20px",
                    border: "1px solid #109cf1",
                    background: "transparent",
                    color: "#109cf1",
                  }}
                  className="form-submit-btn"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamSetting;
