import { AccountCircleRounded, MenuOpen } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Profile from "../Home-page-components/Communicate/components/Profile/Profile";
import { Hubspot } from "./MarketingPartners/HubSpot/Hubspot";
import { Shopify } from "./MarketingPartners/Shopify/Shopify";
import "./MarketingSidebar.css";
import hubspot from "./MarketingWebsiteIcons/hubspot.png";
import shopify from "./MarketingWebsiteIcons/shopify.png";

export const MarketingSidebar = ({
  profile,
  showTagModal,
  setShowTagModal,
  tag,
  integrations,
  appAccountId
}) => {
  const [isSidebarOpen, setIssidebarOpen] = useState(false);
  const [selectedMarketingService, setSelectedMarketingService] = useState("");

  const icon = {
    width: "100%",
    height: "33px",
    color: "#BEBEBE",
    transitionDuration: "0.5s",
  };

  function openSidebar(serviceName) {
    setSelectedMarketingService(serviceName);
    setIssidebarOpen(true);
  }

  function menuButtonPress() {
    if (!isSidebarOpen) {
      setSelectedMarketingService("profile");
    } else {
      setSelectedMarketingService("close");
    }
    setIssidebarOpen(!isSidebarOpen);
  }

  useEffect(() => {
    setIssidebarOpen(false);
  }, [profile]);

  return (
    <div className="flex-container">
      <div className="marketingi-icons-sidebar">
        <div onClick={() => openSidebar("profile")}>
          <AccountCircleRounded
            className={
              selectedMarketingService === "profile"
                ? "activeImageStyle"
                : "imageStyle"
            }
            style={icon}
          />
        </div>
        {integrations?.hubspot === true && (
          <div onClick={() => openSidebar("hubspot")}>
            <img
              className={
                selectedMarketingService === "hubspot"
                  ? "activeImageStyle"
                  : "imageStyle"
              }
              src={hubspot}
            ></img>
          </div>
        )}
        {integrations?.shopify === true && (
          <div onClick={() => openSidebar("shopify")}>
            <img
              className={
                selectedMarketingService === "shopify"
                  ? "activeImageStyle"
                  : "imageStyle"
              }
              src={shopify}
            ></img>
          </div>
        )}
        <div>
          <MenuOpen
            onClick={() => menuButtonPress()}
            style={icon}
            sx={
              isSidebarOpen
                ? { transform: "rotate(180deg)" }
                : { transform: "rotate(0deg)" }
            }
          />
        </div>
      </div>
      <div
        className="marketing-sidebar-form"
        style={isSidebarOpen ? { width: "18rem" } : { width: "0rem" }}
      >
        {selectedMarketingService === "profile" && (
          <Profile
            profile={profile}
            showTagModal={showTagModal}
            setShowTagModal={setShowTagModal}
            tag={tag}
            appAccountId={appAccountId}
          />
        )}
        {selectedMarketingService === "hubspot" && <Hubspot profile={profile} />}
        {selectedMarketingService === "shopify" && <Shopify profile={profile} />}
      </div>
    </div>
  );
};
