import * as React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Button } from "@mui/material";
import AuthApi from "../../AuthApi";
import "./Sidebar.Styled.css";

import {
  Analytics,
  CampaignSharp,
  ContactMail,
  Dashboard,
  DashboardCustomize,
  FormatAlignCenterTwoTone,
  Group,
  Login,
  Mail,
  Settings,
  SettingsAccessibility,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Contact from "../../Home-page-components/Contact";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar(prop) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const [userOnline, setUserOnline] = useState("");
  const Auth = React.useContext(AuthApi);
  var raw = "";
  let myHeaders = new Headers();
  const name = "auth_token";
  let authtoken = getCookie(name);
  authtoken = "Token " + authtoken;
  //console.log(authtoken)
  myHeaders.append("Authorization", authtoken);
  //console.log(myHeaders)

  // handeling click on logout button
  function handleClick() {
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    function eraseCookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    function handleResult() {
      localStorage.removeItem("userDetails");
      eraseCookie(name);
      Auth.setAuth(false);
    }

    // calling logout api
    fetch("https://preprod-api.tbplabs.tech/user/logout", requestOptions)
      .then((response) => response.text())
      .then((result) => handleResult())
      .catch((error) => console.log("error", error));
  }
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const active = {
    color: "#109cf1",
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <div className="sidebar-title">{userDetails.account.name}</div>
        </DrawerHeader>
        <Divider />

        <List className="sidbar-list">
          <ListItem style={{ display: "flex", justifyContent: "center" }}>
            <div className="profile-container">
              <img
                className="profile-picture"
                src={userDetails.account.logo}
                alt="profile-pic"
              />

              <div>
                <div className="">
                  <strong>{userDetails.user.first_name}</strong>
                </div>

                <div className="profile-cns">{userDetails.user.email}</div>
              </div>
            </div>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/Dashboard">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  style={prop.active == "Dashboard" ? active : null}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Dashboard />
                </ListItemIcon>
                <ListItemText
                  style={prop.active == "Dashboard" ? active : null}
                  primary="Dashboard"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/Contact">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  style={prop.active == "Contact" ? active : null}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ContactMail />
                </ListItemIcon>
                <ListItemText
                  style={prop.active == "Contact" ? active : null}
                  primary="Contact"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/campaigns">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  style={prop.active == "Campaigns" ? active : null}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <CampaignSharp />
                </ListItemIcon>
                <ListItemText
                  style={prop.active == "Campaigns" ? active : null}
                  primary="Campaigns"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/candidates">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  style={prop.active == "Candidates" ? active : null}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    // prop.active=='Candidates'?active:null
                  }}
                >
                  <Group />
                </ListItemIcon>
                <ListItemText
                  style={prop.active == "Candidates" ? active : null}
                  primary="Candidates"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/communicate">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  style={prop.active == "Communicate" ? active : null}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Mail />
                </ListItemIcon>
                <ListItemText
                  style={prop.active == "Communicate" ? active : null}
                  primary="Communicate"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

          {/* <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/Workflow">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  style={prop.active == "Workflow" ? active : null}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SettingsAccessibility />
                </ListItemIcon>
                <ListItemText
                  style={prop.active == "Workflow" ? active : null}
                  primary="Workflow"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem> */}

          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/analytics">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  style={prop.active == "Analytics" ? active : null}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    // prop.active=='Analytics'?active:null
                  }}
                >
                  <Analytics />
                </ListItemIcon>
                <ListItemText
                  style={prop.active == "Analytics" ? active : null}
                  primary="Analytics"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        <Divider />
        <List className="sidbar-list">
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/AddForm">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  style={prop.active == "Form" ? active : null}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <FormatAlignCenterTwoTone />
                </ListItemIcon>
                <ListItemText
                  style={prop.active == "Form" ? active : null}
                  primary={"Form"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/settings">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  style={prop.active == "Settings" ? active : null}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Settings />
                </ListItemIcon>
                <ListItemText
                  style={prop.active == "Settings" ? active : null}
                  primary={"Settings"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={handleClick}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Login />
              </ListItemIcon>
              <ListItemText primary={"Logout"} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>

        {/* Below function will call for open and close the sidebar in future */}
        {/* <Button onClick={handleDrawerOpen}>Open</Button> */}
      </Drawer>
    </Box>
  );
}
