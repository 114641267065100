import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TagFilterInput from "./TagFilterInput";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};  


function FilterModal({open, handleClose, selectedTags, setSelectedTags, tag, filter, handleInput, handleSearch, selectedDate, setSelectedDate}) {

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div id="filters">
            <TagFilterInput
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              tag={tag}
            />
            <div className="flex">
              <ul className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg  border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
                  <div className="flex items-center pl-3">
                    <input
                      id="list-radio-license"
                      type="radio"
                      value="newest"
                      checked={filter.order === "newest"}
                      name="order"
                      onChange={(e) => {
                        handleInput(e.target.value, e.target.name);
                      }}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="list-radio-license"
                      className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Newest
                    </label>
                  </div>
                </li>
                <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
                  <div className="flex items-center pl-3">
                    <input
                      id="list-radio-id"
                      type="radio"
                      value="oldest"
                      checked={filter.order === "oldest"}
                      name="order"
                      onChange={(e) => {
                        handleInput(e.target.value, e.target.name);
                      }}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="list-radio-id"
                      className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Oldest
                    </label>
                  </div>
                </li>
              </ul>
              <ul className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg  border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
                  <div className="flex items-center pl-3">
                    <input
                      id="vue-checkbox"
                      name="unread"
                      type="checkbox"
                      checked={filter.unread}
                      onChange={(e) => {
                        handleInput(e.target.value, e.target.name);
                      }}
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="vue-checkbox"
                      className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Unread
                    </label>
                  </div>
                </li>
                <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
                  <div className="flex items-center pl-3">
                    <input
                      id="react-checkbox"
                      name="read"
                      type="checkbox"
                      checked={filter.read}
                      onChange={(e) => {
                        handleInput(e.target.value, e.target.name);
                      }}
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="react-checkbox"
                      className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Read
                    </label>
                  </div>
                </li>
                <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
                  <div className="flex items-center pl-3">
                    <input
                      id="angular-checkbox"
                      name="hot"
                      type="checkbox"
                      checked={filter.hot}
                      onChange={(e) => {
                        handleInput(e.target.value, e.target.name);
                      }}
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="angular-checkbox"
                      className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Hot
                    </label>
                  </div>
                </li>
                <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
                  <div className="flex items-center pl-3">
                    <input
                      id="laravel-checkbox"
                      name="cold"
                      type="checkbox"
                      checked={filter.cold}
                      onChange={(e) => {
                        handleInput(e.target.value, e.target.name);
                      }}
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="laravel-checkbox"
                      className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Cold
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className="my-2">
              <label className="block text-gray-900 mb-2">Search in chat</label>
              <input
                id="search-in-chat"
                name="searchInChat"
                type="checkbox"
                checked={filter.searchInChat}
                onChange={(e) => {
                  handleInput(e.target.value, e.target.name);
                }}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
            </div>
            <div className="my-2">
              <label className="block text-gray-900 mb-2">Expire By</label>
              <DateTimePicker
                selected={selectedDate}
                onChange={(date) => {
                  const formattedDate = date.toISOString().slice(0, 16); // Output date in "2022-04-17T15:30" format
                  console.log(formattedDate);
                  handleInput(
                    formattedDate,
                    "chatExpiringBy"
                  );
                }}
                disablePast
                className="w-full p-2 rounded-xl border border-gray-300"
              />
            </div>
            <div className="flex  justify-center">
              <button
                type="button"
                onClick={() => {
                  handleSearch();
                  document.getElementById("filters").style.display = "none";
                  document.getElementById("fIcon").style.color = "grey";
                }}
                className="w-4/5 my-2 text-white bg-[#0086ff] hover:bg-[#0077ff] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Apply
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default FilterModal;
