import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";


function _Modal({ open, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    px: 2,
    py: 4,
    borderRadius: 2,
  };

  const [formData, setFormData] = useState({ name: "", description: "" });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    fetch("https://preprod-api.tbplabs.tech/user/roles", {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("auth_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error(error));
    handleClose();
  };

  useEffect(()=>{
    console.log(formData)
  },[formData])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center justify-between w-full">
            <Typography id="modal-modal-description" variant="h6">
              Add new role
            </Typography>
            <div
              onClick={handleClose}
              className="cursor-pointer text-black font-bold"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>

          <div className="mt-6 flex flex-col justify-between items-center w-full gap-2">
            <TextField
              label="Role name"
              name="name"
              size="small"
              style={{ width: "100%" }}
              onChange={handleInputChange}
              value={formData.name}
            />
            <TextField
              label="Description"
              name="description"
              size="small"
              style={{ width: "100%" }}
              onChange={handleInputChange}
              value={formData.description}
            />
            <Button sx={{mt: 2}} variant="contained" onClick={handleSubmit}>
              Add
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default _Modal;
