import React from 'react'

const TimeStamp = ({time, isSideBar, sentBy}) => {
     const messageTime = new Date(time);
     const hours = messageTime.getHours();
     let minutes = messageTime.getMinutes();
     const day = messageTime.getDate();
     const month = messageTime.getMonth();
     const year = messageTime.getFullYear();
     
    if(minutes < 10){
      minutes = '0'+minutes
    }

     const monthList = [
       "Jan",
       "Feb",
       "Mar",
       "Apr",
       "May",
       "Jun",
       "Jul",
       "Aug",
       "Sept",
       "Oct",
       "Nov",
       "Dec",
     ];
  return (
    <>
      <div
        className={`flex w-full text-xs border-t-[1] border-slate-300 mt-2 ${
          sentBy === "agent" ? "text-gray-200" : "text-gray-400"
        }`}
      >
        {isSideBar && (
          <p
            className={`flex justify-between ml-[20px] mr-[20px] ${isSideBar && "text-slate-400"}`}
          >
                <span>
                  {hours}:{minutes}
                </span>&nbsp;&nbsp;
                <span>
                  {day} {monthList[month]}
                </span>
          </p>
        )}
        {!isSideBar && (
          <>
            <span>
              {hours}:{minutes}
            </span>&nbsp;&nbsp;
            <p>
              {day} {monthList[month]} {year}
            </p>
          </>
        )}
      </div>
    </>
  );
}

export default TimeStamp