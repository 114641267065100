import React, { useState, useEffect, useRef } from "react";
import FormComponent from "./FormComponent";
import { useParams } from "react-router-dom"

// import AccountSetting from "./AccountSetting/AccountSetting";
// import PeopleSetting from "./PeopleSetting/PeopleSetting";
import "./SettingSidebar.css";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";

import axios from "axios";


const AddForm = () => {
  const [active, setActive] = useState("");
  const [accountData, setAccountData] = useState();
  const [submit, setSubmit] = useState(false)
  const [listingData, setlistingData] = useState([])
  // const [postBody, setPostBody] = useState([])
  let postBody = []
  let errors = false
  let { id } = useParams();

  function changeActiveElement(active) {
    setActive(active);
  }
  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  let myHeaders = new Headers();
  const refs = useRef([])
  function getData(){
    for(let i=0; i<groupData.length; i++){
      console.log(refs)

      refs.current[i].setFileValueToJson(i)
    }
    console.log(postBody)
    if(errors){
      return
    }else{sendData()}
  }

  async function sendData() {
    const name = "auth_token";
    let authtoken = getCookie(name);
    authtoken = "Token " + authtoken;
    //console.log(authtoken)
    myHeaders.append("Authorization", authtoken);
    //console.log(myHeaders)

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    let tempData = []
    for(let i =0; i< postBody.length; i++){
      tempData.push(...postBody[i])
    }
    let data= {
      data: tempData
    }
    try{
      console.log(config, data);
      const resp = await axios.put("https://preprod-api.tbplabs.tech/contacts/Contactform/"+id,data,config);
      console.log(resp);
    }
    catch(error){
      console.log(error);
    }


  }

  const [groupData, setgroupData] = useState([])
  useEffect(() => {
    const name = "auth_token";
    let authtoken = getCookie(name);
    authtoken = "Token " + authtoken;
    //console.log(authtoken)
    myHeaders.append("Authorization", authtoken);
    //console.log(myHeaders)

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    function handleData(result) {
      if (result.detail !== "Invalid token.") {
        console.log(result);
      } else return;
    }

    

    //Calling ME api
    fetch("https://preprod-api.tbplabs.tech/user/me", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        setAccountData(data);
        
      })
      .catch((error) => console.log("error", error));
      fetch("https://preprod-api.tbplabs.tech/contacts/Contactform", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        setgroupData(data.data)
      })
      .catch((error) => console.log("error", error));
      fetch("https://preprod-api.tbplabs.tech/contacts/Contactform/"+id, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        setlistingData(data.data)
      })
      .catch((error) => console.log("error", error));
  }, []);

  // fetch
  
  const pageStyle = {
    display: "flex",
  };
  return (
    <div style={pageStyle}>
      {/* SETTING SIDEBAR */}
      <div className="sideBarcontainer">
        {/* <h2>General Settings</h2> */}
        {/* <hr></hr> */}
        <ul>
          {
            groupData.map((item, key)=>{
              return(<li>{item.groupLabel}</li>)
            })
          }
        </ul>

        
      </div>
      <div>
      {
            groupData.map((item, key)=>{
              return(<FormComponent group={item} postBody={postBody} listingData={listingData} submit={submit} accountData={accountData} ref={(el) => refs.current[key] = el}/>)
            })
          }
          <div style={{ margin: "20px 0" }}><Button variant="contained" onClick={()=>{getData()}}>Submit</Button></div>
          </div>
      {/* SETTING PAGES COMONENTS */}
      {/* {active === "account" && <AccountSetting profile={accountData} />}
      {active === "people" && <PeopleSetting />} */}
    </div>
  );
};

export default AddForm;
