import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import { CommunicateContextProvider } from './store/CommunicateContext';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

ReactDOM.render(
  <CommunicateContextProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <App />
    </LocalizationProvider>
  </CommunicateContextProvider>,

  document.getElementById("root")
);

