import { DataGrid } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import { useState, useEffect } from "react";
import DropdownInput from "./DropdownInput";
import Button from "@mui/material/Button";
import Modal from "./Modal"
import Alert from "@mui/material/Alert";
import Cookies from "js-cookie";





function RoleSetting() {
    const [selectedOption, setSelectedOption] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [roles, setRoles] = useState();
    const [permissions, setPermissions] = useState();
    const [rowData, setRowData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);


    useEffect(()=>{
      fetch("https://preprod-api.tbplabs.tech/user/roles", {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("auth_token")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          setRoles(data.id)
        })
        .catch((error) => console.error(error));
    },[])

    // const handleChange = (event, rowDataItem) => {
    //  const newData = rowData.map((item) =>
    //    item.id === rowDataItem.id
    //      ? { ...item, enabled: event.target.checked }
    //      : item
    //  );
    //  setRowData(newData);
    //  console.log(newData)
    // };
    const handleChange = (event, id) => {
      const checked = event.target.checked;
      setRowData((prevRowData) =>
        prevRowData.map((row) =>
          row.id === id ? { ...row, enabled: checked } : row
        )
      );

      fetch(`https://preprod-api.tbplabs.tech/user/rolepermission/${id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Token ${Cookies.get("auth_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          enable: checked,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.error(error));
    };

    const columns = [
      { field: "permissionName", headerName: "Permission name", width: 400 },
      {
        field: "enabled",
        headerName: "Access",
        width: 200,
        renderCell: (params) => {
          // console.log(rowData.row.enabled)
          const checked = params.row.enabled;
          const id = params.row.id;
          return (
            <Switch
              checked={checked}
              onChange={(event) => handleChange(event, id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          );
        },
      },
    ];

    const handleClose = ()=>{
      setIsModalOpen(false)
    }

    useEffect(()=>{
      if(roles && selectedOption){
        fetch(`https://preprod-api.tbplabs.tech/user/rolepermission/${selectedOption}`, {
          method: "GET",
          headers: {
            Authorization: `Token ${Cookies.get("auth_token")}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data.content)
            setPermissions(data.content)
          })
          .catch((error) => console.error(error));
      }
    },[roles, selectedOption])

    useEffect(()=>{
      if (permissions) {
        const rows = permissions.map((permission) => ({
          id: permission.id,
          enabled: permission.enabled,
          permissionName: permission.permission__name,
        }));
        setRowData(rows);
        console.log(rows)
      }

    },[permissions])

    const handlePageChange = (params) => {
      setCurrentPage(params.page);
    };

  return (
    <>
      <div className="w-full">
        <div className="flex items-center justify-between w-full">
          <div>
            <div className="text-2xl font-extrabold">Roles</div>
            <p className="text-gray-500 mt-2">
              Find all of your users and their associated roles.
            </p>
          </div>
          <div className="flex items-center gap-2">
            {roles && (
              <DropdownInput
                label="Select role"
                value={selectedOption || ""}
                options={roles}
                onChange={(event) => {
                  console.log(event.target.value);
                  setSelectedOption(event.target.value);
                }}
              />
            )}
            <div className="">
              {/* <button className="bg-blue-500 text-white p-2">Add roles</button> */}
              <Button onClick={() => setIsModalOpen(true)} variant="contained">
                Add roles
              </Button>
            </div>
            <Modal open={isModalOpen} handleClose={handleClose} />
          </div>
        </div>
        <div className="mt-6" style={{ height: "75vh", width: "100%" }}>
          {permissions && rowData ? (
            <DataGrid
              rows={rowData ? rowData : []}
              columns={columns}
              pageSize={15}
              page={currentPage}
              onPageChange={handlePageChange}
            />
          ) : (
            <div className="flex justify-center items-center w-full h-full">
              <Alert severity="info">Please select a role</Alert>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RoleSetting;
