import React, { useEffect, useState } from 'react'
import search_icon from "../Images/search-icon.png"
import bell_icon from "../Images/notification-icon.png"
import dot_icon from "../Images/Ellipse.png"
import AuthApi from '../../AuthApi'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import Sidebar from '../../utils/components/Sidebar'

function Campaigns() {
  const Toggle = React.useContext(AuthApi)
  const history = useHistory();
  const [campaignsData, setCampaignsData] = useState();

  //showing sidebar as per toggle
  const [currentSidebarStyle, setCurrentSidebarStyle] = useState((Toggle.toggleSlider) ? {
    position: "fixed",
    width: "235px",
    maxWidth: "100%",
    height: "100%",
    background: "#ffffff",
    boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
    transition: "0.1s",
  }
: {
    position: "fixed",
    width: "75px",
    maxWidth: "100%",
    height: "100%",
    background: "#ffffff",
    boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
    transition: "0.1s",
  })
  // showing search container as per toggle 
  const [searchContainerStyle, setSeachContainerStyle] = useState((Toggle.toggleSlider) ? {
    marginLeft: '235px',
    display: 'flex',
    flexDirection: 'column',
  } : {
    marginLeft: '75px',
    display: 'flex',
    flexDirection: 'column',
  })

  // handeling toggle changes
  function handleToggle(){
    Toggle.setToggleSlider(!Toggle.toggleSlider)
    if(Toggle.toggleSlider ){
      setCurrentSidebarStyle(prevStyle => ({
        ...prevStyle,
        width: '75px',
        transition: '0.3s'
      })) 
        setSeachContainerStyle(prevStyle => ({
          ...prevStyle,
          marginLeft: '75px'
        }))
    }
    else {
      setCurrentSidebarStyle(prevStyle => ({
        ...prevStyle,
        width: '235px',
        transition: '-0.5s'
      }))
      setSeachContainerStyle(prevStyle => ({
        ...prevStyle,
        marginLeft: '235px'
      }))
    }
  }

   // getting cookie
   function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  const[userOnline, setUserOnline] = useState('')
  const Auth = React.useContext(AuthApi)
  var raw = "";
  let myHeaders = new Headers();
  const name='auth_token'
  let authtoken=getCookie(name)
  authtoken="Token "+authtoken
  //console.log(authtoken)
  myHeaders.append("Authorization", authtoken);
  //console.log(myHeaders)

// handeling click on logout button
  function handleClick(){
    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  function handleResult(){
    localStorage.removeItem('userDetails')
    eraseCookie(name)
    Auth.setAuth(false)
  }

  // calling logout api
  fetch("https://preprod-api.tbplabs.tech/user/logout", requestOptions)
    .then(response => response.text())
    .then(result => handleResult())
    .catch(error => console.log('error', error));
    }

  useEffect(() => {
   const handleGetCampaigns = async () => {
    //  const token = "e74bc3894c4956b51beba1b9ba6e872081fcea48";
     const response = await fetch(
       "https://preprod-api.tbplabs.tech/whatsapp/campaigns",
       {
         method: "GET",
         headers: {
           Authorization: `Token ${Cookies.get("auth_token")}`,
         },
       }
     );
     const data = await response.json();
     setCampaignsData(data);
   };
    
  handleGetCampaigns();
 
  },[])

    // // getting user details from local storage to use it in sidebar
    let userDetails = JSON.parse(localStorage.getItem('userDetails'))
  
  return (
    <div className="dashboard">
      <Sidebar active='Campaigns'/>
      <div className="search-container" style={searchContainerStyle}>
        <input
          className="search-box"
          type="text"
          placeholder=" Global Search"
        ></input>
        <img className="seach-icon" src={search_icon} />
        <img className="bell-icon" src={bell_icon} />
        <img className="dot-icon" src={dot_icon} />
        <div className="data-container">
          <div className="flex justify-between items-center border-b-2 py-4 px-4">
            <h1 className="text-3xl font-medium">Marketing Campaigns</h1>
            <button
              onClick={() => history.push("/addcampaign")}
              className="bg-[#0086ff] hover:bg-[#0077ff] text-white my-2 p-2 font-medium text-base"
            >
              Add Campaign
            </button>
          </div>
          <div className="flex px-4 py-6 justify-between">
            <h1 className="text-xl font-medium">
              Overview{" "}
              <span className="text-gray-400 font-medium text-base">
                (Last 30 days)
              </span>
            </h1>
            <div className="flex gap-x-4 font-medium w-1/2">
              <div className="w-1/3">
                <p className="border-b-2 py-2 text-gray-500">Sent Message</p>
                <div className="flex justify-between items-center px-2">
                  {campaignsData && (
                    <p className="py-2 text-xl font-semibold">
                      {campaignsData.data.summary.sent}
                    </p>
                  )}
                  <svg
                    className="h-6 w-6"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                      fill="currentColor"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div className="w-1/3">
                <p className="border-b-2 py-2 text-gray-500">
                  Delivered Messages
                </p>
                <div className="flex items-center justify-between">
                  {campaignsData && (
                    <p className="py-2 text-xl font-semibold">
                      {campaignsData.data.summary.delivered}
                    </p>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version={1.0}
                    width="96.000000pt"
                    height="96.000000pt"
                    viewBox="0 0 96.000000 96.000000"
                    preserveAspectRatio="xMidYMid meet"
                    className="h-6 w-6"
                  >
                    <g
                      transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path d="M427 522 l-187 -187 -68 68 -68 67 -27 -28 -27 -28 88 -87 c48 -48 94 -87 102 -87 17 0 420 399 420 416 0 6 -10 20 -23 32 l-23 21 -187 -187z" />
                      <path d="M668 523 c-179 -179 -188 -186 -206 -170 -17 16 -20 15 -45 -11 l-27 -28 38 -37 c21 -20 44 -37 53 -37 16 0 419 399 419 416 0 6 -10 20 -23 32 l-23 21 -186 -186z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div className="w-1/3">
                <p className="border-b-2 py-2 text-gray-500">Read Messages</p>
                <div className="flex justify-between items-center">
                  {campaignsData && (
                    <p className="py-2 text-xl font-semibold">
                      {campaignsData.data.summary.read}
                    </p>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version={1.0}
                    width="96.000000pt"
                    height="96.000000pt"
                    viewBox="0 0 96.000000 96.000000"
                    preserveAspectRatio="xMidYMid meet"
                    className="h-6 w-6 text-green-200"
                  >
                    <g
                      transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path d="M427 522 l-187 -187 -68 68 -68 67 -27 -28 -27 -28 88 -87 c48 -48 94 -87 102 -87 17 0 420 399 420 416 0 6 -10 20 -23 32 l-23 21 -187 -187z" />
                      <path d="M668 523 c-179 -179 -188 -186 -206 -170 -17 16 -20 15 -45 -11 l-27 -28 38 -37 c21 -20 44 -37 53 -37 16 0 419 399 419 416 0 6 -10 20 -23 32 l-23 21 -186 -186z" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="overflow-x-auto py-4">
              <table className="min-w-full divide-y-2 divide-gray-200 text-sm outline-none">
                <thead className="py-2">
                  <tr>
                    <th className="whitespace-nowrap px-4 py-2 text-left font-medium ">
                      Campaign Name
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 text-left font-medium ">
                      Template Name
                    </th>
                    {/* <th className="whitespace-nowrap px-4 py-2 text-left font-medium ">
                      Campaign Size
                    </th> */}
                    <th className="whitespace-nowrap px-4 py-2 text-left font-medium ">
                      Sent Messages
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 text-left font-medium ">
                      Delivered Messages
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 text-left font-medium ">
                      Failed Messages
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 text-left font-medium ">
                      Read Messages
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {campaignsData &&
                    campaignsData.data.campaigns.map((campaign) => {
                      return (
                        <>
                          <tr className="odd:bg-gray-50">
                            <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                              {campaign.campaignName}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                              {campaign.templateName}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                              {campaign.size}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                              {campaign.delivered}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                              {campaign.failed}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                              {campaign.read}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaigns