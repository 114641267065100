import React, { useEffect, useState } from "react";
import "./TeamSetting.css";
import axios from "axios";
import Cookies from "js-cookie";
import { Delete } from "@mui/icons-material";
const TeamDescription = (props) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    setUserData(props.users);
  }, []);

  const teamDesc = {
    color: "#109cf1",
    marginBottom: "10px",
    fontSize: "16px",
  };

  async function deleteUserOfTeam(teamId, id, key) {
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    const newUserData = userData.filter((l) => l.user_id !== id);
    setUserData(newUserData);
    const url = `https://preprod-api.tbplabs.tech/user/userTeam?teamId=${teamId}&userId=${id}`;
    await axios.delete(url, config);
  }

  return (
    <div>
      <div className="user-details-btn-container">
        <h3 style={teamDesc}>Users Details</h3>
      </div>
      {userData != null
        ? userData?.map((e) => (
            <div key={e.user_id} className="user-team-container">
              <div className="team-user-desc">
                Name: {e.user__first_name} {e.user__last_name} <br></br>
                Email: {e.user__email}
                <br></br>
                <br></br>
              </div>
              {props.permission.DELETE_TEAM && (
                <span
                  onClick={() => deleteUserOfTeam(props.teamId, e.user_id)}
                  className="delete-user-btn"
                >
                  <Delete />
                </span>
              )}
            </div>
          ))
        : null}
    </div>
  );
};

export default TeamDescription;
