import React, { useState, useEffect } from "react";
import "./AccountConfiguration.css";
import { Switch } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Delete } from "@mui/icons-material";
import Cookies from "js-cookie";
import axios from "axios";
const AccountConfiguration = () => {
  const [configData, setConfigData] = useState(null);

  async function getConfig() {
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    const api = "https://preprod-api.tbplabs.tech/user/config";
    let resp = await axios.get(api, config);
    setConfigData(resp.data.data);
  }
  useEffect(() => {
    getConfig();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <h1 className="account-setting"> Account Configuration </h1>
      <div className="form-container-config">
        <div className="property-form">
          <label>Property Name</label>
          <div style={{ display: "flex" }}>
            <input
              className="nameInput"
              placeholder="Enter Select Value"
            ></input>
            <span className="arrow-down">
              <KeyboardArrowDownIcon />
            </span>
          </div>
        </div>
        <div className="property-form">
          <label>Value</label>
          <div style={{ display: "flex" }}>
            <input
              className="nameInput"
              placeholder="Enter Select Value"
            ></input>
            <span className="arrow-down">
              <KeyboardArrowDownIcon />
            </span>
          </div>
        </div>
      </div>
      <button className="form-submit-btn">Create Account config</button>
      <div className="gap"></div>
      <h4 style={{ fontWeight: "bold" }}>Account Configuration Table</h4>

      <table className="people-form-table">
        <tr>
          <th>Name</th>
          <th>Enabled</th>
          <th>Delete</th>
        </tr>
        {configData !== null
          ? configData?.map((e) => (
              <tr>
                <td>{e.propertyType}</td>
                <td>
                  <Switch action={null} checked={e.installTags} />
                </td>
                <td>
                  <Delete style={{ color: "red" }} />
                </td>
              </tr>
            ))
          :(<p>Loading...</p>)
          }
      </table>
    </div>
  );
};

export default AccountConfiguration;
