import React, { useEffect, useState } from "react";
import "./Dropdown.css";
import candidateIcon from "../../../../Images/candidates-colored.png";
import downarrowIcon from "../../../assets/arrow-down-3101.svg";
import axios from "axios";

const Dropdown = (props) => {
  const online = {
    color: "#00d26a",
  };
  const offline = {
    color: "orange",
  };

  const [isOpen, setIsOpen] = useState(false);
  const [chooseName, setChooseName] = useState("");
  const [assignedTo, setAssignedTo] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [dropdownSearch, setDropdownSearch] = useState("");

  useEffect(() => {
    setAssignedTo(props.profile?.Data.object.assignedTo);
    setContactId(props.profile?.Data.object.id);
  }, [props.profile?.Data.object.assignedTo]);

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  async function contactUsingId(
    type,
    id,
    user__first_name,
    user__last_name,
    user__email,
    department
  ) {
    setChooseName(user__first_name);
    let myHeaders = new Headers();
    const auth_name = "auth_token";
    let authtoken = getCookie(auth_name);
    authtoken = "Token " + authtoken;
    myHeaders.append("Authorization", authtoken);
    const config = {
      headers: { Authorization: authtoken },
    };
    let data = {};
    if (type === "user") {
      data = {
        user__first_name: user__first_name,
        user__last_name: user__last_name,
        user__email: user__email,
        user__id: id,
        department: department,
        contactId: contactId,
      };
    } else {
      data = {
        name: user__first_name,
        id: id,
        department: department,
        contactId: contactId,
      };
    }

    const api = "https://preprod-api.tbplabs.tech/contacts/contact";
    let response;
    try {
      response = await axios.patch(api, data, config);
      setIsOpen(!isOpen);
    } catch (error) {
    }
  }
  useEffect(() => {
    setChooseName("");
    props.dropdownUp?.map((e) =>
      e.user__id == assignedTo ? setChooseName(e.user__first_name) : null
    );
  }, [assignedTo]);

  return (
    <div className="main-dropdwon-container">
      <div className="dropdown" onClick={() => setIsOpen((prev) => !prev)}>
        <img src={candidateIcon} alt=""></img>

        <span> {chooseName} </span>
        <img
          className="downarrow"
          id="downarrowIcon"
          src={downarrowIcon}
          alt=""
        />
      </div>
      <ul className={"drop-container" + (isOpen ? "IsOpen" : "IsClose")}>
        <input
          placeholder="🔎︎ search"
          onChange={(e) => setDropdownSearch(e.target.value)}
          className="search"
        ></input>
        <p className="dropdown-subHeading">Users</p>
        <hr id="divider"></hr>

        {props.dropdownUp != null ? (
          props.dropdownUp
            .filter((e) => {
              return dropdownSearch.toLowerCase() === ""
                ? e
                : e.user__first_name.toLowerCase().includes(dropdownSearch);
            })
            .map((e) => (
              <li
                onClick={() =>
                  contactUsingId(
                    "user",
                    e.user__id,
                    e.user__first_name,
                    e.user__last_name,
                    e.user__email,
                    e.department
                  )
                }
                className="drop-list"
              >
                <span>{e.user__first_name} </span>
                <span style={e.status !== "online" ? offline : online}>●</span>
              </li>
            ))
        ) : (
          <></>
        )}

        <p className="dropdown-subHeading">Team</p>
        <hr id="divider"></hr>
        {props.dropdownDown != null ? (
          props.dropdownDown
            .filter((e) => {
              return dropdownSearch.toLowerCase() === ""
                ? e
                : e.name.toLowerCase().includes(dropdownSearch);
            })
            .map((e) => (
              <li
                onClick={() =>
                  contactUsingId(
                    "team",
                    e.id,
                    e.name,
                    e.team__last_name,
                    e.user__email,
                    e.department
                  )
                }
                className="drop-list"
              >
                <span>{e.name} </span>
                <span style={e.status !== "online" ? offline : online}>●</span>
              </li>
            ))
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
};

export default Dropdown;
