import React, { useContext, useState, useEffect } from "react";
import CommunicateContext from "../../../../../../store/CommunicateContext";
import "./sidebarcomponent.css";
import TimeStamp from "../../ChatArea/Message/TimeStamp";
import group from "../../../../Images/group.png";
import Tag from "./Tag";

const SideBarComponent = ({
  name,
  text,
  time,
  objectid,
  ws,
  accountid,
  userid,
  setTempObjectId,
  unreadMessages,
  team,
  id,
  clearUnread,
  key,
  profile,
  tag,
  tagIdData,
  appAccountId,
  selectAll,
  setSelectedItems,
}) => {
  const communicateCtx = useContext(CommunicateContext);
  const [selected, setSelected] = useState(false);

  const getHistory = {
    action: "getChatHistory",
    data: {
      accountid: accountid,
      userid: userid,
      objectid: objectid,
      appAccountId: appAccountId,
      isPortal: true,
    },
  };

  const onClickHandler = (e) => {
    clearUnread(id);
    setTempObjectId(objectid);
    ws.send(JSON.stringify(getHistory));
    communicateCtx.setObject(objectid);
  };

   useEffect(() => {
     setSelected(selectAll);
   }, [selectAll]);

   const handleCheckboxChange = (e) => {
     const isChecked = e.target.checked;
      if (isChecked) {
        setSelected(true);
        setSelectedItems((prevSelectedItems) => ({
          ...prevSelectedItems,
          [objectid]: isChecked,
        }));
      } else{
        setSelected(false);
        setSelectedItems((prevSelectedItems) => {
          const updatedSelectedItems = { ...prevSelectedItems };
          delete updatedSelectedItems[objectid];
          return updatedSelectedItems;
        });
      }
     
   };

  return (
    <>
      <div className="">
        <div
          onClick={(e) => onClickHandler(e)}
          className={`msg ${
            objectid === communicateCtx.objectid ? "bg-[#EAEAEA]" : objectid
          }
          ${objectid !== communicateCtx.objectid ? "hover:bg-[#EAEAEA]" : null}
        `}
        >
          <div className={`msg-detail`}>
            <div className="msg-username">
              <div>
                <input
                  type="checkbox"
                  checked={selected}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                {name}{" "}
              </div>
              {team ? <img className="group-icon" src={group}></img> : ""}
              {team ? (
                <div className="group-modal">
                  {team.name}
                  <br></br>
                  {team.status}
                </div>
              ) : null}
            </div>

            <div className="msg-content">
              <span className="msg-message">{text}</span>
              <span className="time-stamp w-full">
                <TimeStamp isSideBar={true} time={time} />
              </span>
            </div>
          </div>

          <div className="w-full flex gap-2 flex-wrap">
            {profile &&
              tagIdData?.map((tagId, key) => {
                const taginfo = tag?.find((item) => item.id === tagId);
                return (
                  <Tag key={key} name={taginfo?.name} color={taginfo?.color} />
                );
              })}
          </div>

          {objectid === communicateCtx.objectid ? null : unreadMessages ? (
            <span className="unreadMessage">{unreadMessages}</span>
          ) : null}

          {/* {unreadMessages?<span className='unreadMessage'>{unreadMessages}</span>: null } */}
        </div>
      </div>

      <hr />
    </>
  );
};

export default SideBarComponent;
