import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { Autocomplete, Typography } from "@mui/material";
import "./FormComponent.css";
import { ContentModal } from "./ContentModal";

const FormComponent = forwardRef(
  ({ group, accountData, postBody, submit, errors }, ref) => {
    const [tempFile, settempFile] = useState(null);
    const [multiKeysState, setmultiKeysState] = useState({});
    const [formData, setformData] = useState({});
    const [formDataError, setformDataError] = useState({});
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [contentLink, setContentLink] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    let tempObj = {};
    let tempFileName = {};
    let multiKeys = {};
    let tempError = {};
    let myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders.append("Authorization", getCookie("auth_token")),
      redirect: "follow",
    };
    function getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    useEffect(() => {
      for (let i = 0; i < group.fields.length; i++) {
        tempError[group.fields[i].id] = { error: false, errorMsg: "" };
        if (group.fields[i]?.type == "MULTICHOICE") {
          let tempArrMulti = [];
          for (let j = 0; j < group.fields[i].list.length; j++) {
            tempObj[group.fields[i].list[j].id] = false;
            tempArrMulti.push(group.fields[i].list[j].id);
          }
          setSelectedOptions(group.fields[i].value);
          multiKeys[group.fields[i].id] = tempArrMulti;
        } else if (
          group.fields[i]?.type == "DOCUMENT" ||
          group.fields[i]?.type == "IMAGE"
        ) {
          tempObj[group.fields[i]?.id] = null;
          tempFileName[group.fields[i]?.id] = false;
        } else if (group.fields[i]?.type == "SINGLECHOICE") {
          tempObj[group.fields[i]?.id] = null;
        } else {
          tempObj[group.fields[i]?.id] = null;
        }
      }
      setmultiKeysState(multiKeys);

      settempFile(tempFileName);

      setformData(tempObj);

      setformDataError(tempError);
    }, []);

    const handleAutocompleteChange = (event, newValue) => {
      setSelectedOptions(newValue);
    };

    function onInputChange(e) {
      let temp = { ...formData, [e.target.name]: e.target.value };
      setformData(temp);
    }
    function onSwitchChange(e) {
      let temp = { ...formData, [e.target.name]: !formData[e.target.name] };
      setformData(temp);
    }
    function onCheckBoxChange(e) {
      let temp = { ...formData, [e.target.name]: !formData[e.target.name] };
      setformData(temp);
    }

    let fileUrl = [];
    useImperativeHandle(ref, () => ({
      setFileValueToJson(index) {
        let temp = [];
        let tempError = { ...formDataError };
        for (let i = 0; i < group.fields.length; i++) {
          if ([group.fields[i].id] in formData) {
            if (group.fields[i].type == "SINGLECHOICE") {
              if (group.fields[i].isRequired && !formData[group.fields[i].id]) {
                tempError[group.fields[i].id].error = true;
                tempError[group.fields[i].id].errorMsg = "required";
                errors = true;
              } else {
                temp.push({
                  id: group.fields[i].id,
                  type: group.fields[i].type,
                  value: {
                    id: formData[group.fields[i].id],
                    label: group.fields[i].list.find(
                      (item) => item.id === formData[group.fields[i].id]
                    ).label,
                  },
                });
              }
            } else {
              temp.push({
                id: group.fields[i].id,
                type: group.fields[i].type,
                value: {
                  type: group.fields[i].type,
                  value: formData[group.fields[i].id],
                },
              });
              if (group.fields[i].isRequired && !formData[group.fields[i].id]) {
                tempError[group.fields[i].id].error = true;
                tempError[group.fields[i].id].errorMsg = "required";
                errors = true;
              }
            }
          } else {
            let a = [];
            for (
              let j = 0;
              j < multiKeysState[group.fields[i].id].length;
              j++
            ) {
              if (formData[multiKeysState[group.fields[i].id][j]]) {
                let x = multiKeysState[group.fields[i].id][j];
                a.push({
                  id: multiKeysState[group.fields[i].id][j],
                  label: group.fields[i].list.find((i) => i.id === x).label,
                });
              }
            }

            temp.push({
              id: group.fields[i].id,
              type: group.fields[i].type,
              value: a,
            });
          }
        }
        setformDataError(tempError);
        postBody[index] = [...temp];
      },
    }));
    let tempImg = [];
    const [imageUrl, setImageUrl] = useState([]);
    const uploadFile = async (e, t) => {
      const form = new FormData();
      let tempFileName = {
        ...tempFile,
        [e.target.name]: true,
      };
      settempFile(tempFileName);

      for (let i = 0; i < e.target.files.length; i++) {
        form.append("file", e.target.files[i]);
        try {
          const response = await axios({
            method: "post",
            url: `https://preprod-api.tbplabs.tech/chatapp/uploadFile?accountId=${accountData.message.account.id}&userId=${accountData.message.user.id}`,
            data: form,
            headers: { "Content-Type": "multipart/form-data" },
          });
          fileUrl.push(response.data.data.fileUrl);
          if (t == "i") {
            tempImg.push(response.data.data.fileUrl);
            setImageUrl(tempImg);
          }
          let temp = { ...formData, [e.target.name]: fileUrl };
          setformData(temp);
        } catch (error) {}
      }
      tempFileName = {
        ...tempFile,
        [e.target.name]: false,
      };
      settempFile(tempFileName);
    };

    function openContentModal(link) {
      setContentLink(link);
      handleOpenModal();
    }

    return (
      <div className="accordian">
        {group.fields.map((item, key) => {
          return (
            <>
              {item.type == "TEXT" ? (
                <TextField
                  style={{ marginTop: "20px", width: "100%" }}
                  inputProps={{ style: { fontSize: 14 } }}
                  error={formDataError[item.id]?.error}
                  name={item.id}
                  onChange={(e) => onInputChange(e)}
                  value={item.value?.value}
                  label={item.label}
                  variant="standard"
                  color="warning"
                  helperText={formDataError[item.id]?.errorMsg}
                />
              ) : item.type == "NUMBER" ? (
                <TextField
                  style={{ marginTop: "20px", width: "100%" }}
                  inputProps={{ style: { fontSize: 14 } }}
                  name={item.id}
                  onChange={(e) => onInputChange(e)}
                  value={item.value?.value}
                  label={item.label}
                  type="number"
                  error={formDataError[item.id]?.error}
                  helperText={formDataError[item.id]?.errorMsg}
                  variant="standard"
                  color="warning"
                />
              ) : item.type == "BINARY" ? (
                <div className="inputDiv" style={{ margin: "8px 1px" }}>
                  <label sx={{ fontWeight: "bold" }} for="first">
                    {item.label}
                  </label>
                  <Switch
                    checked={item.value?.value}
                    name={item.id}
                    onChange={(e) => onSwitchChange(e)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              ) : item.type == "SINGLECHOICE" ? (
                <>
                  <FormControl
                    className="inputDiv"
                    style={{ margin: "8px", width: "100%" }}
                    error={formDataError[item.id]?.error}
                  >
                    <InputLabel>{item.label}</InputLabel>
                    {item && (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={item.value ?? ""}
                        variant="standard"
                        color="warning"
                      >
                        <MenuItem value={null}>Select</MenuItem>
                        {item.list.map((option, key) => {
                          return (
                            <MenuItem value={option.id}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    <FormHelperText>
                      {formDataError[item.id]?.errorMsg}
                    </FormHelperText>
                  </FormControl>
                </>
              ) : selectedOptions && item.type == "MULTICHOICE" ? (
                <div style={{ marginTop: "20px" }}>
                  <div>
                    <div>
                      <label
                        style={{ fontWeight: "bold" }}
                        color="warning"
                        htmlFor="first"
                      >
                        {item.label}:
                      </label>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Autocomplete
                          style={{ padding: "10px !important" }}
                          multiple
                          options={item.list}
                          getOptionLabel={(option) => option.label}
                          value={selectedOptions}
                          onChange={handleAutocompleteChange}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : item.type == "NOTE" ? (
                <TextField
                  style={{ marginTop: "20px" }}
                  name={item.id}
                  error={formDataError[item.id]?.error}
                  fullWidth
                  variant="standard"
                  color="warning"
                  multiline
                  rows={1}
                  label={item.label}
                  onChange={(e) => onInputChange(e)}
                  value={item.value?.value}
                  helperText={formDataError[item.id]?.errorMsg}
                />
              ) : item.type == "DOCUMENT" ? (
                <div>
                  <input
                    onChange={(e) => uploadFile(e, "d")}
                    style={{ display: "none" }}
                    id={item.id}
                    multiple
                    name={item.id}
                    type="file"
                  />
                  <label htmlFor={item.id}>
                    <Button
                      style={{
                        marginTop: "30px",
                        fontSize: "13px",
                        width: "100%",
                        backgroundColor: "#FFEED8 ",
                        marginBottom: "10px",
                      }}
                      variant="standard"
                      color="warning"
                      component="span"
                    >
                      {item.label}
                    </Button>
                  </label>
                  <div></div>
                  <span style={{ textAlign: "center", display: "block" }}>
                    {item.value?.value ? <CheckCircleOutlineIcon /> : null}{" "}
                    {item.value?.value ? item.value?.value?.length : "0"}{" "}
                    file(s) uploaded.
                    {item.value?.value
                      ? item.value?.value?.map((e, index) => (
                          <li
                            style={{ cursor: "pointer", color: "#DD621C" }}
                            fontSize="10px"
                            onClick={() => openContentModal(e)}
                          >
                            Document {index + 1}
                          </li>
                        ))
                      : "0"}
                    {tempFile ? (
                      <CircularProgress
                        style={{
                          marginLeft: "8px",
                          display: tempFile[item.id] ? "inline-block" : "none",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    ) : null}
                  </span>
                </div>
              ) : item.type == "IMAGE" ? (
                <div>
                  <input
                    accept="image/*"
                    onChange={(e) => uploadFile(e, "i")}
                    style={{ display: "none" }}
                    id={item.id}
                    multiple
                    name={item.id}
                    type="file"
                  />
                  <label htmlFor={item.id}>
                    <Button
                      style={{
                        marginTop: "30px",
                        width: "100%",
                        fontSize: "13px",
                        backgroundColor: "#FFEED8 ",
                        marginBottom: "10px",
                      }}
                      variant="standard"
                      color="warning"
                      component="span"
                    >
                      {item.label}
                    </Button>
                  </label>
                  <div>
                    {imageUrl.map((item, key) => {
                      return (
                        <a target="_blank" href={item}>
                          <img
                            src={item}
                            style={{
                              margin: "0 10px",
                              objectFit: "cover",
                              width: "50px",
                              height: "50px",
                            }}
                            alt="img"
                          />
                        </a>
                      );
                    })}
                  </div>
                  <span style={{ textAlign: "center", display: "block" }}>
                    {item.value?.value ? <CheckCircleOutlineIcon /> : null}{" "}
                    {item.value?.value ? item.value?.value?.length : "0"}{" "}
                    file(s) uploaded.
                    {item.value?.value
                      ? item.value?.value?.map((e, index) => (
                          <li
                            style={{ cursor: "pointer", color: "#DD621C" }}
                            onClick={() => openContentModal(e)}
                          >
                            Image {index + 1}
                          </li>
                        ))
                      : "0"}{" "}
                    {tempFile ? (
                      <CircularProgress
                        style={{
                          display: tempFile[item.id] ? "inline-block" : "none",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    ) : null}
                  </span>
                </div>
              ) : (
                <div>
                  <label for="first">First</label>
                  <input type="text" />
                </div>
              )}
            </>
          );
        })}
        <ContentModal
          contentLink={contentLink}
          openModal={openModal}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
        />
      </div>
    );
  }
);
export default FormComponent;
