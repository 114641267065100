import React from 'react'
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "./Modal"
import { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import { ChromePicker } from "react-color";




function TagSetting() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tag, setTag] = useState();
  const [rowData, setRowData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "tagName", headerName: "Tag name", width: 200 },
    {
      field: "tagColor",
      headerName: "Color",
      width: 200,
      renderCell: (params) => (
        <div
          style={{
            width: 24,
            height: 24,
            backgroundColor: params.value,
            borderRadius: "50%",
          }}
        />
      ),
    },
    { field: "description", headerName: "Description", width: 400 },
  ];


  const handleClose = () => {
    setIsModalOpen(false);
  };

  async function fetchTagData() {
    try {
      const response = await fetch(
        "https://preprod-api.tbplabs.tech/user/tag",
        {
          headers: {
            Authorization: `Token ${Cookies.get("auth_token")}`,
          },
        }
      );
      const data = await response.json();
      setTag(data.content);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchTagData();
  }, []);

  useEffect(() => {
    if (tag) {
      const row = tag.map((tag) => ({
        id: tag.id,
        tagName: tag.name,
        tagColor: tag.color,
        description: tag.description,
      }));
      setRowData(row);
    }
  }, [tag]);

  const handlePageChange = (params) => {
    setCurrentPage(params.page);
  };

  return (
    <>
      <div className="w-full">
        <div className="flex items-center justify-between w-full">
          <div>
            <div className="text-2xl font-extrabold">Tags</div>
            <p className="text-gray-500 mt-2">
              Find all of your users and their associated tags.
            </p>
          </div>
          <div className="flex items-center gap-2">
            <div className="">
              <Button onClick={() => setIsModalOpen(true)} variant="contained">
                Add tags
              </Button>
            </div>
            <Modal open={isModalOpen} handleClose={handleClose} />
          </div>
        </div>
        <div className="mt-6" style={{ height: "75vh", width: "100%" }}>
          <DataGrid
            rows={rowData ? rowData : []}
            columns={columns}
            pageSize={15}
            page={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default TagSetting