import React from 'react'
import './AccountSetting.css'

const AccountSetting = (props) => {
  return (
    <div className='acc-card'>
      
      <h1 className='account-setting'>Account Information</h1>
      <div className='accountSettingcontainer'>
         <div className='acc-setting-id'>ACCOUNT ID: {props.profile.message.profile.crmUserId}</div>
         <div className='acc-details'><span className='acc-title'>Name :</span> {props.profile.message.user.first_name} {props.profile.message.user.last_name}</div>
         <div className='acc-details'><span className='acc-title'>Email :</span> {props.profile.message.user.email} </div>
         <div className='acc-details'><span className='acc-title'>Department :</span> {props.profile.message.profile.department}</div>
         <div className='acc-details'><span className='acc-title'>User Type :</span> {props.profile.message.profile.usertype}</div>
         <div className='acc-details'><span className='acc-title'>Remark :</span> {props.profile.message.profile.remark}</div>
         <div className='acc-details'><span className='acc-title'>Phone Number :</span> {props.profile.message.profile.phoneNumber}</div>
           
      </div>
    </div>
  )
}

export default AccountSetting