import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import AuthApi from './AuthApi'
import 'cross-fetch/polyfill';
import Bg_1 from "./login-bg-1.png"
import Bg_2 from "./login-bg-2.png"
function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function eraseCookie(name) {   
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function Login() {
  const [error, setError] = useState('')
  const[currentUser, SetCurrentUser] = useState({
          username:'',
          password:''
        })
  const history = useHistory()
  const Auth = React.useContext(AuthApi)
  
  // Taking Input from user
  function handleChange(event) {
    SetCurrentUser(e => ({
      ...e,
      [event.target.name]: event.target.value
    }))
  }

  // Saving user input in local storage
    localStorage.setItem('users' , JSON.stringify(currentUser))
    var users = JSON.parse(localStorage.getItem('users'))

  // Calling login api on submit button click
  function handleClick(e){
    e.preventDefault()

    var header = new Headers();
    header.append("Content-Type", "application/json");

    var payload = JSON.stringify({
      "username": users.username,
      "password": users.password
    });

    var requestOptions = {
      method: 'POST',
      headers: header,
      body: payload,
      redirect: 'follow',
      credentials: "include"
 };
 
// // Handleling data as per login api calling result
function handleResult(data){
  data = JSON.parse(data)
  // console.log(data)
  if(data.Status === 'Success'){
    const name='auth_token'
    setCookie(name,data.token,15)
    checkMEAPI()
    Auth.setAuth(true)
    // console.log(data)
  } if(data.status === 'Failed'){
    // console.log(data)
    setError(data.message)
  }
  return 
}

//Fetching Login api
 fetch("https://preprod-api.tbplabs.tech/user/login", requestOptions)
  .then(response => response.text())
  .then(data => handleResult((data)))
  .then(data=>console.log(data))
  .catch(error => console.log('error', error));
  }

  // check ME API
function checkMEAPI(){
  let myHeaders = new Headers();
  const name='auth_token'
  let authtoken=getCookie(name)
  authtoken="Token "+authtoken
  myHeaders.append("Authorization", authtoken);

  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    credentials: "include"
  };

  function handleMEAPI(result){
    if(result.Status === 'Success'){
      console.log(result.Status)
      //Auth.setAuth(true)
      //history.push('/Home')
    }
    else console.log(result)
  }
//Calling ME api
  fetch("https://preprod-api.tbplabs.tech/user/me", requestOptions)
    .then((response) => response.text())
    .then((result) => handleMEAPI(JSON.parse(result)))
    .then((data) => console.log(data))
    .catch((error) => (
      document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;',
        Auth.setAuth(false),
        history.push('/Login')
    ));
  }

useEffect(() => {
  checkMEAPI()
},[currentUser])

  return (
    <div>
      <form className="login-container w-80">
        <div className="login-header-1">Sign in</div>
        <div className="login-header-2">Sign in and Assemble your Team</div>
        <input
          type="text"
          className="bg-[#74cce8] mb-7 w-full opacity-50 rounded-xl px-4 py-3 text-center  placeholder-gray-600"
          placeholder="Username"
          name="username"
          value={currentUser.username}
          onChange={handleChange}
        />
        <input
          type="password"
          className={`bg-[#74cce8] ${error? "mb-2": "mb-7"} w-full opacity-50 rounded-xl px-4 py-3 text-center  placeholder-gray-600`}
          placeholder="Password"
          name="password"
          value={currentUser.password}
          onChange={handleChange}
        />
        {error && <div className="text-red-500 my-1 text-center">{error}</div>}
        <div className="login-options">
          <input type="checkbox" className="login-checkbox"></input>
          <label type="checkbox" className="login-checkbox-text">
            Remember me
          </label>
          <span
            onClick={() => alert("Forgot password has not available yet.")}
            className="forgot-password"
          >
            Forgot password?
          </span>
        </div>
        <button
          onClick={handleClick}
          className="bg-[#74cce8] text-[#224957] text-lg text-center capitalize rounded-lg cursor-pointer font-bold shadow-md w-full py-2"
        >
          Login
        </button>
        <img
          src={Bg_1}
          alt="login-background"
          className="absolute bottom-0 h-28 left-0 w-full"
        />
        <img
          src={Bg_2}
          alt="login-background"
          className="absolute bottom-0 h-28 left-0 w-full"
        />
      </form>
    </div>
  );
}

export default Login