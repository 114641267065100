import React, { useState, useEffect, useMemo } from "react";
import SideBarComponent from "./SideBarComponent/SideBarComponent";
import "./sidebar.css";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import AddUserModal from "../Modals/AddUserModal";
import ArchiveIcon from "@mui/icons-material/Archive";
import TagFilterInput from "./TagFilterInput";
import { Badge } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import { atom, useAtom } from "jotai";

import FilterModal from "./FilterModal"

export const filteredContactsAtom = atom()

const SideBar = ({
  ws,
  accountid,
  userid,
  sidebarData,
  inboundSidebarData,
  setSidebarData,
  inboundSearchData,
  setTempObjectId,
  getSidebarListingByApi,
  connectionId,
  profile,
  tag,
  setIsArchived,
  isArchived,
  selectedTags,
  setSelectedTags,
  appAccountId,
  totalMessages,
}) => {
  const [inputText, setInputText] = useState("");
  const [filter, setFilter] = useState({
    order: "newest",
    hot: false,
    cold: false,
    read: false,
    unread: false,
  });
  let show = false;
  let contacts = [];
  const [showModal, setShowModal] = useState(false);

  const [filteredContacts, setFilteredContacts] = useState(contacts);

  const [filteredContactsGlobal, setFilteredContactsGlobal] = useAtom(filteredContactsAtom)
  const [display, setDisplay] = useState(true);
 

  const [selectedDate, setSelectedDate] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function clearUnread(i) {
    let temp = [...filteredContactsGlobal];
    let index = temp.findIndex((x) => x.id === i);
    temp[index].unreadMessages = 0;
    setFilteredContactsGlobal(temp);
  }
  useEffect(() => {
    //Search function
    const newContacts = sidebarData.filter(
      (contact) =>
        contact.name?.toLowerCase().includes(inputText.toLowerCase()) ||
        (contact.WANumber + "")?.includes(inputText.toLowerCase())
    );
      setFilteredContactsGlobal(newContacts);
    // setFilteredContacts(newContacts);
  }, [inputText, sidebarData]);

  const search = {
    action: "searchContact",
    data: {
      accountid: accountid,
      userid: userid,
      searchQuery: inputText,
      isPortal: true,
    },
  };

  useEffect(() => {
    // handling new sidebar element when it exists and doesn't exist
    let exists = false;
    sidebarData.map((contact) => {
      if (
        inboundSidebarData &&
        inboundSidebarData[0].objectId === contact.objectId
      ) {
        exists = true;
        let index = sidebarData.indexOf(contact);
        //removing old element
        if (index > -1) {
          sidebarData.splice(index, 1);
        }
        let newSidebar = [inboundSidebarData[0], ...sidebarData];
        setSidebarData(newSidebar);
      }
    });
    if (inboundSidebarData && exists === false) {
      contacts.push(inboundSidebarData[0]);
      setSidebarData((prevValue) => [inboundSidebarData[0], ...prevValue]);
    }
  }, [inboundSidebarData]);

  useEffect(() => {
    // console.log("here");
    if (inboundSearchData) {
      inboundSearchData.map((contact) => {
        sidebarData.map((sidebarContact) => {
          if (contact !== sidebarContact) {
            setSidebarData((prevValue) => [contact, ...prevValue]);
          }
        });
      });
    }
  }, [inboundSearchData]);

  // const handleInput = (e) => {
  //   const { name, value } = e.target;
  //   let temp;

  //   if (name === "order") {
  //     temp = { ...filter, [name]: value };
  //   } else {
  //     temp = { ...filter, [name]: !filter[name] };
  //   }

  //   if (name === "chatExpiringBy") {
  //     const selectedDate = value;
  //     const currentDate = new Date().toISOString().slice(0, 16); // Get current date in "yyyy-MM-ddTHH:mm" format

  //     if (selectedDate >= currentDate) {
  //       temp = { ...temp, [name]: selectedDate };
  //       console.log(temp)
  //       setSelectedDate(selectedDate);
  //     } else {
  //       alert("Invalid date selection. Please choose a date in the future.");
  //       setSelectedDate("");
  //       return; // Prevent updating state if the selected date is in the past
  //     }
  //   }

  //   setFilter(temp);
  // };
  const handleInput = (value, name) => {
    let temp;

    if (name === "order") {
      temp = { ...filter, [name]: value };
    } else {
      temp = { ...filter, [name]: !filter[name] };
    }

    if (name === "chatExpiringBy") {
      const selectedDate = value;
      const currentDate = new Date().toISOString().slice(0, 16); // Get current date in "yyyy-MM-ddTHH:mm" format

      if (selectedDate >= currentDate) {
        temp = { ...temp, [name]: selectedDate };
        console.log(temp);
        setSelectedDate(selectedDate);
      } else {
        alert("Invalid date selection. Please choose a date in the future.");
        setSelectedDate("");
        return; // Prevent updating state if the selected date is in the past
      }
    }

    setFilter(temp);
  };

  const handleSearch = () => {
    // console.log("searching");
    getSidebarListingByApi(inputText, filter.order, filter);
    handleClose();
  };

  const handleAdd = () => {
    // console.log("foo");
    setShowModal(true);
  };

  return (
    <div className="conversation-area pt-2">
      <div className="relative flex justify-evenly items-center mb-5">
        <input
          placeholder="Search..."
          value={inputText}
          onMouseEnter  ={()=>setDisplay(!display)}
          onMouseLeave ={()=>setDisplay(!display)}
          onChange={(event) => {
            setInputText(event.target.value);
            if (event.target.value === "") {
              getSidebarListingByApi("", filter.order, filter);
            }
          }}
          // style={{transitionDuration: "0.5s"}}
          className="bg-gray-200 rounded-xl p-2"
          style={display?{width:"50%", transitionDuration: "0s"}:{width:"80%",transitionDuration: "0.5s" }}
        />
        <div style={display===true?{display:"flex"}:{display:"none"}}>
        <Badge sx={{marginRight:"10px"}} badgeContent={totalMessages} color="primary">
          <MailIcon color="action" />
        </Badge>
        <TuneIcon
          style={{ color: "grey" }}
          id="fIcon"
          className="cursor-pointer"
          // onClick={() => {
          //   document.getElementById("filters").style.display = show
          //     ? "none"
          //     : "block";
          //   show = !show;
          //   document.getElementById("fIcon").style.color = show
          //     ? "#0086ff"
          //     : "grey";
          // }}
          onClick={handleOpen}
        />
        <FilterModal
          open={open}
          handleClose={handleClose}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          tag={tag}
          filter={filter}
          handleInput={handleInput}
          handleSearch={handleSearch}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <SearchIcon
          className="absolute cursor-pointer right-[8.5rem]"
          style={{ color: "#0086ff" }}
          onClick={handleSearch}
        ></SearchIcon>
        <PersonAddAltRoundedIcon
          onClick={handleAdd}
          style={{ color: "grey" }}
          className="cursor-pointer"
        />
        <ArchiveIcon
          onClick={() => {
            setIsArchived(!isArchived);
          }}
          style={{ color: isArchived ? "#0086ff" : "grey" }}
          className="cursor-pointer"
        />
        </div>
      </div>
      {showModal && (
        <AddUserModal
          showModal={showModal}
          setShowModal={setShowModal}
          connectionId={connectionId}
          appAccountId={appAccountId}
        />
      )}
      <div id="filters" className="hidden">
        <TagFilterInput
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          tag={tag}
        />
        <div className="flex">
          <ul className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg  border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
              <div className="flex items-center pl-3">
                <input
                  id="list-radio-license"
                  type="radio"
                  value="newest"
                  checked={filter.order === "newest"}
                  name="order"
                  onChange={(e) => {
                    handleInput(e);
                  }}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="list-radio-license"
                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Newest
                </label>
              </div>
            </li>
            <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
              <div className="flex items-center pl-3">
                <input
                  id="list-radio-id"
                  type="radio"
                  value="oldest"
                  checked={filter.order === "oldest"}
                  name="order"
                  onChange={(e) => {
                    handleInput(e);
                  }}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="list-radio-id"
                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Oldest
                </label>
              </div>
            </li>
          </ul>
          <ul className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg  border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
              <div className="flex items-center pl-3">
                <input
                  id="vue-checkbox"
                  name="unread"
                  type="checkbox"
                  checked={filter.unread}
                  onChange={(e) => {
                    handleInput(e);
                  }}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="vue-checkbox"
                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Unread
                </label>
              </div>
            </li>
            <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
              <div className="flex items-center pl-3">
                <input
                  id="react-checkbox"
                  name="read"
                  type="checkbox"
                  checked={filter.read}
                  onChange={(e) => {
                    handleInput(e);
                  }}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="react-checkbox"
                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Read
                </label>
              </div>
            </li>
            <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
              <div className="flex items-center pl-3">
                <input
                  id="angular-checkbox"
                  name="hot"
                  type="checkbox"
                  checked={filter.hot}
                  onChange={(e) => {
                    handleInput(e);
                  }}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="angular-checkbox"
                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Hot
                </label>
              </div>
            </li>
            <li className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
              <div className="flex items-center pl-3">
                <input
                  id="laravel-checkbox"
                  name="cold"
                  type="checkbox"
                  checked={filter.cold}
                  onChange={(e) => {
                    handleInput(e);
                  }}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="laravel-checkbox"
                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Cold
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div className="flex  justify-center">
          <button
            type="button"
            onClick={() => {
              handleSearch();
              document.getElementById("filters").style.display = "none";
              document.getElementById("fIcon").style.color = "grey";
            }}
            className="w-4/5 my-2 text-white bg-[#0086ff] hover:bg-[#0077ff] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Apply
          </button>
        </div>
      </div>
      {filteredContactsGlobal?.map((e, id) => {
        return (
          <SideBarComponent
            key={e.id}
            id={e.id}
            name={e.name}
            text={e.Text}
            unreadMessages={e.unreadMessages}
            time={e.created_at}
            objectid={e.objectId}
            image={e.medialurl}
            accountid={accountid}
            appAccountId={appAccountId}
            userid={userid}
            ws={ws}
            setTempObjectId={setTempObjectId}
            team={e.team}
            clearUnread={clearUnread}
            profile={profile}
            tag={tag}
            tagIdData={e.tags}
            
          />
        );
      })}
    </div>
  );
};

export default SideBar;
