import { Box, Button, Modal } from "@mui/material";
import React from "react";

export const ContentModal = ({
  contentLink,
  openModal,
  handleOpenModal,
  handleCloseModal,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70vw",
    maxWidth:"1000px",
    bgcolor: "background.paper",
    boxShadow: 4,
   height:"70vh"
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <iframe
            src={contentLink}
            width="100%"
            height="100%"
            frameborder="0"
          ></iframe>
        </Box>
      </Modal>
    </div>
  );
};
