import React, { useState, useEffect } from "react";
import AccountSetting from "./AccountSetting/AccountSetting";
import PeopleSetting from "./PeopleSetting/PeopleSetting";
import TeamSetting from "./TeamSetting/TeamSetting";
import Listing from "./Listings/Listing";
import "./SettingSidebar.css";
import AccountConfiguration from "./AccountConfiguration/AccountConfiguration";
import AuthApi from "../AuthApi";
import { useHistory } from "react-router-dom";
import axios from "axios";
import RoleSetting from "./RoleSetting/RoleSetting";
import TagSetting from "./TagSetting/TagSetting";

const SettingsComponent = () => {
  const [active, setActive] = useState("");
  const [accountData, setAccountData] = useState();
  const [permission, setpermission] = useState();
  const history = useHistory();
  function changeActiveElement(active) {
    setActive(active);
  }
  const Auth = React.useContext(AuthApi);
  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  let myHeaders = new Headers();
  useEffect(() => {
    const name = "auth_token";
    let authtoken = getCookie(name);
    authtoken = "Token " + authtoken;
    //console.log(authtoken)
    myHeaders.append("Authorization", authtoken);
    //console.log(myHeaders)

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    function handleData(result) {
      if (result.detail !== "Invalid token.") {
        console.log(result);
      } else return;
    }
    getMeApi(requestOptions);
  }, []);

  async function getMeApi() {
    let myHeaders = new Headers();
    const auth_name = "auth_token";
    let authtoken = getCookie(auth_name);
    authtoken = "Token " + authtoken;
    myHeaders.append("Authorization", authtoken);
    const config = {
      headers: { Authorization: authtoken },
    };

    try {
      const resp = await axios.get(
        "https://preprod-api.tbplabs.tech/user/me",
        config
      );
      setAccountData(resp.data);
      setpermission(resp.data.message.permission);
    } catch (err) {
      Auth.setAuth(false);
      history.push("/Login");
    }
  }

  useEffect(()=>{console.log(permission)},[permission])

  const pageStyle = {
    display: "flex",
  };
  return (
    <div style={pageStyle}>
      {/* SETTING SIDEBAR */}
      <div className="sideBarcontainer">
        <h2>General Settings</h2>
        <hr></hr>
        <ul>
          <li onClick={() => changeActiveElement("account")}>
            Account Information
          </li>
          <li onClick={() => changeActiveElement("configuration")}>
            Configuration
          </li>
        </ul>

        <h2>Account Directory</h2>
        <hr></hr>
        <ul>
          {permission?.VIEW_USER && (
            <li onClick={() => changeActiveElement("people")}>People</li>
          )}
          {permission?.VIEW_TEAM && (
            <li onClick={() => changeActiveElement("team")}>Team</li>
          )}

          <li onClick={() => changeActiveElement("listing")}>Templates</li>
          {permission?.VIEW_ROLE_LIST && (
            <li onClick={() => changeActiveElement("roles")}>Roles</li>
          )}
          <li onClick={() => changeActiveElement("tags")}>Tags</li>
        </ul>
      </div>

      {/* SETTING PAGES COMONENTS */}
      {active === "account" && <AccountSetting profile={accountData} />}
      {active === "people" && <PeopleSetting permission={permission} />}
      {active === "team" && <TeamSetting permission={permission} />}
      {active === "listing" && <Listing />}
      {active === "configuration" && <AccountConfiguration />}
      {active === "roles" && <RoleSetting />}
      {active === "tags" && <TagSetting />}
    </div>
  );
};

export default SettingsComponent;
