import React, { useEffect, useState } from "react";
import search_icon from "./Images/search-icon.png";
import bell_icon from "./Images/notification-icon.png";
import dot_icon from "./Images/Ellipse.png";
import AuthApi from "../AuthApi";
import { useHistory } from "react-router-dom";
import "./Candidates/Candidates.css";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbar,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Sidebar from "../utils/components/Sidebar";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      shape="rounded"
    />
  );
}

function Candidates() {
  const Toggle = React.useContext(AuthApi);
  const history = useHistory();
  const [response, setResponse] = useState();
  const [rawResponse, setRawResponse] = useState();
  const [page, setPage] = useState(1);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(55);
  const [finalClickInfo, setFinalClickInfo] = useState(null);

  const handleOnCellClick = (params) => {
    setFinalClickInfo(params);
  };
  const columns = [
    { field: "id", type: "number", headerName: "ID", width: 70 },
    { field: "Name", headerName: "Name", width: 160 },
    {
      field: "Status",
      headerName: "Status",
      width: 90,
      renderCell: (rowData) =>
        rowData.row.Status ? (
          <Chip label={rowData.row.Status} color="primary" variant="outlined" />
        ) : (
          ""
        ),
    },
    { field: "Email", headerName: "Email", width: 240 },
    {
      field: "candidateid",
      headerName: "Candidate ID",
      width: 140,
    },
    { field: "Score", type: "number", headerName: "Score", width: 100 },
    {
      field: "Account_id",
      type: "number",
      headerName: "Account ID",
      width: 100,
    },
    {
      field: "PrimaryPhone",
      type: "number",
      headerName: "PrimaryPhone",
      width: 160,
    },
    {
      field: "SecondaryPhone",
      type: "number",
      headerName: "SecondaryPhone",
      width: 160,
    },
    {
      field: "Contact",
      headerName: "Contact",
      width: 450,
      renderCell: (rowData) =>
        rowData?.row.Contact?.map((e) => <Chip label={e} />),
    },
  ];

  if (response) {
    var rows = response.map((e) => ({
      id: e.id,
      Name: e.Name,
      Status: e.Status,
      Email: e.Email,
      candidateid: e.candidateid,
      Score: e.Score,
      Account_id: e.Account_id,
      PrimaryPhone: e.PrimaryPhone,
      SecondaryPhone: e.SecondaryPhone,
      Jobs: e.Jobs?.map((f) => f.Label),
    }));
  }

  /////

  //showing sidebar as per toggle
  const [currentSidebarStyle, setCurrentSidebarStyle] = useState(
    Toggle.toggleSlider
      ? {
          position: "fixed",
          width: "235px",
          maxWidth: "100%",
          height: "100%",
          background: "#ffffff",
          boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
          transition: "0.1s",
        }
      : {
          position: "fixed",
          width: "75px",
          maxWidth: "100%",
          height: "100%",
          background: "#ffffff",
          boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
          transition: "0.1s",
        }
  );
  // showing search container as per toggle
  const [searchContainerStyle, setSeachContainerStyle] = useState(
    Toggle.toggleSlider
      ? {
          marginLeft: "235px",
          display: "flex",
          flexDirection: "column",
        }
      : {
          marginLeft: "75px",
          display: "flex",
          flexDirection: "column",
        }
  );

  // handeling toggle changes
  function handleToggle() {
    Toggle.setToggleSlider(!Toggle.toggleSlider);
    if (Toggle.toggleSlider) {
      setCurrentSidebarStyle((prevStyle) => ({
        ...prevStyle,
        width: "75px",
        transition: "0.3s",
      }));
      setSeachContainerStyle((prevStyle) => ({
        ...prevStyle,
        marginLeft: "75px",
        transition: "0.3s",
      }));
    } else {
      setCurrentSidebarStyle((prevStyle) => ({
        ...prevStyle,
        width: "235px",
        transition: "0.3s",
      }));
      setSeachContainerStyle((prevStyle) => ({
        ...prevStyle,
        marginLeft: "235px",
        transition: "0.5s",
      }));
    }
  }

  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const [userOnline, setUserOnline] = useState("");
  const Auth = React.useContext(AuthApi);
  var raw = "";
  let myHeaders = new Headers();
  const name = "auth_token";
  let authtoken = getCookie(name);
  authtoken = "Token " + authtoken;
  myHeaders.append("Authorization", authtoken);

  // handeling click on logout button
  function handleClick() {
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    function eraseCookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    function handleResult() {
      localStorage.removeItem("userDetails");
      eraseCookie(name);
      Auth.setAuth(false);
    }

    // calling logout api
    fetch("http://register.aviate.Contact/user/logout", requestOptions)
      .then((response) => response.text())
      .then((result) => handleResult())
      .catch((error) => console.log("error", error));
  }

  function getCandidates() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${Cookies.get("auth_token")}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    async function handleResult(result) {
      let res = await JSON.parse(result);
      let resData = await res.content;
      setResponse(resData);
      setRawResponse(res);
    }

    fetch(
      "https://preprod-api.tbplabs.tech/candidates/CandidateSummary?OFFSET=" +
        offset +
        "&LIMIT=" +
        limit,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => handleResult(result))
      .catch((error) => console.log("error", error));
  }

  const getParams = () => {
    const params = new URLSearchParams(
      "https://preprod-api.tbplabs.tech/candidates/CandidateSummary?OFFSET=" +
        offset +
        "&LIMIT=" +
        limit
    );
  };

  useEffect(() => {
    getParams();
    getCandidates();
  }, [offset]);

  useEffect(() => {
    getCandidates();
    getParams();
  }, []);

  function handleAddCandidate() {
    history.push("/AddCandidate");
  }

  // // getting user details from local storage to use it in sidebar
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <div className="dashboard">
      <Sidebar active="Candidates" />
      <div className="search-container" style={searchContainerStyle}>
        <input
          className="search-box"
          type="text"
          placeholder=" Global Search"
        ></input>
        <img className="seach-icon" src={search_icon} />
        <img className="bell-icon" src={bell_icon} />
        <img className="dot-icon" src={dot_icon} />
        {/* <img src={add_candidates} className='add-candidates' alt='add-candidates-img' />
                <div className="add-candidates-text" >Add Candidate</div> */}
        <Button
          onClick={handleAddCandidate}
          variant="contained"
          sx={{
            width: "160px",
            height: "42px",
            marginTop: "15px",
            marginBottom: "5px",
            marginLeft: "85%",
          }}
        >
          Add Candidates
        </Button>
        <div className="data-container candidate-data-box">
          <div className="candidate-table">
            <div style={{ height: "80vh", width: "100%" }}>
              {response && (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={20}
                  rowsPerPageOptions={[5, 10, 15]}
                  checkboxSelection
                  pagination
                  onCellClick={handleOnCellClick}
                  components={{
                    Pagination: CustomPagination,
                    Toolbar: GridToolbar,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Candidates;
