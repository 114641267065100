import React, { useEffect, useState } from "react";
import dashboard_logo from "./Images/dashboard.png";
import logout_btn from "./Images/logout-btn.png";
import Contact_logo from "./Images/jobs.png";
import campaigns_1 from "./Images/campaigns-1.png";
import campaigns_2 from "./Images/campaigns-2.png";
import candidates_logo from "./Images/candidates.png";
import communicate_logo from "./Images/communicate.png";
import analytics_1 from "./Images/analytics-1.png";
import analytics_2 from "./Images/analytics-left.png";
import analytics_3 from "./Images/analytics-right.png";
import settings_logo from "./Images/settings-colored.png";
import toggle from "./Images/toggle.png";
import search_icon from "./Images/search-icon.png";
import bell_icon from "./Images/notification-icon.png";
import toggle_colored from "./Images/toggle-colored.png";
import dot_icon from "./Images/Ellipse.png";
import AuthApi from "../AuthApi";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import CandidateById from "./Candidates/CandidateById.css";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import StarBorder from "@mui/icons-material/StarBorder";

function CandidateByID() {
  const Toggle = React.useContext(AuthApi);
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [openb, setOpenb] = React.useState(true);
  const [customData, setCustomData] = React.useState();
  const [profileData, setProfileData] = React.useState();

   const handleClickList = () => {
     setOpen(!open);
   };
   const handleClickListb = () => {
     setOpenb(!openb);
   };
  //showing sidebar as per toggle
  const [currentSidebarStyle, setCurrentSidebarStyle] = useState(
    Toggle.toggleSlider
      ? {
        position: "fixed",
        width: "235px",
        maxWidth: "100%",
        height: "100%",
        background: "#ffffff",
        boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
        transition: "0.1s",
      }
    : {
        position: "fixed",
        width: "75px",
        maxWidth: "100%",
        height: "100%",
        background: "#ffffff",
        boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
        transition: "0.1s",
      }
  );
  // showing search container as per toggle
  const [searchContainerStyle, setSeachContainerStyle] = useState(
    Toggle.toggleSlider
      ? {
          marginLeft: "235px",
          display: "flex",
          flexDirection: "column",
        }
      : {
          marginLeft: "75px",
          display: "flex",
          flexDirection: "column",
        }
  );

  // handeling toggle changes
  function handleToggle() {
    Toggle.setToggleSlider(!Toggle.toggleSlider);
    if (Toggle.toggleSlider) {
      setCurrentSidebarStyle((prevStyle) => ({
        ...prevStyle,
        width: "75px",
        transition: "0.3s",
      }));
      setSeachContainerStyle((prevStyle) => ({
        ...prevStyle,
        marginLeft: "75px",
      }));
    } else {
      setCurrentSidebarStyle((prevStyle) => ({
        ...prevStyle,
        width: "235px",
        transition: "-0.5s",
      }));
      setSeachContainerStyle((prevStyle) => ({
        ...prevStyle,
        marginLeft: "235px",
      }));
    }
  }

  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const [userOnline, setUserOnline] = useState("");
  const Auth = React.useContext(AuthApi);
  var raw = "";
  let myHeaders = new Headers();
  const name = "auth_token";
  let authtoken = getCookie(name);
  authtoken = "Token " + authtoken;
  //console.log(authtoken)
  myHeaders.append("Authorization", authtoken);
  //console.log(myHeaders)

  /////////////
  const profile = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 65b4ee08a1e04c51213d31493b7e7550d130feaa"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    

    fetch("https://preprod-api.tbplabs.tech/candidates/703", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setProfileData(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));
  }

  const customFields = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 65b4ee08a1e04c51213d31493b7e7550d130feaa"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

   

    fetch(
      "https://preprod-api.tbplabs.tech/candidates/CustomFields",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setCustomData(JSON.parse(result))
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    customFields();
    profile();
  },[])

  /////////

  // handeling click on logout button
  function handleClick() {
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    function eraseCookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    function handleResult() {
      localStorage.removeItem("userDetails");
      eraseCookie(name);
      Auth.setAuth(false);
    }

    // calling logout api
    fetch("http://register.aviate.Contact/user/logout", requestOptions)
      .then((response) => response.text())
      .then((result) => handleResult())
      .catch((error) => console.log("error", error));
  }

  

  function redirectToDashboard() {
    history.push("/Dashboard");
  }

  function redirectToCampaigns() {
    history.push("/Campaigns");
  }

  function redirectToCandidates() {
    history.push("/Candidates");
  }

  function redirectToCommunicate() {
    history.push("/Communicate");
  }

  function redirectToAnalytics() {
    history.push("/Analytics");
  }

  function redirectToContact() {
    history.push("/Contact");
  }

  // // getting user details from local storage to use it in sidebar
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <div className="dashboard">
      <div style={currentSidebarStyle}>
        <div className="sidebar-title">{userDetails.account.name}</div>
        <div className="sidebar-upper-divider dvdr">
          <hr></hr>
        </div>
        <div className="sidebar-profile-container">
          <img
            className="profile-pic"
            src={userDetails.account.logo}
            alt="profile-pic"
          />
          {Toggle.toggleSlider && (
            <div className="profile-name">{userDetails.user.first_name}</div>
          )}
          {Toggle.toggleSlider && (
            <div className="profile-campaigns">{userDetails.user.email}</div>
          )}
        </div>
        <div className="sidebar-menu-dashboard menu">
          <img
            className="dashboard-logo menu-logo"
            src={dashboard_logo}
            onClick={redirectToDashboard}
            alt="dashboard_logo"
          />
          {Toggle.toggleSlider && (
            <div
              className="dashboard-text menu-text"
              onClick={redirectToDashboard}
              style={{ color: "#334d6e" }}
            >
              Dashboard
            </div>
          )}
        </div>
        <div className="sidebar-menu-Contact menu">
          <img
            className="Contact-logo menu-logo"
            src={Contact_logo}
            alt="Contact_logo"
            onClick={redirectToContact}
          />
          {Toggle.toggleSlider && (
            <div className="Contact-text menu-text" onClick={redirectToContact}>
              Contact
            </div>
          )}
        </div>
        <div className="sidebar-menu-campaigns menu">
          <img
            className="campaigns-logo-1 menu-logo"
            src={campaigns_1}
            alt="campaigns_logo"
            onClick={redirectToCampaigns}
          />
          <img
            className="campaigns-logo-2"
            src={campaigns_2}
            onClick={redirectToCampaigns}
          />
          {Toggle.toggleSlider && (
            <div
              className="campaigns-text menu-text"
              onClick={redirectToCampaigns}
            >
              Campaigns
            </div>
          )}
        </div>
        <div className="sidebar-menu-candidates menu">
          <img
            className="candidates-logo menu-logo"
            src={candidates_logo}
            alt="candidates_logo"
            onClick={redirectToCandidates}
          />
          {Toggle.toggleSlider && (
            <div
              className="candidates-text menu-text"
              onClick={redirectToCandidates}
            >
              Candidates
            </div>
          )}
        </div>
        <div className="sidebar-menu-communicate menu">
          <img
            className="communicate-logo menu-logo"
            src={communicate_logo}
            alt="communicate_logo"
            onClick={redirectToCommunicate}
          />
          {Toggle.toggleSlider && (
            <div
              className="communicate-text menu-text"
              onClick={redirectToCommunicate}
            >
              Communicate
            </div>
          )}
        </div>
        <div className="sidebar-menu-analytics menu">
          <img
            className="analytics-logo-1 menu-logo"
            src={analytics_1}
            alt="analytics_logo"
            onClick={redirectToAnalytics}
          />
          <img
            className="analytics-logo-2"
            src={analytics_2}
            onClick={redirectToAnalytics}
          />
          <img
            className="analytics-logo-3 menu-logo"
            src={analytics_3}
            onClick={redirectToAnalytics}
          />
          {Toggle.toggleSlider && (
            <div
              className="analytics-text menu-text"
              onClick={redirectToAnalytics}
            >
              Analytics
            </div>
          )}
        </div>
        <div className="sidebar-lower-divider dvdr">
          <hr></hr>
        </div>
        <div className="sidebar-menu-settings menu">
          <img
            className="settings-logo menu-logo"
            src={settings_logo}
            alt="settings_logo"
          />
          {Toggle.toggleSlider && (
            <div className="settings-text menu-text active-text">Settings</div>
          )}
        </div>
        <div className="sidebar-logout-btn menu">
          <img
            src={logout_btn}
            alt="logout-btn"
            className="menu-logo logout-btn-img"
            onClick={handleClick}
          />
          {Toggle.toggleSlider && (
            <button className="logout-btn menu" onClick={handleClick}>
              Logout
            </button>
          )}
        </div>
        <div className="sidebar-menu-toggle-slider menu">
          {Toggle.toggleSlider ? (
            <img
              className="toggle-slider-logo menu-logo"
              onClick={handleToggle}
              src={toggle}
            />
          ) : (
            <img
              className="toggle-slider-logo menu-logo"
              onClick={handleToggle}
              src={toggle_colored}
            />
          )}
        </div>
      </div>
      <div className="search-container" style={searchContainerStyle}>
        <input
          className="search-box"
          type="text"
          placeholder=" Global Search"
        ></input>
        <img className="seach-icon" src={search_icon} />
        <img className="bell-icon" src={bell_icon} />
        <img className="dot-icon" src={dot_icon} />
        <div className="data-container">
          <div>
            <Stack direction="row" spacing={2}>
              {/* ////// */}
              <Paper
                sx={{
                  padding: "30px",
                  width: "450px",
                }}
                elevation={0}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <Box sx={{ my: 3, mx: 2 }}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Stack direction="row" spacing={2}>
                          {/* <Avatar sx={{ bgcolor: deepOrange[500] }}>L</Avatar> */}
                          <Typography gutterBottom variant="h4" component="div">
                            Lakshya
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Chip label="Active" />
                      </Grid>
                    </Grid>
                    <Typography color="text.secondary" variant="body2">
                      lakshya@tbplabs.tech
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box sx={{ my: 3, mx: 2 }}></Box>
                  <Box sx={{ m: 2 }}>
                    <Typography gutterBottom variant="body1">
                      Skills
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <Chip label="Product Analyst" />
                      <Chip color="primary" label="Product Manager " />
                      <Chip label="Data Analyst" />
                      {/* <Chip label="SDE Frontend Engineer" /> */}
                    </Stack>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <nav>
                      <List>
                        <ListItemButton onClick={handleClickList}>
                          <ListItemIcon>
                            <LocalPhoneIcon />
                          </ListItemIcon>
                          <ListItemText primary="Primary Phone number" />
                          {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText primary="+91 9876288394" />
                            </ListItemButton>
                          </List>
                        </Collapse>
                        <ListItemButton onClick={handleClickListb}>
                          <ListItemIcon>
                            <LocalPhoneOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="Secondary Phone number" />
                          {openb ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openb} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText primary="+91 7578532789" />
                            </ListItemButton>
                          </List>
                        </Collapse>
                      </List>
                    </nav>
                    <Divider />
                    <nav aria-label="secondary mailbox folders">
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemText
                              primary="Candidate ID"
                              secondary="Test 1313"
                            />
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding>
                          <ListItemButton component="a">
                            <ListItemText primary="Score" secondary="123" />
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                      </List>
                    </nav>
                  </Box>
                  <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                    <Button>Back</Button>
                  </Box>
                </Box>
              </Paper>
              {/* ////// */}
              {profileData && customData && (
                <Box>
                  {customData.data[0].id ===
                    profileData.Content.CustomDetails[0].CustomField_id && (
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton component="a">
                          <ListItemText
                            primary={customData.data[0].Name}
                            secondary={
                              profileData.Content.CustomDetails[0].Value
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  )}
                </Box>
              )}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CandidateByID;

// http://127.0.0.1:8000/candidates/56
