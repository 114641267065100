import React, { useEffect, useState } from "react";
import dashboard_logo from "./Images/dashboard.png";
import logout_btn from "./Images/logout-btn.png";
import Contact_logo from "./Images/jobs-colored.png";
import campaigns_1 from "./Images/campaigns-1.png";
import campaigns_2 from "./Images/campaigns-2.png";
import candidates_logo from "./Images/candidates.png";
import communicate_logo from "./Images/communicate.png";
import analytics_1 from "./Images/analytics-1.png";
import analytics_2 from "./Images/analytics-left.png";
import analytics_3 from "./Images/analytics-right.png";
import settings_logo from "./Images/settings.png";
import toggle from "./Images/toggle.png";
import search_icon from "./Images/search-icon.png";
import bell_icon from "./Images/notification-icon.png";
import toggle_colored from "./Images/toggle-colored.png";
import dot_icon from "./Images/Ellipse.png";
import AuthApi from "../AuthApi";
import { useHistory } from "react-router-dom";
import ContactListing from "./ContactListing/ContactListing";
import Sidebar from "../utils/components/Sidebar";

function Contact() {
  const Toggle = React.useContext(AuthApi);
  const history = useHistory();
  //showing sidebar as per toggle
  const [currentSidebarStyle, setCurrentSidebarStyle] = useState(
    Toggle.toggleSlider
      ? {
          position: "fixed",
          width: "235px",
          maxWidth: "100%",
          height: "100%",
          background: "#ffffff",
          boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
          transition: "0.1s",
        }
      : {
          position: "fixed",
          width: "75px",
          maxWidth: "100%",
          height: "100%",
          background: "#ffffff",
          boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
          transition: "0.1s",
        }
  );
  // showing search container as per toggle
  const [searchContainerStyle, setSeachContainerStyle] = useState(
    Toggle.toggleSlider
      ? {
          marginLeft: "235px",
          display: "flex",
          flexDirection: "column",
        }
      : {
          marginLeft: "75px",
          display: "flex",
          flexDirection: "column",
        }
  );

  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // // getting user details from local storage to use it in sidebar
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  // handeling toggle changes
  function handleToggle() {
    Toggle.setToggleSlider(!Toggle.toggleSlider);
    if (Toggle.toggleSlider) {
      setCurrentSidebarStyle((prevStyle) => ({
        ...prevStyle,
        width: "75px",
        transition: "0.3s",
      }));
      setSeachContainerStyle((prevStyle) => ({
        ...prevStyle,
        marginLeft: "75px",
      }));
    } else {
      setCurrentSidebarStyle((prevStyle) => ({
        ...prevStyle,
        width: "235px",
        transition: "-0.5s",
      }));
      setSeachContainerStyle((prevStyle) => ({
        ...prevStyle,
        marginLeft: "235px",
      }));
    }
  }

  const [userOnline, setUserOnline] = useState("");
  const Auth = React.useContext(AuthApi);
  var raw = "";
  let myHeaders = new Headers();
  const name = "auth_token";
  let authtoken = getCookie(name);
  authtoken = "Token " + authtoken;
  myHeaders.append("Authorization", authtoken);

  // handeling click on logout button
  function handleClick() {
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    function eraseCookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    function handleResult() {
      localStorage.removeItem("userDetails");
      eraseCookie(name);
      Auth.setAuth(false);
    }

    // calling logout api
    fetch("https://preprod-api.tbplabs.tech/user/logout", requestOptions)
      .then((response) => response.text())
      .then((result) => handleResult())
      .catch((error) => console.log("error", error));
  }

  return (
    <div className="dashboard">
      <Sidebar active="Contact" />
      <div className="search-container" style={searchContainerStyle}>
        <input
          className="search-box"
          type="text"
          placeholder=" Global Search"
        ></input>
        <img className="seach-icon" src={search_icon} />
        <img className="bell-icon" src={bell_icon} />
        <img className="dot-icon" src={dot_icon} />
        <div className="data-container">
          <ContactListing />
        </div>
      </div>
    </div>
  );
}

export default Contact;
