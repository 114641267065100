import React, { useState } from "react";
import Cookies from "js-cookie";
import Download from "@mui/icons-material/Download";
import axios from "axios";

function Modal({ showModal, setShowModal }) {
  const [url, setUrl] = useState("");
  const [file, setFile] = useState();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "application/vnd.ms-excel" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        selectedFile.type === "text/csv")
    ) {
      const reader = new FileReader();
      reader.onload = () => {
        setIsFileUploaded(true);
      };
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    } else {
      setFile(null);
      setIsFileUploaded(false);
      alert("Please select an Excel or CSV file.");
    }
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    // console.log("submitted");
    setIsLoading(true);
    const token = Cookies.get("auth_token");
    var formData = new FormData();
    formData.append("file", file);
    try {
      const response = await fetch(
        "https://preprod-api.tbplabs.tech/contacts/uploadContacts",
        {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
          },
          body: formData,
        }
      );
      if (!response.ok || response.status < 200 || response.status >= 300) {
        throw new Error("Something went wrong with the API call.");
      }
      const res = await response.json();
      setIsLoading(false);
      // console.log(res);
    } catch (error) {
      setIsLoading(false);
    }
  };

  async function downloadSamplefile() {
    const url = "https://preprod-api.tbplabs.tech/contacts/template";
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    const resp = await axios.get(url, config);
  }

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-50 w-full h-full flex justify-center items-center bg-opacity-50 bg-gray-600 backdrop-blur-sm">
        <div className="relative w-full h-full max-w-2xl md:h-auto">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* Modal header */}
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Upload Contact
              </h3>
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="defaultModal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            {/* Modal body */}

            <div className="p-6 space-y-6">
              <form onSubmit={handleUpload}>
                <div className="flex items-center justify-center w-full">
                  <label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      {isFileUploaded ? (
                        <p className="mb-2 text-sm text-green-500 font-semibold">
                          File uploaded successfully!
                        </p>
                      ) : (
                        <>
                          <svg
                            aria-hidden="true"
                            className="w-10 h-10 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            />
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            CSV or EXCEL
                          </p>
                        </>
                      )}
                    </div>
                    <input
                      accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      onChange={handleFileUpload}
                    />
                  </label>
                </div>
                <button
                  style={{display:"block",margin:"auto",marginTop:"20px"}}
                  type="submit"
                  className="text-white mt-2 bg-sky-600 hover:bg-sky-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 add-user"
                >
                  Submit
                </button>
              </form>
            </div>
            {/* Modal footer */}
            <div className="flex items-center justify-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                onClick={() => downloadSamplefile()}
                data-modal-hide="defaultModal"
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                <Download />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
