import React, { useState, useEffect } from "react";
import Sidebar from "../utils/components/Sidebar";
import { data } from "./data";
import Structure from "./Structure";

// const TreeNode = ({ node }) => {
//   if (!node) {
//     return null;
//   }

//   const { id, name, child_ID } = node;

//   return (
//     <div>
//       <span>{name}</span>
//       {child_ID.length > 0 && (
//         <div>
//           {child_ID.map((childId) => (
//             <TreeNode key={childId} node={data[childId]} />
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

const Workflow = () => {
//   const [rootNode, setRootNode] = useState(null);

//   useEffect(() => {
//     const createTree = (nodeId) => {
//       const node = data[nodeId];
//       if (!node) {
//         return null;
//       }

//       const childNodes = node.child_ID.map((childId) => createTree(childId));
//       return {
//         ...node,
//         child_ID: childNodes,
//       };
//     };

//     const rootNode = createTree(1);
//     setRootNode(rootNode);
//   }, []);

  return (
   <>
     <Sidebar />
    <div className="structure-container">
      
      <Structure />
    </div>
   </>
  );
};

export default Workflow;
