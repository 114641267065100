import React, { useEffect, useState } from 'react'
import dashboard_logo from "./Images/dashboard.png"
import logout_btn from "./Images/logout-btn.png"
import Contact_logo from "./Images/jobs.png"
import campaigns_1 from "./Images/campaigns-1.png"
import campaigns_2 from "./Images/campaigns-2.png"
import candidates_logo from "./Images/candidates-colored.png"
import communicate_logo from "./Images/communicate.png"
import analytics_1 from "./Images/analytics-1.png"
import analytics_2 from "./Images/analytics-left.png"
import analytics_3 from "./Images/analytics-right.png"
import settings_logo from "./Images/settings.png"
import toggle from "./Images/toggle.png"
import search_icon from "./Images/search-icon.png"
import bell_icon from "./Images/notification-icon.png"
import toggle_colored from "./Images/toggle-colored.png"
import dot_icon from "./Images/Ellipse.png"
import AuthApi from '../AuthApi'
import { useHistory } from 'react-router-dom'
import "./AddCandidate/AddCandidate.css"
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import Dashboard from "./Dashboard/Dashboard"
import Sidebar from '../utils/components/Sidebar'


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
 
  },
  "& .MuiInputBase-input": {
    marginTop: "5px",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    // width: "500px",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function Candidates() {
 
  
  const [custom, setCustom] = useState() // custom fileds go here
  const Toggle = React.useContext(AuthApi)
  const history = useHistory();
  //validation
  const [isValid, setIsValid] = useState(true);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isValidNumber, setIsValidNumber] = useState(true);
  //showing sidebar as per toggle
  const [currentSidebarStyle, setCurrentSidebarStyle] = useState((Toggle.toggleSlider) ? {
    position: "fixed",
    width: "235px",
    maxWidth: "100%",
    height: "100%",
    background: "#ffffff",
    boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
    transition: "0.1s",
  }
: {
    position: "fixed",
    width: "75px",
    maxWidth: "100%",
    height: "100%",
    background: "#ffffff",
    boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
    transition: "0.1s",
  })
  // showing search container as per toggle 
  const [searchContainerStyle, setSeachContainerStyle] = useState((Toggle.toggleSlider) ? {
    marginLeft: '235px',
    display: 'flex',
    flexDirection: 'column',
  } : {
    marginLeft: '75px',
    display: 'flex',
    flexDirection: 'column',
  })
  // handle changes in add candidate form
  const [addCandidate, setAddCandidate] = useState({
    email: "",
    first_name: "",
    last_name: "",
    candidateId: "",
    primaryPhone: "",
    secondaryPhone: "",
    customFields: [],
  });

  

  function handleChange(event) {
    setAddCandidate(e => ({
      ...e,
      [event.target.name] : event.target.value
    }))
  }

  function handlePhoneChange(event) {
    if(event.target.value <= 9999999999){
    setAddCandidate(e => ({
      ...e,
      [event.target.name] : event.target.value
    }))
    }
  }

  function handleCheckChange(event) {
      console.log(event.target.getAttribute("label"));
      const userInput = {
        [event.target.name]: {
          [event.target.id]: event.target.getAttribute("label"),
        },
      };
      addCandidate.customFields.push(userInput);
      setAddCandidate({ ...addCandidate });
    }


  function handleRadioChange(event) {
    const userInput = {
      [event.target.name]: {
        [event.target.id]: event.target.getAttribute("label"),
      },
    };
   addCandidate.customFields.push(userInput);
   setAddCandidate({ ...addCandidate });
  }

  function handleCustomNumberChange(event) {
    if (event.target.value.toString().length > 1) {
      const userInput = {
        [event.target.id]: event.target.value,
      };
      addCandidate.customFields.push(userInput);
      setAddCandidate({ ...addCandidate });
    }
  }

  // handeling toggle changes
  function handleToggle(){
    Toggle.setToggleSlider(!Toggle.toggleSlider)
    if(Toggle.toggleSlider ){
      setCurrentSidebarStyle(prevStyle => ({
        ...prevStyle,
        width: '75px',
        transition: '0.3s'
      })) 
        setSeachContainerStyle(prevStyle => ({
          ...prevStyle,
          marginLeft: '75px'
        })) 
    }
    else {
      setCurrentSidebarStyle(prevStyle => ({
        ...prevStyle,
        width: '235px',
        transition: '-0.5s'
      }))
      setSeachContainerStyle(prevStyle => ({
        ...prevStyle,
        marginLeft: '235px'
      }))
    }
  }

   // getting cookie
   function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }
  
  const Auth = React.useContext(AuthApi)
  var raw = "";
  let myHeaders = new Headers();
  const name='auth_token'
  let authtoken=getCookie(name)
  authtoken="Token "+authtoken
  myHeaders.append("Authorization", authtoken);

// handeling click on logout button
  function handleClick(){
    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  function handleResult(){
    localStorage.removeItem('userDetails')
    eraseCookie(name)
    Auth.setAuth(false)
  }

  // calling logout api
  fetch("http://register.aviate.Contact/user/logout", requestOptions)
    .then(response => response.text())
    .then(result => handleResult())
    .catch(error => console.log('error', error));
    }

    //recieving custom fields
function customfields(){
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authtoken);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
};

async function handleResult(result){
  let res = await JSON.parse(result);
  let resData = await res.data;
  setCustom(resData)
}

    fetch("https://preprod-api.tbplabs.tech/candidates/CustomFields", requestOptions)
      .then(response => response.text())
      .then(result => handleResult(result))
      .catch(error => console.log('error', error));
}
  
  useEffect(() => {
    customfields();
  },[])

    // handle candidate form submit
  function handleSubmit(event) {
    event.preventDefault();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Token 65ac4b8444bd94330dcd1186e2a443c9f15b3f50");
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    Name: addCandidate.first_name + " " + addCandidate.last_name,
    Email: addCandidate.email,
    candidateid: addCandidate.candidateId,
    PrimaryPhone: addCandidate.primaryPhone,
    SecondaryPhone: addCandidate.secondaryPhone,
    CustomFields: addCandidate.customFields.map((e) => {
      return e
    }),
  });


  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch("https://preprod-api.tbplabs.tech/candidates/", requestOptions)
    .then(response => response.text())
    .then(result => {
      redirectToCandidates();
    })
    .catch((error) => {
      alert("error", error);
    });
  }


  function redirectToCandidates() {
     window.scrollTo(0, 0);
    history.push("/Candidates");
  }

    // // getting user details from local storage to use it in sidebar
  let userDetails = JSON.parse(localStorage.getItem('userDetails'))


  function checkEmail() {
    const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w -]{2,4}$/i;
    if (!regexEmail.test(addCandidate.email)) {
      setIsValid(false);
    }
    else if (regexEmail.test(addCandidate.email)) {
      setIsValid(true);
    }
  }

  function checkPhone() {
    const regexPhoneNumber = /^([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}$/i;
    if (!regexPhoneNumber.test(addCandidate.primaryPhone)) {
      setIsValidPhoneNumber(false);
    }
    if (regexPhoneNumber.test(addCandidate.primaryPhone)) {
      setIsValidPhoneNumber(true);
    }
  }

  function checkNumber() {
    const regexNumber = /^[0-9]\d*$/i;
    if (!regexNumber.test(addCandidate["MainCustomer Field 2"])) {
      setIsValidNumber(false);
    }
     if (regexNumber.test(addCandidate["MainCustomer Field 2"])) {
       setIsValidNumber(true);
     }
  }

  return (
    <div className="dashboard">
      <div className="search-container" style={searchContainerStyle}>
        <Sidebar active='Candidates'/>
        <input
          className="search-box"
          type="text"
          placeholder=" Global Search"
        ></input>
        <img className="seach-icon" src={search_icon} />
        <img className="bell-icon" src={bell_icon} />
        <img className="dot-icon" src={dot_icon} />
        <div className="data-container">
          <div className="container">
            <div></div>
            <FormControl variant="standard">
              <InputLabel
                sx={{ fontSize: 25, color: "#414D60" }}
                varient=""
                shrink
                htmlFor="bootstrap-input"
              >
                Email Address
              </InputLabel>
              <BootstrapInput
                defaultValue=""
                sx={{ width: "830px" }}
                id="bootstrap-input"
                type="email"
                placeholder="username@domain.com"
                name="email"
                value={addCandidate.email}
                onChange={handleChange}
                onBlur={checkEmail}
              />
            </FormControl>
            {!isValid && <p className="error">Entered email is invalid</p>}
          </div>
          <div></div>
          <div className="container">
            <div></div>
            <div className="name-field">
              <FormControl variant="standard">
                <InputLabel
                  sx={{ fontSize: 25, color: "#414D60" }}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  First name
                </InputLabel>
                <BootstrapInput
                  sx={{ marginRight: "30px", width: "400px" }}
                  defaultValue=""
                  id="bootstrap-input"
                  type="name"
                  placeholder="First Name"
                  name="first_name"
                  value={addCandidate.first_name}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl variant="standard">
                <InputLabel
                  sx={{ fontSize: 25, color: "#414D60" }}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Last name
                </InputLabel>
                <BootstrapInput
                  defaultValue=""
                  sx={{ width: "400px" }}
                  id="bootstrap-input"
                  type="name"
                  placeholder="Last Name"
                  name="last_name"
                  value={addCandidate.last_name}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div></div>
          </div>
          <div className="container">
            <div></div>
            <FormControl variant="standard">
              <InputLabel
                sx={{ fontSize: 25, color: "#414D60" }}
                shrink
                htmlFor="bootstrap-input"
              >
                Candidate ID
              </InputLabel>
              <BootstrapInput
                defaultValue=""
                id="bootstrap-input"
                sx={{ width: "400px" }}
                type="text"
                placeholder="Candidate ID"
                name="candidateId"
                value={addCandidate.candidateId}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div></div>
          <div className="phone-number">
            <div className="container">
              <div></div>
              <FormControl variant="standard">
                <InputLabel
                  sx={{ fontSize: 25, color: "#414D60" }}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Primary Phone
                </InputLabel>
                <Box sx={{ mt: 3 }}>
                  {!isValidPhoneNumber && (
                    <Input
                      error
                      sx={{ mr: 3, width: "400px" }}
                      startAdornment={
                        <InputAdornment position="start">+91</InputAdornment>
                      }
                      defaultValue=""
                      id="bootstrap-input"
                      type="tel"
                      placeholder="Primary Phone"
                      name="primaryPhone"
                      value={addCandidate.primaryPhone}
                      onChange={handlePhoneChange}
                      onBlur={checkPhone}
                    />
                  )}
                  {isValidPhoneNumber && (
                    <Input
                      sx={{ mr: 3, width: "400px" }}
                      startAdornment={
                        <InputAdornment position="start">+91</InputAdornment>
                      }
                      defaultValue=""
                      id="bootstrap-input"
                      type="tel"
                      placeholder="Primary Phone"
                      name="primaryPhone"
                      value={addCandidate.primaryPhone}
                      onChange={handlePhoneChange}
                      onBlur={checkPhone}
                    />
                  )}
                </Box>
              </FormControl>
              {!isValidPhoneNumber && (
                <p className="error">Entered Phone number is invalid</p>
              )}
            </div>
            <div></div>
            <div className="container">
              <FormControl variant="standard">
                <InputLabel
                  sx={{ fontSize: 25, color: "#414D60" }}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Secondary Phone
                </InputLabel>
                <Box sx={{ mt: 3 }}>
                  {!isValidPhoneNumber && (
                    <Input
                      error
                      sx={{ mr: 3, width: "400px" }}
                      startAdornment={
                        <InputAdornment position="start">+91</InputAdornment>
                      }
                      defaultValue=""
                      id="bootstrap-input"
                      type="tel"
                      placeholder="Secondary Phone"
                      name="secondaryPhone"
                      value={addCandidate.secondaryPhone}
                      onChange={handlePhoneChange}
                      onBlur={checkPhone}
                    />
                  )}
                  {isValidPhoneNumber && (
                    <Input
                      sx={{ mr: 3, width: "400px" }}
                      startAdornment={
                        <InputAdornment position="start">+91</InputAdornment>
                      }
                      defaultValue=""
                      id="bootstrap-input"
                      type="tel"
                      placeholder="Secondary Phone"
                      name="secondaryPhone"
                      value={addCandidate.secondaryPhone}
                      onChange={handlePhoneChange}
                      onBlur={checkPhone}
                    />
                  )}
                </Box>
              </FormControl>
              {!isValidPhoneNumber && (
                <p className="error">Entered Phone number is invalid</p>
              )}
              <div></div>
            </div>
          </div>
          <div className="custom-fields">
            {custom &&
              custom.map((e, id) => {
                return (
                  <div key={id}>
                    {/* <label text="secondary phone">{e.Label}</label> */}
                    <div></div>
                    {e.Type === "int" && (
                      <div>
                        {/* <input
                          className="add-candidate-secondary-phone inp-phone"
                          name={e.Name}
                          onChange={handleCustomNumberChange}
                          // onBlur={checkNumber}
                          id={e.id}
                          maxLength={2}
                        /> */}
                        <FormControl
                          sx={{ mt: 2 }}
                          variant="standard"
                          inputProps={{ maxLength: 2 }}
                        >
                          <InputLabel
                            sx={{ fontSize: 25, color: "#414D60" }}
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            {e.Label}
                          </InputLabel>
                          <BootstrapInput
                            defaultValue=""
                            sx={{ width: "400px" }}
                            name={e.Name}
                            onChange={handleCustomNumberChange}
                            // onBlur={checkNumber}
                            id={e.id}
                            inputProps={{ maxLength: 2 }}
                          />
                        </FormControl>
                        {/* {!isValidNumber && (
                          <p className="error">Entered number is invalid</p>
                        )} */}
                      </div>
                    )}
                    {e.Type === "checklist" &&
                      e.checklist.map((f, id) => {
                        return (
                          <div key={id} className="custom-skill">
                            <input
                              type="checkbox"
                              className="add-candidate-secondary-phone check"
                              name={f.CustomField_id}
                              id={f.id}
                              label={f.Label}
                              // customid={f.CustomField_id}
                              onChange={handleCheckChange}
                            />
                            <label
                              className="check-label"
                              text="secondary phone"
                            >
                              {f.Label}
                            </label>
                            {/* <FormControlLabel
                              sx={{ color: "#414D60" }}
                              control={
                                <Checkbox
                                  type="checkbox"
                                  name={f.CustomField_id}
                                  id={f.id}
                                  label={f.Label}
                                  // customid={f.CustomField_id}
                                  onChange={handleCheckChange}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 40 },
                                  }}
                                />
                              }
                              label={f.Label}
                            /> */}
                          </div>
                        );
                      })}
                    <div className="radio">
                      {e.Type === "radio" &&
                        e.checklist.map((f, id) => {
                          return (
                            <div key={id} className="custom-skill radio">
                              <input
                                type="radio"
                                className="add-candidate-secondary-phone check"
                                name={f.CustomField_id}
                                label={f.Label}
                                id={f.id}
                                onChange={handleRadioChange}
                              />
                              <label
                                className="check-label"
                                text="secondary phone"
                              >
                                {f.Label}
                              </label>
                              {/* <FormControl>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  // value={value}
                                  onChange={handleRadioChange}
                                >
                                  <FormControlLabel
                                    sx={{ color: "#334D6E" }}
                                    value=""
                                    control={
                                      <Radio
                                        type="radio"
                                        name={f.CustomField_id}
                                        label={f.Label}
                                        id={f.id}
                                        // onChange={handleRadioChange}
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 40,
                                          },
                                        }}
                                      />
                                    }
                                    label={f.Label}
                                  />
                                </RadioGroup>
                              </FormControl> */}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
          <Button
            sx={{ marginTop: "15px" }}
            onClick={handleSubmit}
            variant="contained"
            size="large"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Candidates