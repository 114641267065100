import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";


function TagFilterInput({tag, selectedTags, setSelectedTags}) {

    const handleTagChange = (event, value) => {
     setSelectedTags(value);
    };

    const placeholder = selectedTags.length > 0 ? "" : "Choose tags";

  return (
    <Autocomplete
    className="mt-4 px-2"
      multiple
      options={tag}
      getOptionLabel={(option) => option.name}
      value={selectedTags}
      onChange={handleTagChange}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ width: "100%" }}
          variant="outlined"
          label="Select Tags"
          placeholder={placeholder}
        />
      )}
    />
  );
}

export default TagFilterInput