import React, { useEffect, useState } from "react";
import AuthApi from "../../AuthApi";
import { useHistory } from "react-router-dom";
import Modal from "./Modal";
import Cookies from "js-cookie";
import Sidebar from "../../utils/components/Sidebar";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { ArrowDropDown } from "@mui/icons-material";


function AddCampaign() {
  const Toggle = React.useContext(AuthApi);
  const history = useHistory();
  const [campaignName, setCampaignName] = useState("");
  const [file, setFile] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [appAccountId, setAppAccountId] = useState(null);
  const [currentUserList, setCurrentUserList] = useState(null);
  const [defaultUser, SetDefaultUser] = useState("");
  const [isUserDropDownOpen, setIsUserDropDownOpen] = useState(false);

  const handleOptionChange = (newValue) => {
    if (newValue) {
      setSelectedTemplate(newValue);
      console.log(newValue.id);
    }
  };

  const [templates, setTemplates] = useState([]);

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "application/vnd.ms-excel" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        selectedFile.type === "text/csv")
    ) {
      const reader = new FileReader();
      reader.onload = () => {
        setIsFileUploaded(true);
      };
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    } else {
      setFile(null);
      setIsFileUploaded(false);
      alert("Please select an Excel or CSV file.");
    }
  };
  //showing sidebar as per toggle
  const [currentSidebarStyle, setCurrentSidebarStyle] = useState(
    Toggle.toggleSlider
      ? {
          position: "fixed",
          width: "235px",
          maxWidth: "100%",
          height: "100%",
          background: "#ffffff",
          boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
          transition: "0.1s",
        }
      : {
          position: "fixed",
          width: "75px",
          maxWidth: "100%",
          height: "100%",
          background: "#ffffff",
          boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
          transition: "0.1s",
        }
  );
  // showing search container as per toggle
  const [searchContainerStyle, setSeachContainerStyle] = useState(
    Toggle.toggleSlider
      ? {
          marginLeft: "235px",
          display: "flex",
          flexDirection: "column",
        }
      : {
          marginLeft: "75px",
          display: "flex",
          flexDirection: "column",
        }
  );

  // handeling toggle changes
  function handleToggle() {
    Toggle.setToggleSlider(!Toggle.toggleSlider);
    if (Toggle.toggleSlider) {
      setCurrentSidebarStyle((prevStyle) => ({
        ...prevStyle,
        width: "75px",
        transition: "0.3s",
      }));
      setSeachContainerStyle((prevStyle) => ({
        ...prevStyle,
        marginLeft: "75px",
      }));
    } else {
      setCurrentSidebarStyle((prevStyle) => ({
        ...prevStyle,
        width: "235px",
        transition: "-0.5s",
      }));
      setSeachContainerStyle((prevStyle) => ({
        ...prevStyle,
        marginLeft: "235px",
      }));
    }
  }

  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const [userOnline, setUserOnline] = useState("");
  const Auth = React.useContext(AuthApi);
  var raw = "";
  let myHeaders = new Headers();
  const name = "auth_token";
  let authtoken = getCookie(name);
  authtoken = "Token " + authtoken;
  //console.log(authtoken)
  myHeaders.append("Authorization", authtoken);
  //console.log(myHeaders)

  // handeling click on logout button
  function handleClick() {
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    function eraseCookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    function handleResult() {
      localStorage.removeItem("userDetails");
      eraseCookie(name);
      Auth.setAuth(false);
    }

    // calling logout api
    fetch("https://preprod-api.tbplabs.tech/user/logout", requestOptions)
      .then((response) => response.text())
      .then((result) => handleResult())
      .catch((error) => console.log("error", error));
  }

  // // getting user details from local storage to use it in sidebar
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let formData = new FormData();
    formData.append("campaignName", campaignName);
    formData.append("templateId", selectedTemplate.id);
    formData.append("file", file);
    formData.append("startTime", startDate);
    formData.append("endTime", endDate);
    try {
      const response = await fetch(
        "https://preprod-api.tbplabs.tech/whatsapp/campaigns",
        {
          method: "POST",
          headers: {
            Authorization: `Token ${Cookies.get("auth_token")}`,
          },
          body: formData,
        }
      );
      if (!response.ok || response.status < 200 || response.status >= 300) {
        throw new Error("Something went wrong with the API call.");
      }
      const data = await response.json();
      setIsLoading(false);
      history.push("/Campaigns");
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      alert("Something went wrong. Please try again.");
    }
  };

  const getTemplates = async () => {
    const response = await fetch(
      `https://preprod-api.tbplabs.tech/whatsapp/templates?appAccountId=${appAccountId}`,
      {
        headers: {
          Authorization: `Token ${Cookies.get("auth_token")}`,
        },
      }
    );
    const responseData = await response.json();
    let templatesList = [];

    responseData.data.map((template) => {
      templatesList.push({ name: template.name, id: template.id });
    });
    setTemplates(templatesList);
  };

   const getChatApps = async () => {
    const config = {
      headers: {
        Authorization: `Token ${Cookies.get("auth_token")}`,
      },
    };
    try {
      const currentUserListData = await axios.get(
        "https://preprod-api.tbplabs.tech/chatapp/getChatApps",
        config
      );
      setCurrentUserList(currentUserListData.data);
        setAppAccountId(currentUserListData.data[0].id);
        SetDefaultUser(currentUserListData.data[0].name);
        
    } catch (err) {
      SetDefaultUser(null);
    }
   }

  useEffect(() => {
    getChatApps();
    getTemplates();
  }, []);

  useEffect(() => {
    getTemplates();
  }, [appAccountId]);

  const downloadSampleTemplate = async (event) => {
    if (selectedTemplate === null || selectedTemplate === undefined) {
      alert("Please select a template");
      return;
    }
     try {
       const response = await fetch(
         `https://preprod-api.tbplabs.tech/whatsapp/templates/file?id=${selectedTemplate.id}&appAccountId=${appAccountId}`,
         {
           headers: {
             Authorization: `Token ${Cookies.get("auth_token")}`,
           },
         }
       );
       if (response.ok) {
        const url = URL.createObjectURL(await response.blob());
        const link = document.createElement("a");
        link.href = url;
        link.download = "template.xlsx";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.error("Something Went Wrong.");
      }

      //  if (!response.ok) {
      //    throw new Error("Request failed");
      //  }

       const json = await response.json();
       // Handle the JSON response here
       console.log(json);
     } catch (error) {
       // Handle the error here
       console.error(error);
     }
  };

  function clickUserHandler(name, id){
    SetDefaultUser(name);
    setAppAccountId(id);

  }

  const userDropDownOpenStyled={
    height: "auto",
    display: "block",
    backgroundColor:"white",
    transitionDuration: "0.5s",
    borderRadius:"5px",
    boxShadow:"2px 2px 5px 5px #F4F4F4",
    fontWeight: "bold",
    padding:"10px 0px",
    maxWidth:"10rem"
  }
  const userDropDownCloseStyled={
    height: 0,
    display:"none",
  }

  return (
    <div className="dashboard">
      <Sidebar active="Campaigns" />
      <div className="search-container" style={searchContainerStyle}>
        <input
          className="search-box"
          type="text"
          placeholder=" Global Search"
        ></input>
        {defaultUser && (
          <div className="user-account-dropdown" onClick={()=>setIsUserDropDownOpen(!isUserDropDownOpen)}>
            <span className="text-sm font-bold">{defaultUser}</span>
            <span>
              <ArrowDropDown />
            </span>
            <div style={isUserDropDownOpen?userDropDownOpenStyled:userDropDownCloseStyled} className="userDropdown">
              {currentUserList?.map((user)=>(
                <p className="px-2 text-sm py-1" onClick={()=>clickUserHandler(user.name, user.id)}>{user.name}</p>
              ))}
            </div>
          </div>
          
        )}
       
        <div className="data-container">
          <div className="flex justify-between">
            <h1 className="text-3xl m-4 font-semibold text-gray-900">
              Add Campaign
            </h1>
            <div className="m-4">
              <button
                onClick={() => setShowModal(!showModal)}
                className="bg-[#0086ff] hover:bg-[#0077ff] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Get URL
              </button>
              {showModal ? (
                <Modal showModal={showModal} setShowModal={setShowModal} />
              ) : null}
            </div>
          </div>

          <form onSubmit={handleSubmit} className="">
            <div className="rounded bg-white p-4 drop-shadow-lg flex shrink-0 gap-x-2">
              <div className="mb-4 w-full">
                <label
                  htmlFor="campaign-name"
                  className="block text-gray-900 font-bold mb-2"
                >
                  Campaign Name
                </label>
                <input
                  type="text"
                  id="campaign-name"
                  name="campaign-name"
                  value={campaignName}
                  onChange={(event) => setCampaignName(event.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4 w-full">
               
                <label
                  htmlFor="campaign-name"
                  className="block invisible text-gray-900 font-bold mb-2"
                >
                  Campaign Name
                </label>
                <Autocomplete
                  id="my-dropdown"
                  options={templates}
                  value={selectedTemplate}
                  onChange={(event, newValue) => handleOptionChange(newValue)}
                  getOptionLabel={(template) => template.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Template Name"
                      placeholder="search..."
                      variant="outlined"
                      className="w-full"
                      size="small"
                    />
                  )}
                />
              </div>
            </div>

            <div className="my-4 bg-white rounded p-4 drop-shadow-lg">
              <label
                htmlFor="file"
                className="block text-gray-900 font-bold mb-2"
              >
                File
              </label>
              <div className="w-full">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    {isFileUploaded ? (
                      <p className="mb-2 text-sm text-green-500 font-semibold">
                        File uploaded successfully!
                      </p>
                    ) : (
                      <>
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          CSV or EXCEL
                        </p>
                      </>
                    )}
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                    onChange={handleFileUpload}
                  />
                </label>
                <button
                  onClick={(event) => {
                    downloadSampleTemplate()}}
                  className="my-2 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Download sample template
                </button>
              </div>
            </div>
            <div className="bg-white rounded p-4 drop-shadow-lg my-4 flex shrink-0 gap-x-2">
              <div className="mb-4 w-full">
                <label
                  htmlFor="start-date"
                  className="block text-gray-900 font-bold mb-2"
                >
                  Start Date Time
                </label>
                <input
                  type="datetime-local"
                  id="start-date"
                  name="start-date"
                  value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4 w-full">
                <label
                  htmlFor="end-date"
                  className="block text-gray-900 font-bold mb-2"
                >
                  End Date Time
                </label>
                <input
                  type="datetime-local"
                  id="end-date"
                  name="end-date"
                  value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
            </div>

            <div className="flex justify-end">
              {!isLoading && (
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Submit
                </button>
              )}
              {isLoading && (
                <div className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                  Submitting...
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCampaign;
