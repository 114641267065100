import React from "react";
import Home from "./components/Home";
import Login from "./components/Login";
import ProtectedLogin from "./components/ProtectedLogin";
import ProtectedRoute from "./components/ProtectedRoute";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import AuthApi from "./components/AuthApi";
import Cookies from "js-cookie";
import Contact from "./components/Home-page-components/Contact";
import Campaigns from "./components/Home-page-components/Campaigns/Campaigns";
import Candidates from "./components/Home-page-components/Candidates";
import Communicate from "./components/Home-page-components/Communicate/Communicate";
import Analytics from "./components/Home-page-components/Analytics";
import AddForm from "./components/Home-page-components/AddForm";
import Listing from "./components/Home-page-components/Listing";
import Settings from "./components/Home-page-components/Settings";
import Dashboard from "./components/Home-page-components/Dashboard";
import AddCandidate from "./components/Home-page-components/AddCandidate";
import CandidateByID from "./components/Home-page-components/CandidateByID";
import Test from "./components/Home-page-components/Test";
import AddCampaign from "./components/Home-page-components/Campaigns/AddCampaign";
import Sidebar from "./components/utils/components/Sidebar";
import WorkFlow from "./components/WorkFlow/WorkFlow";
import App1  from "./components/WorkFlow/App";


function App() {
  const [auth, setAuth] = useState(false);
  const [toggleSlider, setToggleSlider] = useState(true);

  function readCookie() {
    const currentUser = Cookies.get("auth_token");
    if (currentUser) {
      setAuth(true);
    } else return;
  }

  useEffect(() => {
    readCookie();
  }, []);

  return (
    <AuthApi.Provider value={{ auth, setAuth, toggleSlider, setToggleSlider }}>
      <div className="App">
        <BrowserRouter>
          <Switch>
            <ProtectedLogin path="/Login" auth={auth} component={Login} />
            <ProtectedRoute
              path="/workflow"
              auth={auth}
              setAuth={setAuth}
              component={WorkFlow}
            />
            <ProtectedRoute
              path="/Home"
              auth={auth}
              setAuth={setAuth}
              component={Home}
            />
            <ProtectedRoute
              path="/wf"
              auth={auth}
              setAuth={setAuth}
              component={App1}
            />
            <ProtectedRoute
              path="/Dashboard"
              auth={auth}
              setAuth={setAuth}
              component={Dashboard}
            />
            <ProtectedRoute
              path="/Sidebar"
              auth={auth}
              setAuth={setAuth}
              component={Sidebar}
            />
            <ProtectedRoute
              path="/Contact"
              auth={auth}
              setAuth={setAuth}
              component={Contact}
            />
            <ProtectedRoute
              path="/Campaigns"
              auth={auth}
              setAuth={setAuth}
              component={Campaigns}
            />
            <ProtectedRoute
              path="/addcampaign"
              auth={auth}
              setAuth={setAuth}
              component={AddCampaign}
            />
            <ProtectedRoute
              path="/Candidates"
              auth={auth}
              setAuth={setAuth}
              component={Candidates}
            />
            <ProtectedRoute
              path="/Communicate"
              auth={auth}
              setAuth={setAuth}
              component={Communicate}
            />
            <ProtectedRoute
              path="/Analytics"
              auth={auth}
              setAuth={setAuth}
              component={Analytics}
            />
            <ProtectedRoute
              path="/AddForm"
              auth={auth}
              setAuth={setAuth}
              component={AddForm}
            />
            <ProtectedRoute
              path="/Listing/:id"
              auth={auth}
              setAuth={setAuth}
              component={Listing}
            />
            <ProtectedRoute
              path="/Settings"
              auth={auth}
              setAuth={setAuth}
              component={Settings}
            />
            <ProtectedRoute
              path="/AddCandidate"
              auth={auth}
              setAuth={setAuth}
              component={AddCandidate}
            />
            <ProtectedRoute
              path="/"
              auth={auth}
              setAuth={setAuth}
              component={Home}
            />
            <ProtectedRoute //Does not work
              path="/Foo"
              auth={auth}
              setAuth={setAuth}
              component={CandidateByID}
            />
          </Switch>
        </BrowserRouter>
      </div>
    </AuthApi.Provider>
  );
}

export default App;
