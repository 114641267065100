import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import 'cross-fetch/polyfill'
import AuthApi from './AuthApi'
import Dashboard from './Home-page-components/Dashboard'
import Login from './Login'

// getting cookie
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

  function Home() {
    const[userOnline, setUserOnline] = useState('')
    const Auth = React.useContext(AuthApi)
    const history = useHistory()
    var raw = "";
    let myHeaders = new Headers();
    const name='auth_token'
    let authtoken=getCookie(name)
    authtoken="Token "+authtoken
    //console.log(authtoken)
    myHeaders.append("Authorization", authtoken);
    //console.log(myHeaders)

  // handeling click on logout button
  function handleClick(){
    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  function handleResult(){
    eraseCookie(name)
    Auth.setAuth(false)
  }

  // calling logout api
  fetch("https://preprod-api.tbplabs.tech/user/logout", requestOptions)
    .then(response => response.text())
    .then(result => handleResult())
    .catch(error => console.log('error', error));
    }

    function redirectToDashboard(){
      let userDetails = (localStorage.getItem('userDetails'))
    if(userDetails){
      history.push('/Dashboard')
    }
  }

  useEffect(() => {
    let myHeaders = new Headers();
    const name='auth_token'
    let authtoken=getCookie(name)
    authtoken="Token "+authtoken
    //console.log(authtoken)
    myHeaders.append("Authorization", authtoken);
    //console.log(myHeaders)
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
  
  //Handeling data as per ME api calling
    function handleData(result) {
      // console.log('123')
      // if (result.details === 'Invalid token') {
      //   console.log("hello")
      //   history.push("/Login");
      // }
      if(result.detail != 'Invalid token.'){
        localStorage.setItem('userDetails', JSON.stringify(result.message))
        redirectToDashboard()
        //setUserOnline(JSON.stringify(result.message.account))
        //console.log(authtoken)
      } else 
      return;
    }
    
  //Calling ME api
    fetch("https://preprod-api.tbplabs.tech/user/me", requestOptions)
      .then(response => response.text())
      .then(result => handleData(JSON.parse(result)))
      .catch(error => (
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;',
        Auth.setAuth(false),
        history.push('/Login')

      ));
  },[])
  return (<>
    </>
  )
}

export default Home