import React, { useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { ChromePicker } from "react-color";
import Cookies from "js-cookie";


function _Modal({ open, handleClose }) {
  const [tagColor, setTagColor] = useState("#000000");
  const colorPickerRef = useRef(null);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const nameRef = useRef(null);
  const descriptionRef = useRef(null);



  const handleColorChange = (color) => {
    setTagColor(color.hex);
  };


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    px: 2,
    py: 3,
    borderRadius: 2,
  };
  const colorPickerStyle = {
    position: "absolute",
    top: "calc(100% - 6rem)",
    left: "calc(100% - 6rem)",
    width: "100%",
  };

  const handleAddTag = () => {
    const nameValue = nameRef.current.value;
    const descriptionValue = descriptionRef.current.value;

    const requestData = {
      name: nameValue,
      description: descriptionValue,
      color: tagColor,
      type: "test",
    };

    fetch("https://preprod-api.tbplabs.tech/tag/tag", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${Cookies.get("auth_token")}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Tag added successfully:", data);
        handleClose(); // Close the modal after successful API response
      })
      .catch((error) => {
        console.error("Error adding tag:", error);
      });
  };


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center justify-between w-full">
            <Typography id="modal-modal-description" variant="h6">
              Create tag
            </Typography>
            <div
              onClick={handleClose}
              className="cursor-pointer text-black font-bold"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <Divider sx={{ my: 2 }} />
          <div className="mt-6 flex flex-col justify-between items-center w-full gap-2">
            <div className="flex gap-2">
              <TextField
                label="Tag name"
                name="name"
                size="small"
                style={{ width: "100%" }}
                inputRef={nameRef}
              />
              <TextField
                label="Description"
                name="description"
                size="small"
                style={{ width: "100%" }}
                inputRef={descriptionRef}
              />
              <TextField
                label="Color"
                name="color"
                size="small"
                style={{
                  width: "100%",
                }}
                // onChange={handleInputChange}
                value={tagColor}
              />
              <div
                onClick={() => setColorPickerOpen(!colorPickerOpen)}
                style={{ backgroundColor: tagColor }}
                className={`w-28 h-10 rounded cursor-pointer`}
              >
                {/* Add content inside the div */}
                &nbsp;
              </div>
              {colorPickerOpen && (
                <div
                  ref={colorPickerRef}
                  className="absolute left-0 top-full mt-2"
                  style={colorPickerStyle}
                >
                  <ChromePicker
                    color={tagColor}
                    onChange={handleColorChange}
                    disableAlpha
                  />
                </div>
              )}
              {/* <ChromePicker
                color={tagColor}
                onChange={handleColorChange}
                disableAlpha
              /> */}
            </div>
          </div>
          <Divider sx={{ my: 2 }} />
          <div className="flex gap-2 ">
            <Button onClick={()=> handleAddTag()} variant="contained">Add</Button>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default _Modal;
