import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import "./ContactListing.css";
import Modal from "./Modal";
import { Add } from "@mui/icons-material";
import { Upload } from "@mui/icons-material";
import { Pagination } from "@mui/material";

const ContactListing = () => {
  const [contactList, setContactList] = useState(null);
  const [contentList, setContentList] = useState(null);
  const [paginationTotalPage, setPaginationTotalPage] = useState(null);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const auth_headers = {
    headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
  };

  async function getContactList() {
    const api = "https://preprod-api.tbplabs.tech/contacts/contactList";
    const api2 = `https://preprod-api.tbplabs.tech/contacts/contactList?OFFSET=${offset}&LIMIT=10`;
    const resp = await axios.get(api, auth_headers);
    setContactList(resp.data.data);
    const resp2 = await axios.post(api2, contactList, auth_headers);
    setContentList(resp2.data.data.content);
    setPaginationTotalPage(resp2.data.data.totalPages);
  }
  const handleChangePagination = (event, value) => {
    setOffset(value * 10);
    setPage(value);
  };

  useEffect(() => {
    getContactList();
  }, [offset]);

  useEffect(() => {
    // console.log(showModal);
  }, []);

  const handleLinkClick = (id) => {
    window.open(`/Listing/${id}`, "_blank");
  };
  const LinkClick = (id) => {
    window.open(`/AddForm`, "_blank");
  };

  return (
    <>
      <h1 className="account-setting">Contact</h1>
      <button className="addUser" onClick={() => LinkClick()}>
        <Add /> ADD CONTACT
      </button>
      <button
        style={{ marginRight: "10px" }}
        className="addUser"
        onClick={() => setShowModal(true)}
      >
        <Upload /> UPLOAD CONTACT
      </button>

      <table className="width-full">
        <tr>
          {contactList != null
            ? contactList.map((e, i) => (
                <th
                  style={{fontSize:'0.8rem !important'}}
                  className="py-2"
                  key={i}
                >
                  {e.label}
                </th>
              ))
            : null}
        </tr>
        {contentList != null
          ? contentList.map((e, i) => (
              <tr
                key={i}
                onClick={() => handleLinkClick(e.id)}
              >
                {contactList?.map((event) => (
                  <td className="whitespace-nowrap px-4 py-0 text-gray-700">
                    {e[event.key]}
                  </td>
                ))}
              </tr>
            ))
          : null}
      </table>
      <div className="pagination-btn">
        <Pagination
          onChange={handleChangePagination}
          page={page}
          color="primary"
          count={paginationTotalPage - 1}
          shape="rounded"
        />
      </div>

      {showModal ? (
        <Modal showModal={showModal} setShowModal={setShowModal} />
      ) : null}
    </>
  );
};

export default ContactListing;
