import { useState, useEffect } from "react";
import Cookies from "js-cookie";

function Modal({ setShowTagModal, profile, tag }) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const handleTagClick = (tag) => {
    // console.log(tag)
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleSaveTags = () => {
    // console.log(selectedTags); 
    const requestBody = {
      type: "contact",
      id: profile?.Data.object.id,
      tagIds: selectedTags, 
    };

    fetch("https://preprod-api.tbplabs.tech/user/tag", {
      method: "PATCH",
      headers: {
        Authorization: `Token ${Cookies.get("auth_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        // Handle the API response here
        // console.log(response.json());
      })
      .catch((error) => {
        // Handle errors here
        // console.log(error);
      });
    setShowTagModal(false);
  };

  const filteredTags = profile?.Data.object.tags
    .map((tagId) => tag.find((item) => item.id === tagId))
    .filter((taginfo) =>
      taginfo?.name.toLowerCase().includes(searchInput.toLowerCase())
    );

    useEffect(()=>{
      setSelectedTags(profile?.Data.object.tags)
    },[])

  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="flex justify-between items-center w-full">
                      <h3
                        className="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Add Tags
                      </h3>
                      <div
                        className="cursor-pointer"
                        onClick={() => setShowTagModal(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    </div>

                    <div className="border-t-2 my-2" />
                    <input
                      className="border w-full rounded my-2 p-2"
                      placeholder="search..."
                      onChange={(event) => setSearchInput(event.target.value)}
                    />
                    {/* Tags go here*/}
                    <div className="overflow-y-auto max-h-40 pr-2">
                      {profile &&
                        tag &&
                        tag
                          .filter((tag) =>
                            tag.name
                              .toLowerCase()
                              .includes(searchInput.toLowerCase())
                          )
                          .map((tag) => {
                            // let checked = profile?.Data.object.tags.includes(
                            //   tag.id
                            // );
                            let checked;
                            if(selectedTags){
                              if(selectedTags.includes(tag.id)){
                                checked = true
                              }
                            }
                            return (
                              <div
                                key={tag.id}
                                className="mt-2 w-full flex items-center justify-between"
                              >
                                <p className="text-base">{tag.name}</p>
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 focus:ring-2 focus:ring-blue-600 focus:outline-none"
                                  onChange={() => handleTagClick(tag.id)}
                                  checked={checked}
                                />
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={handleSaveTags}
                  className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                >
                  Save Tags
                </button>
                <button
                  type="button"
                  onClick={() => setShowTagModal(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
