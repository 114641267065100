import React, { useState, useEffect } from "react";
import "./PeopleSetting.css";
import AddUser from "./AddUser";
import axios from "axios";
import Cookies from "js-cookie";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";



const PeopleSetting = ({ permission }) => {
  const [adduserForm, setAdduserForm] = useState(true);
  const [userList, setUserList] = useState(null);
  const [roles, setRoles] = useState([]);
  const [apps, setApps] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState();
  const [selectedApps, setSelectedApps] = useState();
  

  const [edit, setEdit] = useState("notedit");
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetch("https://preprod-api.tbplabs.tech/user/roles", {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("auth_token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRoles(data.id);
      })
      .catch((error) => console.error(error));
  }, [edit]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://preprod-api.tbplabs.tech/chatapp/getChatApps",
          {
            headers: {
              Authorization: `Token ${Cookies.get("auth_token")}`,
            },
          }
        );
        const jsonData = await response.json();
        setApps(jsonData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [edit]);

  const adduserValue =
    edit === "notedit"
      ? {
          email: "",
          username: "",
          password: "",
          first_name: "",
          last_name: "",
          crmUserId: "",
          department: "",
          usertype: "",
          remark: "",
        }
      : {
          email: "",
          username: "",
          password: "",
          first_name: "",
          last_name: "",
          crmUserId: "",
          department: "",
          usertype: "",
          remark: "",
          id: "",
        };

  const [userAddToForm, setUserAddToForm] = useState(adduserValue);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserAddToForm({
      ...userAddToForm,
      [name]: value,
    });
  };

  function openAddUserForm(e) {
    try {
      userAddToForm.first_name = e.user__first_name;
      userAddToForm.email = e.user__email;
      userAddToForm.remark = e.remark;
      // userAddToForm.password=e.password;
      userAddToForm.last_name = e.user__last_name;
      userAddToForm.department = e.department;
      userAddToForm.username = e.user__username;
      userAddToForm.crmUserId = e.crmUserId;
      userAddToForm.usertype = e.usertype;
      userAddToForm.id = e.user__id;
      setEdit("notedit");
    } catch (err) {
      setEdit("edit");
    }
    if (adduserForm === false) {
      userAddToForm.first_name = "";
      userAddToForm.email = "";
      userAddToForm.remark = "";
      userAddToForm.last_name = "";
      userAddToForm.department = "";
      userAddToForm.username = "";
      userAddToForm.crmUserId = "";
      userAddToForm.usertype = "";
    }
    setAdduserForm(!adduserForm);
    setSelectedApps()
    setSelectedRoles()
  }

  async function getUserList() {
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    let resp = await axios.get(
      "https://preprod-api.tbplabs.tech/user/userlist",
      config
    );
    setUserList(resp.data.data);
  }

  useEffect(() => {
    getUserList();
  }, []);

  async function submitAddUserForm() {
    if (!selectedRoles || !selectedApps) {
      // Display an error message or perform any desired action
      alert("Please select roles and apps before submitting.");
      return;
    }
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    const updatedUserAddToForm = {
      ...userAddToForm,
      roleId: selectedRoles,
      appId: selectedApps,
    };
    try {
      if (edit === "notedit") {
        const resp = await axios.put(
          "https://preprod-api.tbplabs.tech/user/addUser",
          updatedUserAddToForm,
          config
        );
        if (resp) {
          openAddUserForm();
        }
      } else {
        const resp = await axios.post(
          "https://preprod-api.tbplabs.tech/user/addUser",
          updatedUserAddToForm,
          config
        );
        if (resp.data.status === "success") {
          openAddUserForm();
        }
      }
    } catch (error) {
      alert(error);
    }
  }

  function updateUser(name) {
    setAdduserForm();
  }

  return (
    <>
      {adduserForm ? (
        <div className="peopleSettingContainer">
          <h1 className="account-setting">People</h1>
          {permission.ADD_USER && (
            <button onClick={() => openAddUserForm()} className="addUser">
              Add User
            </button>
          )}

          <span className="search-people">
            <input
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search People"
              type="text"
            ></input>
          </span>
          <div className="people-form">
            <table className="people-form-table">
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Department</th>
                <th>Remark</th>
                {permission.UPDATE_USER && (
                  <th
                    onClick={() => openAddUserForm()}
                    className="threeDot"
                  ></th>
                )}
              </tr>
              {userList != null
                ? userList
                    .filter((e) => {
                      return search.toLowerCase() === ""
                        ? e
                        : e.user__first_name.toLowerCase().includes(search);
                    })
                    .map((e) => (
                      <tr>
                        <td>
                          {e.user__first_name} {e.user__last_name}
                        </td>
                        <td>{e.usertype}</td>
                        <td>{e.user__email}</td>
                        <td>{e.phoneNumber}</td>
                        <td>{e.department}</td>
                        <td>{e.remark}</td>
                        {permission.UPDATE_USER && (
                          <td
                            onClick={() => openAddUserForm(e)}
                            className="threeDot"
                          >
                            <ManageAccountsIcon style={{ color: "#109cf1" }} />
                          </td>
                        )}
                      </tr>
                    ))
                : null}
            </table>
          </div>
          <div>
            <div className="addUser-container"></div>
          </div>
        </div>
      ) : (
        <div className="adduser-container">
          <h1 className="account-setting">
            Add Person
            <button onClick={() => openAddUserForm()} className="addUser">
              Close X
            </button>
          </h1>

          <div className="accountSettingForm">
            <div className="basic-information">Basic Information</div>
            <div className="addUserForm">
              <div className="field">
                <p className="label">First Name</p>
                <input
                  className="form-input"
                  variant="outlined"
                  name="first_name"
                  value={userAddToForm.first_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <p className="label">Last Name</p>
                <input
                  className="form-input"
                  variant="outlined"
                  name="last_name"
                  value={userAddToForm.last_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <p className="label">Email</p>
                <input
                  className="form-input"
                  type="email"
                  name="email"
                  variant="outlined"
                  value={userAddToForm.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <p className="label">User Name</p>
                <input
                  className="form-input"
                  variant="outlined"
                  name="username"
                  value={userAddToForm.username}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <p className="label">CRM USER ID</p>
                <input
                  className="form-input"
                  name="crmUserId"
                  variant="outlined"
                  value={userAddToForm.crmUserId}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <p className="label">Department</p>
                <input
                  className="form-input"
                  variant="outlined"
                  name="department"
                  value={userAddToForm.department}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <p className="label">User Type</p>
                <input
                  className="form-input"
                  name="usertype"
                  variant="outlined"
                  value={userAddToForm.usertype}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <p className="label">Remark</p>
                <input
                  className="form-input"
                  name="remark"
                  variant="outlined"
                  value={userAddToForm.remark}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <p className="label">Password</p>
                <input
                  className="form-input"
                  name="password"
                  variant="outlined"
                  value={userAddToForm.password}
                  onChange={handleInputChange}
                />
              </div>
              {roles && <div className="field">
                <p className="label">Roles</p>
                <Autocomplete
                  options={roles}
                  className="mt-1 px-2"
                  value={selectedRoles}
                  getOptionLabel={(option) => option?.name}
                  onChange={(event, value) => {
                    if(value){
                      console.log(value.id)
                      setSelectedRoles(value.id);
                    }
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ background: "rgb(233 244 255)" }}
                      size="small"
                    />
                  )}
                />
              </div>}
              {apps && <div className="field">
                <p className="label">Apps</p>
                <Autocomplete
                  options={apps}
                  multiple
                  className="mt-1 px-2"
                  value={selectedApps}
                  onChange={(event, value) => {
                     if (value) {
                       const selectedIds = value.map((item) => item.id);
                       console.log(selectedIds);
                       setSelectedApps(selectedIds);
                     } else {
                       console.log([]);
                       setSelectedApps([]);
                     }
                  }}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ background: "rgb(233 244 255)" }}
                      size="small"
                    />
                  )}
                />
              </div>}
              <br></br>
              <div className="submitButton">
                <button
                  onClick={() => submitAddUserForm()}
                  className="form-submit-btn"
                >
                  Submit
                </button>
                <button
                  onClick={() => openAddUserForm()}
                  style={{
                    marginLeft: "20px",
                    border: "1px solid #109cf1",
                    background: "transparent",
                    color: "#109cf1",
                  }}
                  className="form-submit-btn"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PeopleSetting;
