import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from '@mui/material/FormHelperText';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import axios from "axios";
import { BorderAllRounded } from "@mui/icons-material";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { margin } from "@mui/system";

const FormComponent = forwardRef(
  ({ group, accountData, postBody, submit, listingData }, ref) => {

    const [tempFile, settempFile] = useState(null);
    const [multiKeysState, setmultiKeysState] = useState({});
    const [inputType, setinputType] = useState({});
    const [formData, setformData] = useState({});
    const [formDataError, setformDataError] = useState({});
    const [docName, setdocName] = useState({});


    let temp = [];
    let tempObj = {};
    let tempFileName = {};
    let multiKeys = {};
    let tempError = {}
    // const [isLoading, setisLoading] = useState(second)
    // const [accountData, setAccountData] = useState();
    let myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders.append("Authorization", getCookie("auth_token")),
      redirect: "follow",
    };
    function getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
    

    useEffect(() => {
      console.log(group);

      for (let i = 0; i < group.fields.length; i++) {
        tempError[group.fields[i].id]= {error: false, errorMsg: ""}
        if (group.fields[i]?.type == "MULTICHOICE") {
          let tempArrMulti = [];
          for (let j = 0; j < group.fields[i].list.length; j++) {
            tempObj[group.fields[i].list[j].id] = false;
            tempArrMulti.push(group.fields[i].list[j].id);
          }
          multiKeys[group.fields[i].id] = tempArrMulti;
        } else if (
          group.fields[i]?.type == "DOCUMENT" ||
          group.fields[i]?.type == "IMAGE"
        ) {
          tempObj[group.fields[i]?.id] = null;
          tempFileName[group.fields[i]?.id] = false;
        } else if (group.fields[i]?.type == "SINGLECHOICE") {
          tempObj[group.fields[i]?.id] = null;
        } else {
          tempObj[group.fields[i]?.id] = null;
        }
      }
      console.log(multiKeys)
      setmultiKeysState(multiKeys);

      settempFile(tempFileName);

      setformData(tempObj);

      setformDataError(tempError);


      for (let i = 0; i < group.fields.length; i++) {
        if (group.fields[i]?.type == "MULTICHOICE") {
          let tempList = [];
          for (let j = 0; j < group.fields[i].list.length; j++) {
            tempList.push({
              id: group.fields[i].list[j].id,
              label: group.fields[i].list[j].label,
              isTrue: false,
            });
          }
          temp.push({
            id: group.fields[i].id,
            type: group.fields[i].type,
            value: {
              type: group.fields[i].type,
              value: temp,
            },
          });
        } else if (group.fields[i]?.type == "SINGLECHOICE") {
          temp.push({
            id: group.fields[i]?.id,
            type: group.fields[i]?.type,
            value: {
              id: -1,
              label: "",
            },
          });
        } else {
          temp.push({
            id: group.fields[i]?.id,
            type: group.fields[i]?.type,
            value: {
              type: group.fields[i]?.type,
              value: null,
            },
          });
        }
      }

      console.log(temp);
    }, []);

    useEffect(() => {
      // setGetValues()
      if(listingData.length){
      const names = group.fields.map((obj) => listingData.find((ele) => ele.id == obj.id));
      let tempForm = {...formData}
      for(let i = 0; i< names.length; i++){
        if(names[i]?.type == 'DOCUMENT' || names[i]?.type == 'IMAGE'){
          tempForm[names[i]?.id] = names[i]?.value.value
          if(names[i]?.type == 'IMAGE'){
            tempImg= names[i]?.value.value
            // setImageUrl(tempImg)
          }

        } else if(names[i]?.type == 'SINGLECHOICE'){
          tempForm[names[i]?.id] = names[i]?.value.propertyId
        } else if(names[i]?.type == 'MULTICHOICE'){
          for (let j = 0; j < names[i]?.value.length; j++) {
            // let multiKeys = {...multiKeysState}
            // multiKeys[names[i]?.id][names[i]?.value[j].id]= true
            tempForm[names[i]?.value[j].id] = true
          }
        } else {
          tempForm[names[i]?.id] = names[i]?.value.value
        }
      }
      console.log(tempForm)
      setImageUrl(tempImg)
      setformData(tempForm);
    }
    }, [listingData])
    

    function setGetValues() {
      // let listingData = []
      console.log(listingData)
      const names = group.fields.map((obj) => listingData.find((ele) => ele.id == obj.id));
      let tempForm = {...formData}
      for(let i = 0; i< names.length; i++){
        if(names[i]?.type == 'DOCUMENT' || names[i]?.type == 'IMAGE'){

        } else if(names[i]?.type == 'SINGLECHOICE'){
          
        } else if(names[i]?.type == 'MULTICHOICE'){
          
        } else {
          tempForm[names[i]?.id] = names[i]?.value.value
        }
      }
      

      // console.log(names)

  
      // for (let i = 0; i<=)
    }
    function onInputChange(e) {
      let temp = { ...formData, [e.target.name]: e.target.value };
      setformData(temp);
    }
    function onSwitchChange(e) {
      let temp = { ...formData, [e.target.name]: !formData[e.target.name] };
      setformData(temp);
    }
    function onCheckBoxChange(e) {
      let temp = { ...formData, [e.target.name]: !formData[e.target.name] };
      setformData(temp);
    }

    let fileUrl = [];
    useImperativeHandle(ref, () => ({
      setFileValueToJson(index) {
        console.log(formData)
        let temp = [];
        let tempError={...formDataError}
        for (let i = 0; i < group.fields.length; i++) {
          if ([group.fields[i].id] in formData) {
            // console.log(group.fields[i].id )
            if (group.fields[i].type == "SINGLECHOICE") {
              if(group.fields[i].isRequired&&!formData[group.fields[i].id]){
                tempError[group.fields[i].id].error=true
                tempError[group.fields[i].id].errorMsg="required"
              }
              else{
                temp.push({
                  id: group.fields[i].id,
                  type: group.fields[i].type,
                  value: {
                    id: formData[group.fields[i].id],
                    label: group.fields[i].list.find(
                      (item) => item.id === formData[group.fields[i].id]
                    ).label,
                  },
                })
              }
              
            }
            // else if(group.fields[i].type == "MULTICHOICE"){

            // }
            else {
              temp.push({
                id: group.fields[i].id,
                type: group.fields[i].type,
                value: {
                  type: group.fields[i].type,
                  value: formData[group.fields[i].id],
                },
              });
              if(group.fields[i].isRequired&&!formData[group.fields[i].id]){
                tempError[group.fields[i].id].error=true
                tempError[group.fields[i].id].errorMsg="required"
              }
            }
          } else {
            console.log(group.groupLabel)
            console.log(multiKeysState);
            let a = [];
            for (let j = 0;j < multiKeysState[group.fields[i].id].length;j++) {
              if (formData[multiKeysState[group.fields[i].id][j]]) {
                let x = multiKeysState[group.fields[i].id][j];
                a.push({
                  id: multiKeysState[group.fields[i].id][j],
                  label: group.fields[i].list.find((i) => i.id === x).label,
                });
              }
            }

            temp.push({
              id: group.fields[i].id,
              type: group.fields[i].type,
              value: a,
            });
          }
        }
        setformDataError(tempError)
        postBody[index]= [...temp];
        console.log(postBody);
      },
    }));
    let tempImg =[]
    const [imageUrl, setImageUrl] = useState([])
    const uploadFile = async (e, t) => {
      console.log(e.target.name)
      console.log(formData);
      console.log(accountData);
      const form = new FormData();
      let tempFileName = {
        ...tempFile,
        // [e.target.name]: e.target.value.split(/(\\|\/)/g).pop(),
        [e.target.name]: true,
      };
      settempFile(tempFileName);

      for (let i = 0; i < e.target.files.length; i++) {
        form.append("file", e.target.files[i]);
        try {
          // setIsLoading(true);
          const response = await axios({
            method: "post",
            url: `https://preprod-api.tbplabs.tech/chatapp/uploadFile?accountId=${accountData.message.account.id}&userId=${accountData.message.user.id}`,
            data: form,
            headers: { "Content-Type": "multipart/form-data" },
          });
          console.log(response);
          fileUrl.push(response.data.data.fileUrl);
          if(t=='i'){
            tempImg.push(response.data.data.fileUrl)
            setImageUrl(tempImg)
          }
          let temp = { ...formData, [e.target.name]: fileUrl };
          setformData(temp);
        } catch (error) {
          console.log(error);
        }
      }
      tempFileName = {
        ...tempFile,
        // [e.target.name]: e.target.value.split(/(\\|\/)/g).pop(),
        [e.target.name]: false,
      };
      settempFile(tempFileName);
    };

    // async function onSend() {
    //   setIsLoading(true);
    //   let reqBody = {};
    //   for (let i = 0; i < selectedFile.length; i++) {
    //     await uploadFile(i);
    //     reqBody = {
    //       action: "SendMessageToCustomer",
    //       data: {
    //         userid: userid,
    //         accountid: accountid,
    //         objectid: objectid,
    //         type: fileType,
    //         text: i === 0 ? caption : "",
    //         url: fileUrls,
    //       },
    //     };
    //     ws.send(JSON.stringify(reqBody));
    //     console.log("message sent");
    //   }
    //   // e.preventDefault();
    //   setIsLoading(false);
    //   setcaption("");
    //   closeImageModal();
    //   fileUrls = "";
    // }

    // mapFields(){
    //   for(let i=0; i<=group.fields.length; i++){
    //     if(group.fields[i].type == '')
    //   }
    // }
    return (
      <div className="accordian">
        <Accordion
          defaultExpanded
          sx={{
            boxShadow: "0px 0px 49px -18px rgb(0 0 0 / 18%)",
            borderRadius: "10px !important",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <h2>{group.groupLabel}</h2>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                {group.fields.map((item, key) => {
                  return (
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1, width: "100%" },
                        width:
                          item.type == "DOCUMENT" ||
                          item.type == "NOTE" ||
                          item.type == "IMAGE"
                            ? "100%"
                            : "48%",
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      {item.type == "TEXT" ? (
                        // <div>
                        <div className="inputDiv">
                          {/* {item.isRequired ? (
                            <div className="reqSign"></div>
                          ) : null} */}
                          <TextField
                            error={formDataError[item.id]?.error}
                            name={item.id}
                            onChange={(e) => onInputChange(e)}
                            value={formData[item.id]}
                            id="outlined-required"
                            label={item.label}
                            inputProps={{
                              style:{borderLeft: item.isRequired?"red 5px solid":"unset", borderRadius: "5px"}
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            helperText={formDataError[item.id]?.errorMsg}
                            // defaultValue="Hello World"
                            // InputProps={{
                            //   startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                            // }}
                          />
                        </div>
                        // {formDataError[item.id]?.error?<p className="errorClass">{formDataError[item.id].errorMsg}</p>:null}
                        // </div>
                      ) : item.type == "NUMBER" ? (
                        <div className="inputDiv">
                          {/* {item.isRequired ? (
                            <div className="reqSign"></div>
                          ) : null}{" "} */}
                          <TextField
                            name={item.id}
                            onChange={(e) => onInputChange(e)}
                            value={formData[item.id]}
                            id="outlined-number"
                            label={item.label}
                            type="number"
                            error={formDataError[item.id]?.error}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            helperText={formDataError[item.id]?.errorMsg}
                            inputProps={{
                              style:{borderLeft: item.isRequired?"red 5px solid":"unset", borderRadius: "5px"}
                            }}
                          />
                        </div>
                      ) : item.type == "BINARY" ? (
                        <div className="inputDiv" style={{ margin: "8px 18px" }}>
                          {item.isRequired ? (
                            <div className="reqSign"></div>
                          ) : null}{" "}
                          <label for="first">{item.label}</label>
                          <Switch
                            checked={formData[item.id]}
                            name={item.id}
                            onChange={(e) => onSwitchChange(e)}
                            // onChange={handleChange}
                            inputProps={{ "aria-label": "controlled",  }}
                          />
                        </div>
                      ) : item.type == "SINGLECHOICE" ? (
                        <FormControl className="inputDiv" style={{ margin: "8px", width: "50%" }} error={formDataError[item.id]?.error}>
                          {/* {item.isRequired ? (
                            <div className="reqSign"></div>
                          ) : null}{" "} */}
                          <span id="demo-simple-select-label">
                            {item.label}
                          </span>
                          <select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(e) => onInputChange(e)}
                            name={item.id}
                            defaultValue={formData[item.id]}
                            value = {formData[item.id]}

                            // style={{width: "50%"}}
                            label={item.label}
                            helperText={formDataError[item.id]?.errorMsg}
                            
                              style={{border: 'grey solid 1px', borderLeft: item.isRequired?"red 5px solid":"unset", borderRadius: "5px", width: "100%", height: '50px'}}
                            // onChange={handleChange}
                          >
                            <option value={null}>Select</option>
                            {item.list.map((option, key) => {
                              return (
                                <option value={option.id}>
                                  {option.label}
                                </option>
                              );
                            })}
                            {/* <MenuItem value={option.id}>{option.label}</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                          </select>
                          <FormHelperText>{formDataError[item.id]?.errorMsg}</FormHelperText>
                        </FormControl>
                      ) : item.type == "MULTICHOICE" ? (
                        <div>
                        <div className="inputDiv"
                            style={{ display: "flex", alignItems: "center", margin: "8px 18px"}}
                            >
                            {item.isRequired ? (
                              <div className="reqSign"></div>
                            ) : null}{" "}
                            <label for="first" style={{ paddingRight: "15px" }}>
                              {item.label}:
                            </label>
                            <div className="checkbox-wrapper">
                            {item.list.map((option, key) => {
                              return (
                                <label className="checkbox"><input
                                
                                  type='checkbox'
                                  checked={formData[option.id]}
                                  name={option.id}
                                  onChange={(e) => onCheckBoxChange(e)}
                                /> {option.label}</label>
                                    
                              );
                            })}
                            </div>
                          </div>
                        </div>
                      ) : item.type == "NOTE" ? (
                        <div className="inputDiv">
                          {/* {item.isRequired ? (
                            <div className="reqSign"></div>
                          ) : null}{" "} */}
                          <TextField
                            name={item.id}
                            error={formDataError[item.id]?.error}
                            sx={{ width: 350 }}
                            fullWidth
                            id="outlined-required"
                            multiline
                            rows={2}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={item.label}
                            onChange={(e) => onInputChange(e)}
                            value={formData[item.id]}
                            helperText={formDataError[item.id]?.errorMsg}
                          />
                        </div>
                      ) : item.type == "DOCUMENT" ? (
                        <div className="inputDiv" style={{ margin: "8px", width: "100%" }}>
                          {item.isRequired ? (
                            <span className="reqSign" style={{left: "0"}}>
                              <div></div>
                            </span>
                          ) : null}{" "}
                          <input
                            // accept="image/*"
                            // className={classes.input}
                            onChange={(e) => uploadFile(e,'d')}
                            style={{ display: "none" }}
                            id={item.id}
                            multiple
                            name={item.id}
                            type="file"
                          />
                          <label htmlFor={item.id}>
                            <Button
                              variant="outlined"
                              component="span"
                              // className={classes.button}
                            >
                              {item.label}
                            </Button>
                          </label>
                          <span style={{ margin: "8px", width: "100%" }}>
                            {/* {tempFile ? tempFile[item.id] : ""} */}
                            {formData[item.id] ? (
                              <CheckCircleOutlineIcon
                                style={{ color: "green" }}
                              />
                            ) : null}{" "}
                            {formData[item.id]
                              ? formData[item.id]?.length
                              : "0"}{" "}
                            file(s) uploaded.
                            {tempFile ? (
                              <CircularProgress
                                style={{
                                  marginLeft: "8px",
                                  display: tempFile[item.id]
                                    ? "inline-block"
                                    : "none",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            ) : null}
                          </span>
                        </div>
                      ) : item.type == "IMAGE" ? (
                        <div className="inputDiv" style={{display:"flex", margin: "8px", width: "100%" }}>
                          {item.isRequired ? (
                            <div className="reqSign"style={{left: "0", zIndex: "1", height: "93%"}}></div>
                          ) : null}{" "}
                          <input
                            accept="image/*"
                            // className={classes.input}
                            onChange={(e) => uploadFile(e,'i')}
                            style={{ display: "none" }}
                            id={item.id}
                            multiple
                            name={item.id}
                            type="file"
                          />
                          <label htmlFor={item.id}>
                            <Button
                              variant="contained"
                              component="span"
                              // className={classes.button}
                            >
                              {item.label}
                            </Button>
                          </label>
                          <div style={{display:"flex", width:"max-content", marginLeft: '10px'}}>
                          
                          {imageUrl.map((item, key)=>{
                            return(<a target='_blank' href={item}><img src={item} style= {{margin: "0 10px",objectFit: 'cover', width:"50px", height: "50px"}}alt="img"/></a>)
                          })}
                          </div>
                          <span style={{ margin: "8px" }}>
                            {/* {tempFile ? tempFile[item.id] : ""} */}
                            {formData[item.id] ? (
                              <CheckCircleOutlineIcon
                                style={{ color: "green" }}
                              />
                            ) : null}{" "}
                            {formData[item.id]
                              ? formData[item.id]?.length
                              : "0"}{" "}
                            file(s) uploaded.
                            {tempFile ? (
                              <CircularProgress
                                style={{
                                  marginLeft: "8px",
                                  display: tempFile[item.id]
                                    ? "inline-block"
                                    : "none",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            ) : null}
                          </span>
                        </div>
                      ) : (
                        <div className="input-wrapper">
                          <label for="first">First</label>
                          <input type="text" />
                        </div>
                      )}
                    </Box>
                  );
                })}
                {/* <button onClick={() => { 
                console.log(multiKeysState)
                // setFileValueToJson()
                }}>Click</button> */}
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* <h2>{group.groupLabel}</h2>
      <div className="input-wrapper">
        <label for="first">First</label>
        <input type="text" />
      </div> */}
      </div>
    );
  }
);
export default FormComponent;
