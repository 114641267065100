import React from "react";

const data = {
    1: {
      id: 1,
      name: "yuvraj",
      child_ID: []
    }
  };


  const TreeNode = ({ node, data, level }) => {
    const childNodes = node.child_ID.map((childId) => data[childId]);
  
    return (
      <div style={{ marginLeft: level * 20 }}>
        <div>{node.name}</div>
        {childNodes.length > 0 && (
          <div>
            {childNodes.map((childNode) => (
              <TreeNode
                key={childNode.id}
                node={childNode}
                data={data}
                level={level + 1}
              />
            ))}
          </div>
        )}
      </div>
    );
  };
  
  const App1 = ({ data }) => {
    const rootNodes = Object.values(data).filter(
      (node) => !node.child_ID || node.child_ID.length === 0
    );
  
    return (
      <div style={{ padding: "20px" }}>
        <h1>Tree Visualization</h1>
        {rootNodes.map((rootNode) => (
          <TreeNode key={rootNode.id} node={rootNode} data={data} level={0} />
        ))}
      </div>
    );
  };
  
  export default App1;
  
