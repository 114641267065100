import React, { useEffect, useState } from "react";
import search_icon from "../Images/search-icon.png";
import AuthApi from "../../AuthApi";
import { useHistory } from "react-router-dom";
import Layout from "./Layout";
import axios from "axios";
import Sidebar from "../../utils/components/Sidebar";
import { ArrowDropDown } from "@mui/icons-material";


function Communicate() {
  const Toggle = React.useContext(AuthApi);
  const history = useHistory();
  const [accountData, setAccountData] = useState();
  const [appAccountId, setAppAccountId] = useState(null);
  const [currentUserList, setCurrentUserList] = useState(null);
  const [defaultUser, SetDefaultUser] = useState("");
  const [isUserDropDownOpen, setIsUserDropDownOpen] = useState(false);
  const [searchContainerStyle, setSeachContainerStyle] = useState(
    Toggle.toggleSlider
      ? {
          marginLeft: "235px",
          display: "flex",
          flexDirection: "column",
        }
      : {
          marginLeft: "75px",
          display: "flex",
          flexDirection: "column",
        }
  );

  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const Auth = React.useContext(AuthApi);
  var raw = "";
  let myHeaders = new Headers();
  const name = "auth_token";
  let authtoken = getCookie(name);
  authtoken = "Token " + authtoken;
  //console.log(authtoken)
  myHeaders.append("Authorization", authtoken);
  //console.log(myHeaders)

  // handeling click on logout button
  function handleClick() {
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    function eraseCookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    function handleResult() {
      localStorage.removeItem("userDetails");
      eraseCookie(name);
      Auth.setAuth(false);
    }

    // calling logout api
    fetch("https://preprod-api.tbplabs.tech/user/logout", requestOptions)
      .then((response) => response.text())
      .then((result) => handleResult())
      .catch((error) => console.log("error", error));
  }
  async function getMeApi() {
    let myHeaders = new Headers();
    const auth_name = "auth_token";
    let authtoken = getCookie(auth_name);
    authtoken = "Token " + authtoken;
    myHeaders.append("Authorization", authtoken);
    const config = {
      headers: { Authorization: authtoken },
    };

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const resp = await axios.get(
        "https://preprod-api.tbplabs.tech/user/me",
        config
      );
      setAccountData(resp.data);

      try {
        const currentUserListData = await axios.get(
          "https://preprod-api.tbplabs.tech/chatapp/getChatApps",
          config
        );
        setCurrentUserList(currentUserListData.data);
          setAppAccountId(currentUserListData.data[0].id);
          SetDefaultUser(currentUserListData.data[0].name);
          
      } catch (err) {
        SetDefaultUser(null);
      }
    } catch (err) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      Auth.setAuth(false);
      history.push("/Login");
    }
  }

  useEffect(() => {
    getMeApi();
  }, []);


  function clickUserHandler(name, id){
    console.log(id)
    SetDefaultUser(name);
    setAppAccountId(id);

  }

  const userDropDownOpenStyled={
    height: "auto",
    display: "block",
    backgroundColor:"white",
    transitionDuration: "0.5s",
    borderRadius:"5px",
    boxShadow:"2px 2px 5px 5px #F4F4F4",
    fontWeight: "bold",
    padding:"10px 0px",
    maxWidth:"10rem"
  }
  const userDropDownCloseStyled={
    height: 0,
    display:"none",
  }

  return (
    <div className="dashboard">
      <Sidebar appAccountId={appAccountId} active="Communicate" />
      <div className="search-container" style={searchContainerStyle}>
        <input
          className="search-box"
          type="text"
          placeholder=" Global Search"
        ></input>
        {defaultUser && (
          <div className="user-account-dropdown" onClick={()=>setIsUserDropDownOpen(!isUserDropDownOpen)}>
            <span className="text-sm font-bold">{defaultUser}</span>
            <span>
              <ArrowDropDown />
            </span>
            <div style={isUserDropDownOpen?userDropDownOpenStyled:userDropDownCloseStyled} className="userDropdown">
              {currentUserList?.map((user)=>(
                <p className="px-2 text-sm py-1" onClick={()=>clickUserHandler(user.name, user.id)}>{user.name}</p>
              ))}
            </div>
          </div>
          
        )}

        <img className="seach-icon" src={search_icon} alt="search-icon" />
            

        {accountData && (
          <>
            <div className="data-container">
              <Layout accountData={accountData} appAccountId={appAccountId} />
            
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Communicate;
