import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import CommunicateContext from "../../../../../store/CommunicateContext";
import Cookies from "js-cookie";
import "./chatarea.css";
import Message from "./Message/Message";
import ChatFooter from "./ChatFooter";
import SideBar from "../SideBar/SideBar";
import sound from "../../assets/sound.mp3";
import axios from "axios";
import TemplateModal from "../Modals/TemplateModal";
import TagModal from "../Profile/Modal";
import { CircularProgress } from "@mui/material";
import { MoreVert, ThreeDRotation } from "@mui/icons-material";


const ChatArea = ({
  accountid,
  userid,
  profile,
  setActiveProfile,
  showTagModal,
  setShowTagModal,
  tag,
  appAccountId,
}) => {
  const communicateCtx = useContext(CommunicateContext);
  const [socketResponse, setSocketResponse] = useState();
  const [nextoffset, setNextoffset] = useState(0);
  let [bottomScroll, setBottomScroll] = useState(true);
  const bottomDiv = useRef(null);
  const a = useRef(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null);

  const [sidebarData, setSidebarData] = useState();
  const [tempObjectId, setTempObjectId] = useState();
  const [templates, setTemplates] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [inboundSidebarData, setInboundSidebarData] = useState();
  const [inboundSeachData, setInboundSearchData] = useState();
  const [connectionId, setConnectionId] = useState();
  const [isChatListing, setIsChatListing] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]); // being used inside TagFilerInput.js
  const [tagIds, setTagIds] = useState([]); // being used inside TagFilerInput.js
  const [refreshSidebar, setRefreshSidebar] = useState(false);
  const [totalMessages, setTotalMessage] = useState(null);

  const web = useRef(
    useMemo(
      () =>
        new WebSocket(
          `wss://aeuw7yqidg.execute-api.ap-south-1.amazonaws.com/dev?accountid=12412&userid=121231&objectid=1`
        ),
      []
    )
  );

  const audio = useMemo(() => new Audio(sound), []);

  const listing = {
    action: "getChatHistory",
    data: {
      accountid: accountid,
      appAccountId: appAccountId,
      userid: userid,
      isPortal: "TRUE",
    },
  };

  const scrollToBottom = () => {
    if (bottomDiv.current && bottomScroll) {
      bottomDiv.current.scrollIntoView();
    }
  };
  const handleScroll = (e) => {
    let element = e.target;
    if (element.scrollTop === 0) {
      setBottomScroll(false);
      //fetch messages
      if (nextoffset === 0) {
        return;
      } else {
        getChatHistoryByApi();
      }
    }
  };
  function pingWs() {
    web.current.send(JSON.stringify("."));
  }
  const MINUTE_MS = 9000 * 60;

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('Logs every minute');
      pingWs();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);
  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const Authorization = {
    headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
  };

  async function getSidebarListingByApi(query = "", order = "", filter = {}) {
    const params = {};
    params.query = query;
    params.orderBy = order;
    let temp = filter;
    delete temp?.order;
    const arr = [],
      obj = Object.keys(temp);
    for (var x in obj) {
      if (temp[obj[x]] === true) {
        arr.push(obj[x]);
      }
    }
    params.filters = arr.join(",");
    console.log(filter)

    await axios
      .get(
        `https://preprod-api.tbplabs.tech/chatapp/fetchWhatsAppChatDataForSideBarListingReactApp?query=${query}&orderBy=${order}&filters=${params.filters}&connectionId=${connectionId}&archived=${isArchived}&tagIds=${tagIds}&appAccountId=${appAccountId}&chatExpiringBy=${filter.chatExpiringBy}&searchInChat=${filter.searchInChat}`,
        Authorization,
        { params }
      )
      .then((response) => {
        setTotalMessage(response.data.totalMessages);
        setSidebarData(response.data.data);
      });
  }
  const appAccountIdRef = useRef(appAccountId);
  let tempId = useRef(0);

  async function getChatHistoryByApi(resObject) {
    const currentAppAccountId = appAccountIdRef.current;

    await axios(
      `https://preprod-api.tbplabs.tech/chatapp/getChatHistory?objectId=${tempId.current}&accountId=${accountid}&appAccountId=${currentAppAccountId}&offset=${nextoffset}&isPortal=TRUE`,
      Authorization
    ).then((response) => {
      if (nextoffset === 0) {
        setSocketResponse(response.data.data);
        setNextoffset(response.data.Nextoffset);
      } else {
        setSocketResponse((prevArray) => [...response.data.data, ...prevArray]);
        setNextoffset(response.data.Nextoffset);
        a.current.childNodes[response.data.data.length].scrollIntoView();
      }
    });
  }

  useEffect(() => {
    appAccountIdRef.current = appAccountId;
    tempId.current = communicateCtx.objectid;
    setBottomScroll(true);
  }, [communicateCtx.objectid]);

  const getNotification = (data) => {
     if (Notification.permission === "granted") {
       const notificationOptions = {
         title: `New Message from ${data[0].name}`,
         body: data[0].Text,
       };

       new Notification(notificationOptions.title, notificationOptions);
     } else if (Notification.permission !== "denied") {
       Notification.requestPermission().then((permission) => {
         if (permission === "granted") {
           getNotification(data); // Retry sending the notification after obtaining permission
         }
       });
     }
  };

  // All logic for receiving messages and sending actions to web socket

  const connect = () => {
    web.current.onopen = () => {
      web.current.send(JSON.stringify(listing));
    };

    web.current.onmessage = function (event) {
      let res = JSON.parse(event.data);
      if (!connectionId && res.connectionId) {
        setConnectionId(res.connectionId);
      }

      if (res.responseType === "inboundSideBarSearchListing") {
        setInboundSearchData(res.data);
      }

      if (res.responseType === "chatListing") {
        setIsChatListing(true);
      }
      if (res.responseType === "inboundSideBarListing") {
        getNotification(res.data);
        setInboundSidebarData(res.data);
      }
      if (res.data && res.responseType === "chatHistory") {
        if (res.data.length > 2) {
          getChatHistoryByApi(res.data[0].objectid);
        }

        if (res.data.length <= 2) {
          setSocketResponse(res.data[0].Text);
          scrollToBottom();
        }
      }
      if (res.data && res.responseType === "SendMessageToCustomer") {
        const updatedData = {
          ...res.data[0],
        };

        setSocketResponse((prevArray) => [...prevArray, updatedData]);
        scrollToBottom();
      }
      if (res.data && res.responseType === "inboundMessage") {
        audio.play();
        if (res.data[0].objectid === tempId.current) {
          let temp = res.data[0];
          setSocketResponse((prevArray) => [...prevArray, temp]);
          setActiveProfile();
        }
        scrollToBottom();
      }
    };

    web.current.onclose = function (e) {
      if (waitingToReconnect) {
        return;
      }
      setWaitingToReconnect(true);
      setTimeout(() => setWaitingToReconnect(null), 5000);
    };

    web.current.onerror = function (err) {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      web.current.close();
    };
  };

  useEffect(() => {
    scrollToBottom();
  }, [socketResponse]);

  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }
    connect();
    getTemplates();
  }, [waitingToReconnect]);

  useEffect(() => {
    if (isChatListing) {
      if (connectionId) {
        console.log("refreshed");
        getSidebarListingByApi();
      }
    }
  }, [connectionId, isChatListing, isArchived, refreshSidebar]);

  useEffect(() => {}, [connectionId]);

  const getTemplates = async () => {
    try {
      let url = "https://preprod-api.tbplabs.tech/chatapp/templates/nonToken";
      let res = await axios.get(url, {
        params: {
          accountid: accountid,
          appAccountId: appAccountId,
        },
        headers: Authorization.headers,
      });
      setTemplates(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getTemplates();
    getSidebarListingByApi();
  }, [appAccountId]);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (selectedTags.length > 0) {
      const tagIds = selectedTags.map((tag) => tag.id).join(",");
      setTagIds(tagIds);
    } else {
      setTagIds("");
    }
  }, [selectedTags]);

  useEffect(() => {
    // Request notification permission when the component mounts
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission !== "granted") {
          console.log("Notification permission denied.");
        }
      });
    }
  }, []);


  return (
    <>
      {sidebarData ? (
        <SideBar
          accountid={accountid}
          userid={userid}
          sidebarData={sidebarData}
          ws={web.current}
          inboundSidebarData={inboundSidebarData}
          setSidebarData={setSidebarData}
          inboundSeachData={inboundSeachData}
          setTempObjectId={setTempObjectId}
          getSidebarListingByApi={getSidebarListingByApi}
          connectionId={connectionId}
          profile={profile}
          tag={tag}
          isArchived={isArchived}
          setIsArchived={setIsArchived}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          appAccountId={appAccountId}
          refreshSidebar={refreshSidebar}
          setRefreshSidebar={setRefreshSidebar}
          totalMessages={totalMessages}
        />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            marginTop: "40vh",
          }}
        >
          <CircularProgress />
        </div>
      )}

      <TemplateModal
        openModal={modalIsOpen}
        closeModal={closeModal}
        profile={profile}
        templates={templates}
        accountid={accountid}
        userid={userid}
        ws={web.current}
        appAccountId={appAccountId}
      />
      <div className="chat-area">
        <div className="chat-area-header"></div>
        <div ref={a} className="chat-area-main" onScroll={handleScroll}>
          {showTagModal && (
            <TagModal
              tag={tag}
              profile={profile}
              setShowTagModal={setShowTagModal}
            />
          )}
          {socketResponse && (
            <div className="message-header-chatArea">
              <div>
              {socketResponse
                ? socketResponse[socketResponse.length - 1]?.name
                : null}
              </div>
              <div className="three-dot-icon">
                <MoreVert />
                </div>
            </div>
          )}
          {socketResponse &&
            socketResponse.map((e, id) => {
              return (
                <Message
                  key={id}
                  message={e.Text}
                  sentBy={e.SentBy}
                  mediaURL={e.MediaUrl}
                  messageType={e.MessageType}
                  time={e.created_at}
                  senderName={e.SenderName}
                  statuses={e.Statuses}
                />
              );
            })}

          <div ref={bottomDiv} />
        </div>
        {communicateCtx.objectid && (
          <ChatFooter
            ws={web.current}
            accountid={accountid}
            userid={userid}
            objectid={communicateCtx.objectid}
            profile={profile}
            openModal={openModal}
            appAccountId={appAccountId}
          />
        )}
      </div>
    </>
  );
};

export default ChatArea;
