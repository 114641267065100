import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { useState, useEffect, useContext } from "react";
import "./Profile.css";
import axios from "axios";
import Attachedfile from "./Attachedfile";
import Avatar from "@mui/material/Avatar";
import Dropdown from "../ChatArea/Dropdown/Dropdown";
import Tags from "./Tags";
import BlockIcon from "@mui/icons-material/Block";
import ArchiveIcon from "@mui/icons-material/Archive";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import Cookies from "js-cookie";
import CommunicateContext from "../../../../../store/CommunicateContext";
import { filteredContactsAtom } from "../SideBar/SideBar";
import { useAtom } from "jotai";

const Profile = ({
  profile,
  showTagModal,
  setShowTagModal,
  tag,
  appAccountId,
}) => {
  const [dropdownUsers, setDropdownUsers] = useState();
  const [dropdownTeam, setDropdownTeam] = useState();
  const [isArchived, setIsArchived] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const communicateCtx = useContext(CommunicateContext);
  const [isChatRead, setIsChatRead] = useState(true); // assuming chat is read to add button for unread
  const [filteredContactsGlobal, setFilteredContactsGlobal] =
    useAtom(filteredContactsAtom);

  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  async function getDropdowonList() {
    let myHeaders = new Headers();
    const name = "auth_token";
    let authtoken = getCookie(name);
    authtoken = "Token " + authtoken;
    const config = {
      headers: { Authorization: authtoken },
    };

    const api = "https://preprod-api.tbplabs.tech/user/assignee";
    //api call with authorization

    let response = await axios.get(api, config);

    //api call without authorization
    // let response = await axios.get(api);
    await setDropdownUsers(response.data.data.users);
    await setDropdownTeam(response.data.data.teams);
    await setTimeout(() => {
      getDropdowonList();
    }, 100000);
  }
  useEffect(() => {
    getDropdowonList();
  }, []);

  const margin = {
    marginTop: "10px",
  };

  const handleProfile = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, /");
    myHeaders.append("Authorization", `Token ${Cookies.get("auth_token")}`);

    var raw = JSON.stringify({
      enabled: isBlocked,
      archived: isArchived,
      contactId: communicateCtx?.objectid,
      appAccountId: appAccountId,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://preprod-api.tbplabs.tech/contacts/contact", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.enabled === true) {
          setIsBlocked(false);
        }
        if (result.data.archived === true) {
          setIsArchived(true);
        }
        if (result.data.enabled === false) {
          setIsBlocked(true);
        }
        if (result.data.archived === false) {
          setIsArchived(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleArchive = async () => {
    try {
      const response = await axios.patch(
        "https://preprod-api.tbplabs.tech/contacts/contact",
        {
          archived: !isArchived,
          contactId: communicateCtx?.objectid,
          id: profile?.Data.object.id,
          appAccountId: appAccountId,
        },
        {
          headers: {
            Authorization: `Token ${Cookies.get("auth_token")}`,
          },
        }
      );

      const { enabled, archived } = response.data.data;
      setIsBlocked(enabled);
      setIsArchived(archived);
      const newContacts = filteredContactsGlobal.filter(
        (item) => item.objectId !== communicateCtx?.objectid
      );
      archived && setFilteredContactsGlobal(newContacts);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleBlock = async () => {
    try {
      const response = await axios.patch(
        "https://preprod-api.tbplabs.tech/contacts/contact",
        {
          enabled: !isBlocked,
          contactId: communicateCtx?.objectid,
          id: profile?.Data.object.id,
          appAccountId: appAccountId,
        },
        {
          headers: {
            Authorization: `Token ${Cookies.get("auth_token")}`,
          },
        }
      );

      const { enabled, archived } = response.data.data;
      setIsBlocked(enabled);
      setIsArchived(archived);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    console.log(profile);
    setIsArchived(false);
    setIsBlocked(false);
    if (profile?.Data.object.archived) {
      setIsArchived(true);
    }
    if (profile?.Data.object.enabled) {
      setIsBlocked(true);
    } else {
      setIsBlocked(false);
      setIsArchived(false);
    }
  }, [profile]);

  const handleMarkAsUnread = () => {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${Cookies.get("auth_token")}`,
      },
      body: JSON.stringify({
        read: false,
        contactId: communicateCtx?.objectid,
        id: profile?.Data.object.id,
        appAccountId: appAccountId,
      }),
    };

    fetch("https://preprod-api.tbplabs.tech/contacts/contact", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Handle the API response if needed
        console.log(result);
        setIsChatRead(false);
      })
      .catch((error) => console.log("error", error));
  };

  const handleMarkChatRead = () => {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${Cookies.get("auth_token")}`,
      },
      body: JSON.stringify({
        read: true,
        id: profile?.Data.object.id,
        contactId: communicateCtx?.objectid,
        appAccountId: appAccountId,
      }),
    };

    fetch("https://preprod-api.tbplabs.tech/contacts/contact", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Handle the API response if needed
        console.log(result);
        setIsChatRead(true);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className="detail-area border-r-2 border-slate-200">
        <div className="detail-area-header">
          <Dropdown
            dropdownUp={dropdownUsers}
            dropdownDown={dropdownTeam}
            profile={profile}
          />
          <Avatar style={margin} sx={{ width: 70, height: 70 }}>
            R
          </Avatar>
          {profile && (
            <div className="detail-title mt-3">
              {profile?.Data.object.firstname} {profile?.Data.object.lastname}
            </div>
          )}
          {profile?.Data.object.conversationToExpireIn !== "Expired" ? (
            <span className="text-sm my-0 mb-6 text-center text-success expiresIn">
              Expires in : {profile?.Data.object.conversationToExpireIn}
            </span>
          ) : null}

          {true && (
            <>
              <div>{profile?.Data.object.conversation}</div>
              <div className="w-1/2 flex justify-between mt-2">
                <BlockIcon
                  style={{ color: isBlocked ? "#0086ff" : "grey" }}
                  onClick={handleBlock}
                  className="cursor-pointer"
                />
                <ArchiveIcon
                  style={{ color: isArchived ? "#0086ff" : "grey" }}
                  onClick={handleArchive}
                  className="cursor-pointer"
                />
                {isChatRead ? (
                  <MarkAsUnreadIcon
                    style={{ color: "grey" }}
                    onClick={handleMarkAsUnread}
                    className="cursor-pointer"
                  />
                ) : (
                  <MarkChatReadIcon
                    style={{ color: "grey" }}
                    onClick={handleMarkChatRead}
                    className="cursor-pointer"
                  />
                )}
              </div>
            </>
          )}

          <div className="text-left mr-auto p-5 b w-full mt-4 overflow-x-auto rounded-lg drop-shadow-md bg-white">
            {profile && (
              <div className="">
                <div className="font-semibold text-[#797c8c]">Name</div>
                <div>
                  {profile?.Data.object.firstname}{" "}
                  {profile?.Data.object.lastname}
                </div>
              </div>
            )}
            <div className="mt-3">
              <div className="font-semibold text-[#797c8c]">Job title</div>
              {profile && <div>{profile?.Data.object.jobtitle}</div>}
            </div>
            <div className="mt-3">
              <div className="font-semibold text-[#797c8c]">Email</div>
              {profile && <div>{profile?.Data.object.email}</div>}
            </div>
            <div className="mt-3">
              <div className="font-semibold text-[#797c8c]">Phone number</div>
              {profile && <div>{profile?.Data.object.phone}</div>}
            </div>
          </div>
        </div>
        {profile && (
          <div className="rounded-lg drop-shadow-md bg-white p-4 my-2">
            <div className="flex justify-between">
              <div>Tags</div>
              <div
                onClick={() => setShowTagModal(true)}
                className="cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </div>
            </div>
            <div className="border-t-2 my-2 border-gray-200" />
            {profile &&
              profile?.Data.object.tags.map((tagId) => {
                const taginfo = tag?.find((item) => item.id === tagId);
                return (
                  <Tags
                    setShowTagModal={setShowTagModal}
                    name={taginfo?.name}
                    color={taginfo?.color}
                  />
                );
              })}
          </div>
        )}
        <div className="detail-photos">
          <div className="detail-photo-title">
            <AttachFileOutlinedIcon />
            Attached files
          </div>
          <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
            {profile &&
              profile?.Data.uploadedfile.map((file, id) => {
                return <Attachedfile data={file} key={id} />;
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Profile;
