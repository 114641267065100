import React, {useEffect, useState} from 'react'
import { Route, Redirect } from 'react-router-dom'

function ProtectedRoute({ auth, setAuth, component: Component, ...rest }) {

  const [a, setA] = useState(false) //this is used to fix the redirect to home page issue when we refresh.

  useEffect(() => {
    setA(true)
  },[auth]) // since initially auth is false, we first wait for it to change to true incase of a valid user.
            // without doing it whenever we refresh the page it will redirect us to login page since, auth=false "initially" and since we have auth-token stored in cookies, we will be redirected to home page, and it will be difficult to debug what is causing it to redirect to home page.
  return (<>
    {a && <Route
      {...rest}
      render={() =>
        auth ? (<Component />) : (
          <Redirect to='/Login/' />
        )}
    />}
 </>
  )
}


export default ProtectedRoute