import React, { useState, useEffect, useRef } from "react";
import FormComponent from "./FormComponent";
// import AccountSetting from "./AccountSetting/AccountSetting";
// import PeopleSetting from "./PeopleSetting/PeopleSetting";
import "./SettingSidebar.css";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import axios from "axios";
import AuthApi from "../../AuthApi";
import { useHistory } from "react-router-dom";

const AddForm = () => {
  const [active, setActive] = useState("");
  const [accountData, setAccountData] = useState();
  const [submit, setSubmit] = useState(false);
  const history = useHistory();
  // const [postBody, setPostBody] = useState([])
  // const [errors, setErrors] = useState(false)
  let postBody = [];
  let errors = false;
  const Auth = React.useContext(AuthApi);
  function changeActiveElement(active) {
    setActive(active);
  }
  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  let myHeaders = new Headers();
  const refs = useRef([]);
  function getData() {
    for (let i = 0; i < groupData.length; i++) {
      refs.current[i].setFileValueToJson(i);
    }
    if (errors) {
      return;
    } else {
      sendData();
    }
  }

  async function sendData() {
    const name = "auth_token";
    let authtoken = getCookie(name);
    authtoken = "Token " + authtoken;
    myHeaders.append("Authorization", authtoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    let tempData = [];
    for (let i = 0; i < postBody.length; i++) {
      tempData.push(...postBody[i]);
    }
    let data = {
      data: tempData,
    };
    try {
      const resp = await axios.post(
        "https://preprod-api.tbplabs.tech/contacts/Contactform",
        data,
        config
      );
    } catch (error) {
    }
  }

  const [groupData, setgroupData] = useState([]);
  useEffect(() => {
    const name = "auth_token";
    let authtoken = getCookie(name);
    authtoken = "Token " + authtoken;
    myHeaders.append("Authorization", authtoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    function handleData(result) {
      if (result.detail !== "Invalid token.") {
      } else return;
    }

    //Calling ME api
    fetch("https://preprod-api.tbplabs.tech/user/me", requestOptions)
      .then(
        (res) =>
          res.json()
      )
      .then((data) => {
        setAccountData(data);
      })
      .catch(
        (error) => (
          (document.cookie =
            name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"),
          Auth.setAuth(false),
          history.push("/Login")
        )
      );
    fetch(
      "https://preprod-api.tbplabs.tech/contacts/Contactform",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setgroupData(data.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const pageStyle = {
    display: "flex",
  };
  return (
    <div style={pageStyle}>
      {/* SETTING SIDEBAR */}
      <div className="sideBarcontainer">
        <ul>
          {groupData.map((item, key) => {
            return <li>{item.groupLabel}</li>;
          })}
        </ul>
      </div>
      <div>
        {groupData.map((item, key) => {
          return (
            <FormComponent
              group={item}
              postBody={postBody}
              submit={submit}
              accountData={accountData}
              errors={errors}
              ref={(el) => (refs.current[key] = el)}
            />
          );
        })}
        <div style={{ margin: "20px 0" }}>
          <Button
            variant="contained"
            onClick={() => {
              getData();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddForm;
