import React, { useEffect, useState } from "react";
import dashboard_logo from "./Images/dashboard.png";
import logout_btn from "./Images/logout-btn.png";
import Contact_logo from "./Images/jobs.png";
import campaigns_1 from "./Images/campaigns-1.png";
import campaigns_2 from "./Images/campaigns-2.png";
import candidates_logo from "./Images/candidates.png";
import communicate_logo from "./Images/communicate.png";
import analytics_1 from "./Images/analytics-1-colored.png";
import analytics_2 from "./Images/analytics-left-colored.png";
import analytics_3 from "./Images/analytics-right-colored.png";
import settings_logo from "./Images/settings.png";
import toggle from "./Images/toggle.png";
import search_icon from "./Images/search-icon.png";
import bell_icon from "./Images/notification-icon.png";
import toggle_colored from "./Images/toggle-colored.png";
import dot_icon from "./Images/Ellipse.png";
import AuthApi from "../AuthApi";
import { useHistory } from "react-router-dom";
import AddForm from "./AddForm/AddForm";
import Listings from "./Listing/Listings";

function Analytics() {
  const Toggle = React.useContext(AuthApi);
  const history = useHistory();
  //showing sidebar as per toggle
  const [currentSidebarStyle, setCurrentSidebarStyle] = useState(
    Toggle.toggleSlider
      ? {
          position: "fixed",
          width: "235px",
          maxWidth: "100%",
          height: "100%",
          background: "#ffffff",
          boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
          transition: "0.1s",
        }
      : {
          position: "fixed",
          width: "75px",
          maxWidth: "100%",
          height: "100%",
          background: "#ffffff",
          boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
          transition: "0.1s",
        }
  );
  // showing search container as per toggle
  const [searchContainerStyle, setSeachContainerStyle] = useState(
    Toggle.toggleSlider
      ? {
          marginLeft: "235px",
          display: "flex",
          flexDirection: "column",
        }
      : {
          marginLeft: "75px",
          display: "flex",
          flexDirection: "column",
        }
  );

  // handeling toggle changes
  function handleToggle() {
    Toggle.setToggleSlider(!Toggle.toggleSlider);
    if (Toggle.toggleSlider) {
      setCurrentSidebarStyle((prevStyle) => ({
        ...prevStyle,
        width: "75px",
        transition: "0.3s",
      }));
      setSeachContainerStyle((prevStyle) => ({
        ...prevStyle,
        marginLeft: "75px",
      }));
    } else {
      setCurrentSidebarStyle((prevStyle) => ({
        ...prevStyle,
        width: "235px",
        transition: "-0.5s",
      }));
      setSeachContainerStyle((prevStyle) => ({
        ...prevStyle,
        marginLeft: "235px",
      }));
    }
  }

  // getting cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  function redirectToForm(){
    history.push('/AddForm')
  }

  const [userOnline, setUserOnline] = useState("");
  const Auth = React.useContext(AuthApi);
  var raw = "";
  let myHeaders = new Headers();
  const name = "auth_token";
  let authtoken = getCookie(name);
  authtoken = "Token " + authtoken;
  //console.log(authtoken)
  myHeaders.append("Authorization", authtoken);
  //console.log(myHeaders)

  // handeling click on logout button
  function handleClick() {
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    function eraseCookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    function handleResult() {
      localStorage.removeItem("userDetails");
      eraseCookie(name);
      Auth.setAuth(false);
    }

    // calling logout api
    fetch("https://preprod-api.tbplabs.tech/user/logout", requestOptions)
      .then((response) => response.text())
      .then((result) => handleResult())
      .catch((error) => console.log("error", error));
  }

  function redirectToDashboard() {
    history.push("/Dashboard");
  }

  function redirectToCampaigns() {
    history.push("/Campaigns");
  }

  function redirectToCandidates() {
    history.push("/Candidates");
  }

  function redirectToCommunicate() {
    history.push("/Communicate");
  }
  function redirectToAnalytics(){
    history.push('/Analytics')
  }
  function redirectToContact() {
    history.push("/Contact");
  }

  function redirectToSettings() {
    history.push("/Settings");
  }

  // // getting user details from local storage to use it in sidebar
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <div className="dashboard">
      <div style={currentSidebarStyle}>
        <div className="sidebar-title">{userDetails.account.name}</div>
        <div className="sidebar-upper-divider dvdr">
          <hr></hr>
        </div>
        <div className="sidebar-profile-container">
          <img
            className="profile-pic"
            src={userDetails.account.logo}
            alt="profile-pic"
          />
          {Toggle.toggleSlider && (
            <div className="profile-name">{userDetails.user.first_name}</div>
          )}
          {Toggle.toggleSlider && (
            <div className="profile-campaigns">{userDetails.user.email}</div>
          )}
        </div>
        <div className="sidebar-menu-dashboard menu">
          <img
            className="dashboard-logo menu-logo"
            src={dashboard_logo}
            onClick={redirectToDashboard}
            alt="dashboard_logo"
          />
          {Toggle.toggleSlider && (
            <div
              className="dashboard-text menu-text"
              onClick={redirectToDashboard}
              style={{ color: "#334d6e" }}
            >
              Dashboard
            </div>
          )}
        </div>
        <div className="sidebar-menu-Contact menu">
          <img
            className="Contact-logo menu-logo"
            src={Contact_logo}
            alt="Contact_logo"
            onClick={redirectToContact}
          />
          {Toggle.toggleSlider && (
            <div className="Contact-text menu-text" onClick={redirectToContact}>
              Contact
            </div>
          )}
        </div>
        <div className="sidebar-menu-campaigns menu">
          <img
            className="campaigns-logo-1 menu-logo"
            src={campaigns_1}
            alt="campaigns_logo"
            onClick={redirectToCampaigns}
          />
          <img
            className="campaigns-logo-2"
            src={campaigns_2}
            onClick={redirectToCampaigns}
          />
          {Toggle.toggleSlider && (
            <div
              className="campaigns-text menu-text"
              onClick={redirectToCampaigns}
            >
              Campaigns
            </div>
          )}
        </div>
        <div className="sidebar-menu-candidates menu">
          <img
            className="candidates-logo menu-logo"
            src={candidates_logo}
            alt="candidates_logo"
            onClick={redirectToCandidates}
          />
          {Toggle.toggleSlider && (
            <div
              className="candidates-text menu-text"
              onClick={redirectToCandidates}
            >
              Candidates
            </div>
          )}
        </div>
        <div className="sidebar-menu-communicate menu">
          <img
            className="communicate-logo menu-logo"
            src={communicate_logo}
            alt="communicate_logo"
            onClick={redirectToCommunicate}
          />
          {Toggle.toggleSlider && (
            <div
              className="communicate-text menu-text"
              onClick={redirectToCommunicate}
            >
              Communicate
            </div>
          )}
        </div>
        <div className='sidebar-menu-analytics menu'>
            <img className='analytics-logo-1 menu-logo' src={analytics_1} alt='analytics_logo' onClick={redirectToAnalytics}/>
            <img className='analytics-logo-2' src={analytics_2} onClick={redirectToAnalytics}/>
            <img className='analytics-logo-3 menu-logo' src={analytics_3} onClick={redirectToAnalytics}/>
            {Toggle.toggleSlider && <div className='analytics-text menu-text' onClick={redirectToAnalytics}>Analytics</div>}
          </div>
        <div className='sidebar-menu-form menu'>
        <img
            className="analytics-logo-1 menu-logo"
            src={analytics_1}
            alt="analytics_logo"
          />
          <img className="analytics-logo-2" src={analytics_2} />
          <img className="analytics-logo-3 menu-logo" src={analytics_3} />
            {Toggle.toggleSlider && <div className='analytics-text menu-text'>Form</div>}
          </div>
        <div className='sidebar-menu-listing menu'>
        <img
            className="analytics-logo-1 menu-logo"
            src={analytics_1}
            alt="analytics_logo"
          />
          <img className="analytics-logo-2" src={analytics_2} />
          <img className="analytics-logo-3 menu-logo" src={analytics_3} />
            {Toggle.toggleSlider && <div className='analytics-text menu-text'>Listing</div>}
          </div>
        <div className="sidebar-lower-divider dvdr">
          <hr></hr>
        </div>
        <div className="sidebar-menu-settings menu">
          <img
            className="settings-logo menu-logo"
            src={settings_logo}
            alt="settings_logo"
            onClick={redirectToSettings}
          />
          {Toggle.toggleSlider && (
            <div
              className="settings-text menu-text"
              onClick={redirectToSettings}
            >
              Settings
            </div>
          )}
        </div>
        <div className="sidebar-logout-btn menu">
          <img
            src={logout_btn}
            alt="logout-btn"
            className="menu-logo logout-btn-img"
            onClick={handleClick}
          />
          {Toggle.toggleSlider && (
            <button className="logout-btn menu" onClick={handleClick}>
              Logout
            </button>
          )}
        </div>
        <div className="sidebar-menu-toggle-slider menu">
          {Toggle.toggleSlider ? (
            <img
              className="toggle-slider-logo menu-logo"
              onClick={handleToggle}
              src={toggle}
            />
          ) : (
            <img
              className="toggle-slider-logo menu-logo"
              onClick={handleToggle}
              src={toggle_colored}
            />
          )}
        </div>
      </div>
      <div className="search-container" style={searchContainerStyle}>
        <input
          className="search-box"
          type="text"
          placeholder=" Global Search"
        ></input>
        <img className="seach-icon" src={search_icon} />
        <img className="bell-icon" src={bell_icon} />
        <img className="dot-icon" src={dot_icon} />
        <div className="data-container">
          <Listings />
          {/* <Contacts /> */}
        </div>
      </div>
    </div>
  );
}

export default Analytics;
