import React, { useState, useEffect } from "react";
import "./Listing.css";
import axios from "axios";
import Cookies from "js-cookie";
import image from "./assets/image.png";
import video from "./assets/video.png";
import document from "./assets/document.png";
import Lock from "./assets/lock.png";
import megaPhone from "./assets/megaphone.png";
import notification from "./assets/notification.png";

const Listing = () => {
  const [template, setTemplates] = useState(null);
  const [editTemplates, setEditTemplates] = useState("viewTemplate");
  const [components, setComponents] = useState(null);
  const [nameInputSearch, setNameInputSearch] = useState("`");
  const [templateType, setTemplateType] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [selectMedia, setSelectMedia] = useState(null);
  const [headrType, setHeaderType] = useState(null);
  const [headerText, setHeaderText] = useState("");
  const [headerVaribleAdded, setHeaderVaribleAdded] = useState(false);
  const [bodyText, setBodyText] = useState(null);
  const [footerText, setFooterText] = useState(null);
  const [bodyVarible, setBodyVarible] = useState(1);
  const [bodyWarning, setBodyWarning] = useState(false);
  const [numberOfComponents, setNumberOfComponents] = useState(0);
  const [numberOfComponentsCTA, setNumberOfComponentsCTA] = useState(0);
  const [inactive, setInactive] = useState(false);
  const [buttonSelect, setButtonSelect] = useState(null);
  const [websiteButton, setWebsiteButton] = useState("website");
  const [websiteButton2, setWebsiteButton2] = useState("phone");
  const [inputs, setInputs] = useState([""]);
  const [websiteInput, setWebsiteInput] = useState({})
  const [phoneInput, setPhoneInput] = useState({})
  const [countryCode, setCountryCode] = useState('')
  const [searchResult, setSearchResult]  = useState(false);
  const [chooseLanguage, setChooseLanguage] = useState(null)
  const [submitStatus, setSubmitStatus] = useState('Submit')

  const websiteButtonChoose = (event) => {
    console.log(event.target.value)
    const { name, value } = event.target;
      if(name==='type'){
        setWebsiteButton(value);
        if(value==="phone"){
          setWebsiteButton2("Website")
        }
        else{
          setWebsiteButton2("phone")
        }
      }
    setWebsiteInput({ ...websiteInput, [name]: value });
  };
  const phoneButtonChoose = (event) => {
    const { name, value } = event.target;
    if(name==='type'){
      setWebsiteButton(event.target.value);
    if(event.target.value==="phone"){
      setWebsiteButton2("Website")
    }
    
    else{
      setWebsiteButton2("phone")
    }
    }
    
    
    setPhoneInput({ ...phoneInput, [name]: value });
  };

  const handleButtonClick = () => {
    if (numberOfComponents < 1) {
      setNumberOfComponents(numberOfComponents + 1);
    } else {
      if (numberOfComponents >= 0) {
        setInactive(true);
      }
    }
  };
  useEffect(() => {
    console.log(buttonSelect);
  }, [buttonSelect]);

  const token = `Token ${Cookies.get("auth_token")}`;
  async function getTemplates() {
    const config = {
      headers: { Authorization: token },
    };
    const url = "https://preprod-api.tbplabs.tech/whatsapp/templates";
    const resp = await axios.get(url, config);
    setTemplates(resp.data.data);
  }

  const handleAddInput = () => {
    if (numberOfComponentsCTA >= 1) {
      setInactive(true);
    } else if (numberOfComponentsCTA >= 0) {
      setInactive(true);
      setNumberOfComponentsCTA(numberOfComponentsCTA + 1);
      const newInputs = [...inputs, ""];
      setInputs(newInputs);
    } else {
      setInactive(false);
      setNumberOfComponentsCTA(numberOfComponentsCTA + 1);
      const newInputs = [...inputs, ""];
      setInputs(newInputs);
    }
  };

  const handleDeleteInput = (index) => {
    setNumberOfComponentsCTA(numberOfComponentsCTA - 1);
    setInactive(false);
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handleInputChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
  };

  function openEditTemplates(components) {
    setComponents(components);
    setEditTemplates("editTemplate");
  }
  function closeEditTemplate() {
    setEditTemplates("viewTemplate");
  }
  function createTemplates() {
    setEditTemplates("createTemplate");
  }
  function addHeaderVarible() {
    if (headerVaribleAdded !== true) setHeaderText(headerText + "{{1}} ");
    setHeaderVaribleAdded(true);
  }
  function openEditform() {
    if (
      templateType !== null &&
      nameInputSearch !== "`" &&
      nameInputSearch !== "" &&
      templateName !== null
    ) {
      setEditTemplates("fillInformationTemplate");
    }
  }
  useEffect(() => {
    getTemplates();
  }, []);

  function headerTextSet(e) {
    setHeaderText(e.target.value);
    if (headerText.includes("{{1}}", 0)) {
      setHeaderVaribleAdded(true);
    } else {
      setHeaderVaribleAdded(false);
    }
  }

  function addBodyVarible() {
    const text = bodyText + " {{" + bodyVarible + "}}";
    setBodyVarible(bodyVarible + 1);
    setBodyText(text);
  }

  function checkParanthesis(str) {
    var stack = [];
    var brace_pairings = { ")": "(", "}": "{", "]": "[" };
    for (var i = 0; i < str.length; i++) {
      if (str[i] == "(" || str[i] == "{" || str[i] == "[") {
        stack.push(str[i]);
      } else if (str[i] in brace_pairings) {
        if (stack.pop() != brace_pairings[str[i]]) {
          return false;
        }
      }
    }

    return !stack.length;
  }

  function setBodyTextfunction(e) {
    setBodyText(e.target.value);
    if (checkParanthesis(bodyText)) {
    } else {
      setBodyWarning(true);
    }
  }

  function resetBody() {
    setBodyText("");
    setBodyVarible(1);
    setBodyWarning(false);
  }

  function selectButtontype(type) {
    setInactive(false);

    setButtonSelect(type);
  }
  function clickDropdown(value,name)
  {
    setNameInputSearch(name.toLowerCase());
    setChooseLanguage(value);
    setSearchResult(false);
  }

 

  // CREATE TEMPLATE

  async function createTemplate() {
    setSubmitStatus('Submitting...')
    if (
      templateType !== null &&
      templateName !== null &&
      chooseLanguage != null
    ) {
      const api =
        "https://preprod-api.tbplabs.tech/whatsapp/templates?name=" +
        templateName +
        "&category=" +
        templateType +
        "&language=" +
        chooseLanguage;
      if (bodyText !== null) {
        const templateData = [
          {
            type: "BODY",
            text: bodyText,
          },
          
        ];
        if(footerText!==null){
          templateData.push(
            {
              type: "FOOTER",
              text: footerText,
            }
          )
        }
        if (buttonSelect === "button") {
          if (inputs[1]) {
            templateData.push({
              type: "BUTTONS",
              buttons: [
                {
                  type: "QUICK_REPLY",
                  text: inputs[0],
                },
                {
                  type: "QUICK_REPLY",
                  text: inputs[1],
                },
              ],
            });
          } else if (inputs[0]) {
            templateData.push({
              type: "BUTTONS",
              buttons: [
                {
                  type: "QUICK_REPLY",
                  text: inputs[0],
                },
              ],
            });
          }
        }
        else{
          if(Object.keys(websiteInput).length !== 0 && Object.keys(phoneInput).length!==0){
            templateData.push({
              type:"BUTTONS",
              buttons: [
                {
                  type:'URL',
                  text:websiteInput.text,
                  url:websiteInput.url
                },
                {
                  type:'PHONE_NUMBER',
                  text:phoneInput.text,
                  phone_number:countryCode+' '+phoneInput.phone_number
                }
              ]
            });
          } 
          else if(Object.keys(websiteInput).length === 0 && countryCode+' '+phoneInput.phone_number!==' undefined' ){
            templateData.push({
              type:"BUTTONS",
              buttons: [
                {
                  type:'PHONE_NUMBER',
                  text:phoneInput.text,
                  phone_number:countryCode+' '+phoneInput.phone_number
                }
              ]
            });
          }
          else if(Object.keys(phoneInput).length === 0 && websiteInput.text){
            templateData.push({
              type:"BUTTONS",
              buttons: [
                {
                  type:'URL',
                  text:websiteInput.text,
                  url:websiteInput.url
                }
              ]
            });
          }
        }
       if(headerText!==""){
        if (headrType === "MEDIA") {
          templateData.push({ type: "HEADER", format: selectMedia });
        } else {
          templateData.push({
            type: "HEADER",
            format: "TEXT",
            text: headerText,
          });
        }
       }
       

        const config = {
          headers: { Authorization: token },
        };

        const resp = await axios.post(api, templateData, config);
      
        if(resp.status===200){
          setTemplateType(null);
          setBodyText(null);
          setHeaderText("");
          setNameInputSearch('`');
          setSubmitStatus('Submit');
          closeEditTemplate();
        }
      }
    } else {
      setSubmitStatus("Submit");
    }
  }

  function SearchDropdown(e){
    console.log(nameInputSearch);
    setSearchResult(true);
    setNameInputSearch(e.target.value);
  }

  const colorGray = {
    background: "#c7c7c7",
  };

  const templateMedia = {
    margin: " 0px 5px",
    border: "2px solid gray",
    padding: "20px",
    borderRadius: "3px",
    filter: "grayscale(100)",
    cursor: "pointer",
  };
  const selectedMedia = {
    margin: " 0px 5px",
    border: "2px solid #109cf1",
    padding: "20px",
    borderRadius: "3px",
    filter: "none",
    cursor: "pointer",
  };
  const callToAction = {
    background: "#e9f4ff",
    padding: "10px 20px",
    display: "flex",
    alignIteam: "center",
    justifyContent: "space-between",
    borderRadius: "5px",
    marginTop: "15px",
    width: "80%",
    minWidth: "550px",
  };
  const callToActionContainerQucik = {
    background: "#e9f4ff",
    padding: "10px 20px",
    display: "flex",
    alignIteam: "center",
    justifyContent: "space-between",
    borderRadius: "5px",
    marginTop: "15px",
    width: "240px",
  };
  const select = {
    border: "2px solid black",
    background: "#cbebff",
    scale: "1.01",
  };
  const selectMediacss = {
    border: "2px solid #109cf1",
    background: "#cbebff",
    padding: "20px",
    margin: "10px",
    borderRadius: "5px",
    cursor: "pointer",
  };
  const notselectMediacss = {
    background: "#ebf7ff",
    padding: "20px",
    margin: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    filter: "grayscale(1)",
  };

  const language = [
    {
      name: "Afrikaans",
      value: "af",
    },
    {
      name: "Albanian",
      value: "sq",
    },
    {
      name: "Arabic",
      value: "ar",
    },
    {
      name: "Azerbaijani",
      value: "az",
    },

    {
      name: "Benagli",
      value: "bn",
    },
    {
      name: "Bulgarian",
      value: "bg",
    },
    {
      name: "Catalan",
      value: "ca",
    },
    {
      name: "Chinese (CHN)",
      value: "zh_CN",
    },

    {
      name: "Chinese (HKG)",
      value: "zh_HK",
    },
    {
      name: "Chinese (TAI)",
      value: "zh_TW",
    },
    {
      name: "Croatian",
      value: "hr",
    },
    {
      name: "Czech",
      value: "cs",
    },

    {
      name: "Danish",
      value: "da",
    },
    {
      name: "Dutch",
      value: "nl",
    },
    {
      name: "English",
      value: "en",
    },
    {
      name: "English (UK)",
      value: "en_GB",
    },
    {
      name: "English (US)",
      value: "en_US",
    },
    {
      name: "Estonian",
      value: "et",
    },
    {
      name: "Filipino",
      value: "fil",
    },
    {
      name: "Finnish",
      value: "fi",
    },

    {
      name: "French",
      value: "fr",
    },
    {
      name: "Georgian",
      value: "ka",
    },
    {
      name: "German",
      value: "de",
    },
    {
      name: "Greek",
      value: "el",
    },

    {
      name: "Gujarati",
      value: "gu",
    },
    {
      name: "Hausa",
      value: "ha",
    },
    {
      name: "Hebrew",
      value: "he",
    },
    {
      name: "Hindi",
      value: "hi",
    },

    {
      name: "Hungarian",
      value: "hu",
    },
    {
      name: "Indonesian",
      value: "id",
    },
    {
      name: "Irish",
      value: "ga",
    },
    {
      name: "Italian",
      value: "it",
    },
    {
      name: "Japanese",
      value: "ja",
    },

    {
      name: "Kannada",
      value: "kn",
    },
    {
      name: "Kazakh",
      value: "kk",
    },
    {
      name: "Korean",
      value: "ko",
    },
    {
      name: "Kyrgyz (Kyrgyzstan)",
      value: "ky_KG",
    },
    {
      name: "Lao",
      value: "lo",
    },
    {
      name: "Latvian",
      value: "lv",
    },
    {
      name: "Lithuanian",
      value: "lt",
    },
    {
      name: "Macedonian",
      value: "mk",
    },
    {
      name: "Malay",
      value: "ms",
    },
    {
      name: "Malayalam",
      value: "ml",
    },
    {
      name: "Marathi",
      value: "mr",
    },
    {
      name: "Norwegian",
      value: "nb",
    },
    {
      name: "Persian",
      value: "fa",
    },
    {
      name: "Polish",
      value: "pl",
    },
    {
      name: "Portuguese (BR)",
      value: "pt_BR",
    },
    {
      name: "Portuguese (POR)",
      value: "pt_PT",
    },
    {
      name: "Punjabi",
      value: "pa",
    },
    {
      name: "Romanian",
      value: "ro",
    },
    {
      name: "Russian",
      value: "ru",
    },
    {
      name: "Serbian",
      value: "sr",
    },
    {
      name: "Slovak",
      value: "sk",
    },
    {
      name: "Slovenian",
      value: "sl",
    },
    {
      name: "Spanish",
      value: "es",
    },
    {
      name: "Spanish (SPA)",
      value: "es_ES",
    },
    {
      name: "Swahili",
      value: "sw",
    },
    {
      name: "Swedish",
      value: "sv",
    },
    {
      name: "Tamil",
      value: "ta",
    },
    {
      name: "Telugu",
      value: "te",
    },
    {
      name: "Thai",
      value: "th",
    },
    {
      name: "Turkish",
      value: "tr",
    },
    {
      name: "Ukrainian",
      value: "uk",
    },
    {
      name: "Urdu",
      value: "ur",
    },
    {
      name: "Uzbek",
      value: "uz",
    },
    {
      name: "Vietnamese",
      value: "vi",
    },
    {
      name: "Zulu",
      value: "zu",
    },
  ];

  return (
    <>
      {editTemplates === "viewTemplate" && (
        <div className="Listing-Container-parent">
          <h1 className="account-setting">Message Templates</h1>

          <div className="listing-container">
            <div className="listing-filter-row">
              <input type="search" placeholder="Search"></input>
              <select name="cars" id="cars">
                <option value="volvo">Choose Category</option>
                <option value="saab">Tech</option>
                <option value="mercedes">Non-Tech</option>
              </select>
              <select name="cars" id="cars">
                <option value="volvo">Language</option>
                <option value="saab">English- US</option>
                <option value="mercedes">Indian -en</option>
              </select>
            </div>
            <div>
              <button
                onClick={() => createTemplates()}
                className="create-template"
              >
                Create Template
              </button>
            </div>
          </div>
          <div className="listing-table">
            <br></br>
            <table className="people-form-table">
              <tr>
                <th>Template Name</th>
                <th>Language</th>
                <th>Status</th>
                <th>Category</th>
                {/* <th className="threeDot"></th> */}
              </tr>
              {template != null ? (
                template.map((e) => (
                  <tr onClick={() => openEditTemplates(e.components)}>
                    <td>{e.name}</td>
                    <td>{e.language}</td>
                    <td>{e.status}</td>
                    <td>{e.category}</td>
                  </tr>
                ))
              ) : (
                <>Loading Templates...</>
              )}
            </table>
          </div>
        </div>
      )}

      {editTemplates === "editTemplate" && (
        <div className="Listing-Container-parent">
          <div>
            <div className="edit-template-header">
              <h1 className="account-setting">Edit Templates</h1>
              <button
                onClick={() => closeEditTemplate()}
                className="create-template"
              >
                Close
              </button>
            </div>
            {components[0].type === "HEADER" ? (
              <div className="templateHeader">
                <h1 className="subHeading">Header</h1>
                <h6>
                  Add a title or choose which type of media you'll use for this
                  header.
                </h6>
                <div class="template-media">
                  <div>
                    <img
                      style={
                        components[0].format == "IMAGE"
                          ? selectedMedia
                          : templateMedia
                      }
                      src={image}
                      alt="imgage"
                    />
                    Image
                  </div>
                  <div>
                    <img
                      style={
                        components[0].format == "VIDEO"
                          ? selectedMedia
                          : templateMedia
                      }
                      src={video}
                      alt="video"
                    />
                    Video
                  </div>
                  <div>
                    <img
                      style={
                        components[0].format == "TEXT"
                          ? selectedMedia
                          : templateMedia
                      }
                      src={document}
                      alt="document"
                    />
                    Text
                  </div>
                </div>
              </div>
            ) : null}
            {components.map((e) =>
              e.type == "BODY" ? (
                <div className="templateBody">
                  <h1 className="subHeading">Body</h1>
                  <h6>
                    Enter the text your message in the language that you've
                    selected
                  </h6>
                  <textarea value={e.text} readOnly></textarea>
                </div>
              ) : null
            )}

            {components.map((e) =>
              e.type === "FOOTER" ? (
                <div>
                  <h1 className="subHeading">Footer</h1>
                  <h6>{e.text}</h6>
                </div>
              ) : null
            )}

            {components.map((e) =>
              e.type === "BUTTONS" ? (
                <div className="call-to-action">
                  <h1 className="subHeading">Buttons</h1>
                  {e.buttons.map((e) =>
                    e.type == "PHONE_NUMBER" ? (
                      <div
                        className="callToActionContainer"
                        style={callToAction}
                      >
                        <div>
                          <label>Type of Action</label>
                          <input value={e.type} readOnly></input>
                        </div>
                        <div>
                          <label>Button Text</label>
                          <input value={e.text} readOnly></input>
                        </div>
                        <div>
                          <label>Phone Number</label>
                          <input value={e.phone_number} readOnly></input>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="callToActionContainer"
                        style={callToActionContainerQucik}
                      >
                        <div>
                          <label>Type of Action</label>
                          <input value={e.type} readOnly></input>
                        </div>
                        <div>
                          <label>Button Text</label>
                          <input value={e.text} readOnly></input>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : null
            )}
          </div>
        </div>
      )}

      {editTemplates === "createTemplate" && (
        <div style={{ width: "100%" }}>
          <div className="edit-template-header">
            <h1 className="account-setting">Create Templates</h1>
            <button
              onClick={() => closeEditTemplate()}
              className="create-template"
            >
              Close
            </button>
          </div>

          <div className="editTemplate-first-form">
            <div className="marketing-container">
              <div
                onClick={() => setTemplateType("marketing")}
                style={templateType === "marketing" ? select : null}
                className="marketing-container1"
              >
                <div className="marketing-image">
                  <img src={megaPhone}></img>
                </div>
                <div>
                  <h1>Marketing</h1>
                  <p>
                    Send promotions or information about your products, services
                    or business.
                  </p>
                </div>
              </div>

              <div
                onClick={() => setTemplateType("utility")}
                style={templateType === "utility" ? select : null}
                className="marketing-container1"
              >
                <div className="marketing-image">
                  <img src={notification}></img>
                </div>
                <div>
                  <h1>Utility</h1>
                  <p>Send messages about an existing order or account.</p>
                </div>
              </div>

              <div
                onClick={() => setTemplateType("authentication")}
                style={templateType === "authentication" ? select : null}
                className="marketing-container1"
              >
                <div className="marketing-image">
                  <img src={Lock}></img>
                </div>
                <div>
                  <h1>Authentication</h1>
                  <p>Send codes to verify a transaction or login</p>
                </div>
              </div>
              <div className="marketing-container-name">
                <h1>Name</h1>
                <p>Name your message template</p>
                {/* TEMPLATE NAME */}
                <input
                  onChange={(e) => setTemplateName(e.target.value)}
                  className="nameInput"
                  placeholder="Enter message template name..."
                ></input>
              </div>

              <div className="marketing-container-name">
                <h1>Language</h1>
                <p>
                  Choose languages for message template. You can delete or add
                  more languages later.
                </p>
                <input
                  value={nameInputSearch === "`" ? null : nameInputSearch}
                  onChange={(e) => SearchDropdown(e)}
                  type="search"
                  className="nameInput"
                  placeholder="Enter message template name..."
                ></input>
                <div>
                  <ul style={searchResult?{display:"block"}:{display:"none"}} className="search-result">
                    {language !== null
                      ? language
                          .filter((e) => {
                            return nameInputSearch.toLowerCase() === ""
                              ? e
                              : e.name.toLowerCase().includes(nameInputSearch);
                          })
                          .map((e) => (
                            <li onClick={() => clickDropdown(e.value,e.name)}>
                              {e.name}
                            </li>
                          ))
                      : null}
                  </ul>
                </div>
              </div>
              <button
                style={
                  templateType !== null &&
                  nameInputSearch !== "`" &&
                  nameInputSearch !== "" &&
                  templateName !== null
                    ? { background: "#109bf1" }
                    : { background: "#b9b9b9" }
                }
                onClick={() => openEditform()}
                className="next-from-btn"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {editTemplates === "fillInformationTemplate" && (
        <div className="Listing-Container-parent">
          <div>
            <div className="edit-template-header">
              <h1 className="account-setting">Create Templates</h1>
              <button
                onClick={() => closeEditTemplate()}
                className="create-template"
              >
                Close
              </button>
            </div>

            <div className="templateHeader">
              <h1 className="subHeading">Header</h1>
              <h6>
                Add a title or choose which type of media you'll use for this
                header.
              </h6>

              <select
                className="select-dropdown"
                onChange={(e) => setHeaderType(e.target.value)}
                name="cars"
                id="cars"
              >
                <option value="">None</option>
                <option value="TEXT">Text</option>
                <option value="MEDIA">Media</option>
              </select>
              {headrType === "TEXT" ? (
                <div className="add-varible-container">
                  <input
                    onChange={(e) => headerTextSet(e)}
                    value={headerText}
                    className="nameInput"
                    placeholder="Add title for template..."
                  ></input>
                  <div>
                    <span
                      style={
                        headerVaribleAdded
                          ? { color: "gray", cursor: "default" }
                          : {}
                      }
                      onClick={() => addHeaderVarible()}
                      className="header-add-varible"
                    >
                      + Add Varible
                    </span>
                  </div>
                </div>
              ) : null}

              {headrType === "MEDIA" ? (
                <div class="template-media">
                  <div
                    onClick={() => setSelectMedia("IMAGE")}
                    style={
                      selectMedia == "IMAGE"
                        ? selectMediacss
                        : notselectMediacss
                    }
                  >
                    <img src={image} alt="img" />
                    Image
                  </div>
                  <div
                    onClick={() => setSelectMedia("VIDEO")}
                    style={
                      selectMedia == "VIDEO"
                        ? selectMediacss
                        : notselectMediacss
                    }
                  >
                    <img src={video} alt="video" />
                    Video
                  </div>
                  <div
                    onClick={() => setSelectMedia("DOCUMENT")}
                    style={
                      selectMedia == "DOCUMENT"
                        ? selectMediacss
                        : notselectMediacss
                    }
                  >
                    <img src={document} alt="document" />
                    Text
                  </div>
                </div>
              ) : null}
            </div>

            <div className="templateBody">
              <h1 className="subHeading">Body</h1>
              <h6>
                Enter the text your message in the language that you've selected
              </h6>

              <textarea
                value={bodyText}
                onChange={(e) => setBodyTextfunction(e)}
              ></textarea>
              {bodyWarning == false ? null : (
                <div className="error-container-text-area">
                  If you delete varibles then you will not get your desire
                  outputs. <br></br>
                  <span className="reset-body" onClick={() => resetBody()}>
                    ↻ Reset Body
                  </span>{" "}
                </div>
              )}

              <div className="add-varible-container">
                <span
                  onClick={() => addBodyVarible()}
                  className="header-add-varible"
                >
                  + Add Varible
                </span>
              </div>
            </div>

            <div className="footer-container">
              <h1 className="subHeading">Footer</h1>
              <input
                className="nameInput"
                type="text"
                onChange={(e) => setFooterText(e.target.value)}
                placeholder="Enter text to add in footer"
              ></input>
            </div>

            <div className="call-to-action">
              <h1 className="subHeading">Buttons</h1>
              <select
                className="select-dropdown"
                onChange={(e) => selectButtontype(e.target.value)}
                name="cars"
                id="cars"
              >
                <option value="">None</option>
                <option value="cta">CTA</option>
                <option value="button">Button</option>
              </select>
              {buttonSelect == "cta" && (
                <div>
                  <div className="callToActionContainer" style={callToAction}>
                    <div>
                      <label>Type of Action</label>
                      <select
                        onChange={(e)=>websiteButtonChoose(e)}
                        className="typeOfAction"
                        name="type"
                        id="cars"
                      >
                        <option value="website">Website</option>
                        <option value="phone">Phone</option>
                      </select>
                    </div>
                    {websiteButton === "phone" ? (
                      <>
                        <div>
                          <label>Button Text</label>
                          <input
                          name="text"
                          value={websiteInput.text || ""}
                          onChange={(e)=>websiteButtonChoose(e)}
                          ></input>
                        </div>
                        <div>
                          <label>Country</label>
                          <input onChange={(e)=>setCountryCode(e.target.value)} className="country" placeholder="+ 44"></input>
                        </div>
                        <div>
                          <label>Phone Number</label>
                          <input
                          name="phone_number"
                          value={websiteInput.phone_number || ""}
                          onChange={(e)=>websiteButtonChoose(e)}
                          ></input>
                        </div>
                      </>
                    ) : (
                      websiteButton === "website"?(
                      <>
                        
                        <div>
                          <label>Button Text</label>
                          <input 
                           name="text"
                           value={websiteInput.text || ""}
                           onChange={(e)=>websiteButtonChoose(e)}
                          ></input>
                        </div>
                        <div>
                          <label>URL type</label>
                          <select
                            className="typeOfAction"
                            name="cars"
                            id="cars"
                          >
                            <option value="static">Static</option>
                            <option value="dynamic">Dynamic</option>
                          </select>
                        </div>
                        <div>
                          <label>Website</label>
                          <input 
                          placeholder="https://abc.com/"
                          name="url"
                          value={websiteInput.url || ""}
                          onChange={(e)=>websiteButtonChoose(e)}
                          ></input>
                        </div>
                      </>
                     ):(null)
                      
                    )}
                  </div>

                  <div>
                    {Array.from({ length: numberOfComponents }, (_, index) => (
                      <div
                        key={index}
                        className="callToActionContainer"
                        style={callToAction}
                      >
                        <div>
                          <label>Type of Action</label>
                          <select
                            onChange={(e)=>websiteButtonChoose(e)}
                            className="typeOfAction"
                            name="type"
                            value={phoneButtonChoose.type || ""}
                            id="cars"
                          >
                            <option value={websiteButton2}>{websiteButton2}</option>
                            
                          </select>
                        </div>
                        {websiteButton2 === "phone" ? (
                          <>
                            <div>
                              <label>Button Text</label>
                              <input
                              name="text"
                              value={phoneInput.text || ""}
                              onChange={phoneButtonChoose}
                              ></input>
                            </div>
                            <div>
                              <label>Country</label>
                              <input
                              onChange={(e)=>setCountryCode(e.target.value)}
                                className="country"
                                placeholder="+ 44"
                              ></input>
                            </div>
                            <div>
                              <label
                              
                              >Phone Number</label>
                              <input
                               name="phone_number"
                               value={phoneInput.phone_number || ""}
                               onChange={phoneButtonChoose}
                              ></input>
                            </div>
                          </>
                        ) : (
                          <>
                            {console.log(websiteButton)}
                            <div>
                              <label>Button Text</label>
                              <input></input>
                            </div>
                            <div>
                              <label>URL type</label>
                              <select
                                className="typeOfAction"
                                name="cars"
                                id="cars"
                              >
                                <option value="static">Static</option>
                                <option value="dynamic">Dynamic</option>
                              </select>
                            </div>
                            <div>
                              <label>Website</label>
                              <input placeholder="https://abc.com/"></input>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                  <button
                    style={inactive === true ? colorGray : {}}
                    className="btn-add"
                    onClick={handleButtonClick}
                  >
                    Add CTA
                  </button>
                </div>
              )}
              {buttonSelect == "button" && (
                <div>
                  {inputs.map((input, index) => (
                    <div
                      key={index}
                      className="callToActionContainer"
                      style={callToActionContainerQucik}
                    >
                      <div>
                        <label>Button Text</label>

                        <input
                          value={input}
                          onChange={(event) =>
                            handleInputChange(index, event.target.value)
                          }
                        ></input>
                      </div>
                      <span
                        onClick={() => handleDeleteInput(index)}
                        className="cut-button"
                      >
                        ✖
                      </span>
                    </div>
                  ))}

                  <button
                    style={inactive === true ? colorGray : {}}
                    className="btn-add"
                    onClick={handleAddInput}
                  >
                    Add Button
                  </button>
                </div>
              )}
            </div>
            <button className="createTemplatebtn" onClick={() => createTemplate()}>{submitStatus}</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Listing;
