import React from 'react'
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";






const DropdownInput = ({ label, value, options, onChange }) => {
  return (
    <>
      <Box style={{ minWidth: 200 }}>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select value={value} label={label} onChange={onChange}>
            {options.map((option, index) => (
              <MenuItem key={index} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default DropdownInput