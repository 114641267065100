import "./App.css";
import Header from "./components/Header/Header";
import ChatArea from "./components/ChatArea/ChatArea";
import Profile from "./components/Profile/Profile";
import { useState, useEffect, useContext, useRef } from "react";
import CommunicateContext from "../../../store/CommunicateContext";
import Cookies from "js-cookie";
import { MarketingSidebar } from "../../MarketingSidebar/MarketingSidebar";

const Layout = ({ accountData, appAccountId }) => {
  const [profile, setProfile] = useState();

  const accountid =  accountData.message.account.id;
  const userid = accountData.message.user.userid;
  const communicateCtx = useContext(CommunicateContext);
  const [showTagModal, setShowTagModal] = useState(false);
  const [tag, setTag] = useState();
  const [integrations, setIntegrations] = useState(null);

  const Authorization = {
    headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
  };

  const fetchProfile = () => {
    fetch(
      `https://preprod-api.tbplabs.tech/chatapp/getObject?&accountid=${accountid}&appAccountId=${appAccountId}&objectid=${communicateCtx.objectid}&isPortal=true`, Authorization
    )
      .then((res) => res.json())
      .then((data) => {
        setProfile(data);
        setIntegrations(data.Data.object.integration)
      });
  };
  
  async function fetchTagData() {
    try {
      const response = await fetch(
        "https://preprod-api.tbplabs.tech/user/tag",
        {
          headers: {
            Authorization: `Token ${Cookies.get("auth_token")}`,
          },
        }
      );
      const data = await response.json();
      setTag(data.content);
    } catch (error) {
      console.error(error);
    }
  }

  let tempId = useRef(0);

  useEffect(() => {
    tempId.current = profile;
  }, [profile]);

  function setActiveProfile() {
    let temp = { ...tempId.current };
    temp.Data.object.conversation = "Active";
    setProfile(temp);
  }

  useEffect(() => {
    fetchProfile();
  }, [communicateCtx.objectid]);

  useEffect(() => {
    fetchTagData();
  }, []);
  return (
    <div className="app">
      {/* <Header /> */}
      <div className="wrapper">
        {appAccountId && (
          <ChatArea
            accountid={accountid}
            userid={userid}
            profile={profile}
            setActiveProfile={setActiveProfile}
            showTagModal={showTagModal}
            setShowTagModal={setShowTagModal}
            tag={tag}
            objectid={communicateCtx.objectid}
            appAccountId={appAccountId}
          />
        )}
        {communicateCtx.objectid && (
          <MarketingSidebar
            profile={profile}
            showTagModal={showTagModal}
            setShowTagModal={setShowTagModal}
            tag={tag}
            integrations={integrations}
            appAccountId={appAccountId}
          />
        )}
      </div>
    </div>
  );
};

export default Layout;
