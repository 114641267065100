import { Label } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

import { ws } from "../ChatArea/Connection";
import Modal from "react-modal";
import axios from "axios";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import SendIcon from "@mui/icons-material/Send";

Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50rem",
    // height: "70vh",
    position: "relative",
    padding: "0px 0px",
    maxWidth: "60rem",
  },
  overlay: {
    backgroundColor: "rgba(1,1,1,0.4)",
    backdropFilter: "blur(4px)",
    zIndex: 10000,
  },
  dropdown: {
    border: "1px solid gainsboro",
    padding: "4px",
    borderRadius: "7px",
    width: "90%",
  },
  textBox: {
    border: "1px solid gainsboro",
    padding: "4px",
    borderRadius: "7px",
    width: "40%",
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-evenly",
  },
};

const ImageUploadModal = (props) => {
  const { openModal, closeModal } = props;

  let subtitle;
  let headerUrl = "";
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  const [preview, setPreview] = useState([]);

  useEffect(() => {
    let objectUrl = [];
    console.log(props.images?.length);
    for (let i = 0; i < props.images?.length; i++) {
      objectUrl.push(URL.createObjectURL(props.images[i]));
    }
    setPreview([...objectUrl]);
  }, [props.images]);

  return (
    <div>
      <Modal
        isOpen={openModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex w-full h-[3rem] justify-between items-center px-[16px]">
          <div className="font-bold">Send Message</div>
          <div className="cursor-pointer" onClick={closeModal}>
            x
          </div>
        </div>
        <hr />
        <div className="overflow-y-auto flex flex-col items-center w-full justify-around items-center relative h-[80%] px-[1rem] py-[1rem]">
        <div
            className="circle_cont"
            style={{
              display: props.isLoading ? "flex" : "none",
            }}
          >
            <svg
              class="svg"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="50"
                cy="50"
                r="30"
                fill="transparent"
                stroke-width="8px"
                stroke-dasharray="160"
              />
            </svg>
          </div>
          {(props.fileType=="image")?<div className="flex">
            {props.images?.map((image, index) => (
              <div className="w-[8rem] h-[8rem] p-[0.5rem]">
                <img
                  src={preview[index]}
                  className="object-cover h-[100%] w-[100%] rounded-2xl"
                  alt=""
                />
              </div>
            ))}
          </div>:
          <div>{props.fileName}</div>
          }
          <div className="flex w-[100%]">
            <textarea rows="4" value={props.caption} onChange={(e)=>{props.setCaption(e.target.value)}} placeholder="Enter a caption" className=" border border-sky-500 w-[100%] p-1 my-2"></textarea>
            <div className="flex items-center justify-center">
              <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-1 rounded modlBtn"
                onClick={() => {
                  props.onSend();
                }}
              >
                <SendIcon />
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div className="flex w-full h-[3rem] justify-end items-center h-[10%] px-[1rem]"></div>
      </Modal>
    </div>
  );
};

export default ImageUploadModal;