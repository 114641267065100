import shopify from "../../MarketingWebsiteIcons/shopify.png";
import axios from "axios";
import Cookies from "js-cookie";
import { useRef } from "react";

import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormComponent from "../../Component/FormComponent";

export const Shopify = ({ profile }) => {
  const [ShopifyData, setShopifyData] = useState(null);
  const [submit, setSubmit] = useState(false);

  const refs = useRef([]);

  useEffect(() => {
    getHubspotFormData();
  }, [profile.Data.object.id]);

  async function getHubspotFormData() {
    const API_URL = `https://preprod-api.tbplabs.tech/contacts/ContactformById/${profile.Data.object.id}?type=Shopify`;
    const config = {
      headers: { Authorization: `Token ${Cookies.get("auth_token")}` },
    };
    const hubspotFormData = await axios.get(API_URL, config);
    setShopifyData(hubspotFormData.data.data);
  }
  let postBody = [];

  return (
    <div className="py-2 px-5 marketing-form-parent">
      <div className="flex align-center">
        <img className="marketing-partner-icon" src={shopify} />
        <Typography fontWeight="bold">Shopify</Typography>
      </div>
      {ShopifyData?.map((item, key) => (
        <Accordion defaultExpanded={key === 0 ? true : false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize="small" fontWeight="500">
              {item.groupLabel}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormComponent
              group={item}
              postBody={postBody}
              submit={submit}
              ref={(el) => (refs.current[key] = el)}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
